@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel:wght@400..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel:wght@400..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel:wght@400..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel:wght@400..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap');
/* templat css */
/* #temp17 .main-temaplt{  font-family: 'lato', sans-serif !important;} */
#temp17 .wtemplate {
    background: #0F0F0F;
    font-family: "Montserrat", sans-serif;
}

#temp17 ul.gallerylist li {
    margin: 4px 4px;
    width: 28%;
    border-radius: 18px;
}

#temp17 ul.gallerylist li img {
    border-radius: 16px;
}

#temp17 h3.sectitle {
    font-size: 14px;
    margin-bottom: 16px;
    color: #A0A0A0;
    font-weight: 400;
    text-align: start;
    text-transform: uppercase;
}
#temp17 .makeappitmneets h3.sectitle,
#temp17 .testimoniyalbox h3.sectitle {
    text-align: center;
}

#temp17 .servesbox {
    padding: 0;
    margin: 0;
   /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;*/
    list-style-type: none;
    
     display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  gap:16px;
    
}

#temp17 p.para {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    text-align: start; 
    word-break: break-word;
}

#temp17 .sec {
    font-family: "Montserrat", sans-serif;   
    border-radius: 8px;    
    padding: 16px 12px;
    margin-bottom: 18px;
    margin: 23px 23px;
    background: linear-gradient(88.9deg, #0B1A39 0.84%, #0F0F0F 98.97%);
}

#temp17 .servicesName.sec {
    /* padding: 30px 30px 0; */
}

#temp17 article.abouttemplate.sec {
    text-align: center;
}

#temp17 h3.username {
    font-family: "Playfair Display", serif;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
   display: inline-block;
   margin-bottom: 4px;
   word-break: break-word;
}

#temp17 input.form-control {
    border-radius: 0;
    background-color: transparent;
    border: 0.5px solid #6C6C6C;
    color: #999999;
    padding: 15px 15px;
    font-size: 12px;
    /* margin-top: 5px; */
}

#temp17 input.form-control::placeholder {
    color: #999999 !important;
}

#temp17 textarea.form-control::placeholder {
    color: #999999 !important;
}

#temp17 textarea.form-control {
    border-radius: 0;
    background-color: transparent;
    border: 0.5px solid #6C6C6C;
    /* margin-top: 5px; */

}

#temp17 select.form-select {
    border-radius: 0;
    background-color: transparent;
    border: 0.5px solid #6C6C6C;   
    color: #999999;
    padding: 15px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add custom arrow */
    background-image: url('../../../../Images/d-arrow-w.-new.png');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 17px;
    font-size: 12px;
}

#temp17 select option{
background: linear-gradient(88.9deg, #0B1A39 0.84%, #0F0F0F 98.97%);
color:#000;
    
}
#temp17 button.btn.btn-submitform {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgb(0 0 0), rgb(0 0 0)); */
    background: #D58F2D;
    box-sizing: border-box;
    width: 100%;
    color: #fff;
    border-radius: 0;
    padding: 15px 20px;
    font-size: 12px;
    border:1px solid #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


#temp17 ul.socil-link li { 
    display: flex;
    text-align: center;  
    font-size: 23px;
    border-radius: 0;
    /* background: #FFFCFC; */
    /* box-shadow: 0px 4px 10px 0px rgba(179, 156, 156, 0.25); */
    align-items: center;
    justify-content: center;  
}

#temp17 article.imgsechere.sec {
    background: #D58F2D;
    position: relative;
    width: 100%;
    height: 330px;
    overflow: hidden;
    border-radius: 0;
    clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 65%);
}

#temp17 .logoTextContainer {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
   position: absolute;
   padding: 0 20px;
   top:60px;
   width:auto; 
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
}
#temp17 .coverimghere{
    height:auto
}
#temp17 article.personalinfo {
    margin-top: 0px;
    display: flex; 
    flex-direction: column;
    align-items: center;
}

#temp17 article.qrcode-open {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp17 .logo-profile {
    padding: 0 10px 6px 10px;   
    position: relative;   
    border-bottom: 1px solid #Fff;
    width: 98px;   
    padding-bottom:15px;
    height: auto;
    border-radius: 0;
    margin: 0 0 6px 0 !important;
}



#temp17 button.btn.btnmakeappitmnets {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background-color: #D58F2D;
    width: 100%;
    color: #fff;
    border-radius: 0;
    padding: 15px 24px;
    font-size: 12px;
    border:1px solid #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

#temp17 .timelist {
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
}



#temp17 ul.timelist li .day {
    /* margin-right: 28px; */
    font-size: 15px;
    font-weight: 500;
    /* width: 27%; */
    padding: 0 10px;
    color: #fff;
}

#temp17 ul.servesbox li {
    position: relative;
}

#temp17 ul.conlink li i {
    min-width: 40px;
    font-size: 17px;
    height: auto;
    /* background: antiquewhite; */
    text-align: center;
    line-height: auto;
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%); */
    color: #D58F2D;
    border-radius: 0;
    margin-bottom: 16px;
}

#temp17 ul.conlink li span {

   font-family: "Montserrat", sans-serif;
    padding-left: 1px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: #F6F6F6;
    text-align: left;
}

#temp17 ul.conlink li {
    margin-bottom: 19px;
    display: flex;
}

#temp17 ul.conlink {
    padding: 0px;
}

#temp17 ul.conlink li a {
    display: flex;    
    align-items: baseline;
    width: 100%;
}

#temp17 p.titleuser {
    color: #ffff;
    margin: 0;
    word-break: break-word;
    font-size: 13px;
    display: inline-block;
    /* padding-left: 70px; */
}

#temp17 article.businesshour.sec h3 {
    text-align: center;
}

#temp17 ul.servesbox li {
    position: relative;
}

#temp17 ul.servesbox li:before {
    content: "";
    width: 15px;
    height: 15px;
    /* background: #ff752a; */
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: -9px;
    top: 0px;
}

#temp17 ul.servesbox li {
    font-family: "Montserrat", sans-serif;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #0B1A39;
    border-radius: 0;   
    background-color: #fff;    
    padding: 8px 12px;  
    box-shadow: 2px 2px 0px 0px #D58F2D;
}

#temp17 ul.timelist li {
    position: relative;
}

#temp17 ul.timelist li:before {
    content: "\f017";
    font-family: 'FontAwesome';
    color: #6A6A6A;
    /* margin-right: 11px; */
    position: absolute;
    right: 20px;
    font-size: 24px;
}

#temp17 .time {
    font-weight: 400;
    font-size: 15px;
    font-weight: 500;
}


#temp17 .testimoniyalbox.sec {   
    color: #fff;
    background: linear-gradient(88.9deg, #0B1A39 0.84%, #0F0F0F 98.97%);
    position: relative;
}
#temp17 .testimoniyalbox.sec .quoteup {
    position: absolute;
    left:8px;
    top:10px;
    width:40px;
}
#temp17 .testimoniyalbox.sec .quotedown {
    position: absolute;
    right:8px;
    bottom:10px;
    width:40px;
}
#temp17 .testimonialimg {
    border: 5px solid #ffc708;
}

#temp17 .testimonialcontent {
    display: flex;
    margin: 6px 0 0 0;
    align-items: flex-start;
}

#temp17 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
}

#temp17 .testiprofile {
    width: 170px;
    height: 170px;
}

#temp17 .testimonialname {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    padding-top: 30px;
    display: block;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
}

#temp17 .testimonialdescription {      
    /* line-height: 22px; */
    padding-top: 0;
    font-size: 14px;
    color: white;
    text-align: center;
    /* width: 70%; */
}

#temp17 .testibox {
    text-align: center;
    width: 350px;
    margin:0 auto;
}

#temp17 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
}

#temp17 .testimonials {
    display: flex;
    width: max-content;
    /* width: 200% !important; */

}

#temp17 .testiimg {
    max-width: 90px;
    max-height: 90px;
    display: none;
}
#temp17 .testimonial-rating {
    width:100%;
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
}
#temp17 .testimonialcontent img {
    /* border: 3px solid #ffc708 !important; */
    margin: 0 !important;
    border-radius: 50px;
}

#temp17 .sectitle.testimonial {
    /* color: #000; */
    color: #A0A0A0;
}

#temp17 .testibox {
    text-align: justify;
    position: relative;
}

#temp17 label.form-label {
    color: #F6F6F6 ;
    font-size: 12px;
    font-weight: 300;
}

#temp17 .clintname {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5px;
}


/* comman css */

#temp17 .testibox {
    text-align: center;
}



#temp17 h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    color:#000;
}

#temp17 .main-temaplt ul {
    list-style-type: none;
    padding: 0px;
}

#temp17 ul {
    margin: 0px;
}

#temp17 .main-temaplt a {
    text-decoration: none;
    color: #000;
}



#theme3 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}

ul.startaring {
    margin-top: 16px;
}

img.mx-auto.d-block.img-fluid.coverimghere {
    width: 100vw;
    height: 285px;
}


.wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
}

#temp17 ul.socil-link {
    display: flex;
    /* justify-content: space-around; */
    justify-content: start;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 0;
    column-gap: 40px;
    row-gap: 12px;
    justify-content: center;
}

#temp17 ul.socil-link-inner {
    display: flex;
    justify-content: center;
    padding: 0px;
    margin-top: 30px;
    column-gap: 40px;
    color: white;
}

#temp17 ul.socil-link-inner li a {
    /* color: transparent; */

}

#temp17 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}

#temp17 ul.timelist li {
    display: flex;
    margin-bottom: 14px;
    align-items: center;
    border-radius: 55px;
    border: 0.5px solid rgba(255, 255, 255, 0.33);
    /* background: #212121; */
    background: transparent;
    backdrop-filter: blur(40px);
    padding: 20px 10px;
}

#temp17 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}

#temp17 ul.gallerylist li {
    margin: 4px 4px;
}

#temp17 article.fixedfooter {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    position: relative;
    /*
    align-items: center;
    background: transparent; */
    margin: 0px;
}

@media(max-width: 380px){
    #temp17 article.fixedfooter{
        justify-content: space-between;
    }
}

#temp17 .personalinfo.modal-info {
    text-align: center;
}

#temp17 .fixedd {
    position: fixed;
    bottom: 0;
    width: inherit;   
    transform: translate(0%, 0px);
    padding: 20px 15px;
    box-shadow: 0px 0px 18px 5px #0000000d;
    z-index: 98;
    background: linear-gradient(156.07deg, #0B1A39 15.37%, #0F0F0F 84.4%);

}

#temp17 .addtocinatct {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px; */
    background: transparent;
    color: #fff;
    padding: 0;
    border-radius: 23px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
}

#temp17 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp17 .footerleft {
    position: absolute;
    display: flex;
    top: -36px;   
    width: 75px;
    height: 75px;
    background: #D58F2D;    
    text-align: center;
    line-height: 35px;
    color: #fff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    border:1px solid #fff;    
    left: calc(50% - 37px);
    /* box-shadow: 0px 0px 9px 2px #000; */
}



#temp17 .qrcodeshaere {
    cursor: pointer;
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;   
    border-radius: 100px;
    padding: 45px;
}

#temp17 .addtocinatct {
    cursor: pointer;
}

@media (max-width: 480px) {
    .fixedd {
        width: 100vw;
    }

    .coverimghere {
        width: 100% !IMPORTANT;
    }

    ul.gallerylist li {
        margin: 4px 4px;
        width: 46%;
    }

    .wtemplate {
        padding: 0px;
    }

    #temp17 .sec {
        /* background: #F8F8F8; */
        border-radius: 8px;
         padding: 16px 12px; 
        margin-bottom: 18px;
        margin: 21px 9px;
        background: linear-gradient(88.9deg, #0B1A39 0.84%, #0F0F0F 98.97%);
    }



}

#temp17 .modal-content {
    width: 80%;
    border-radius: 20px;
}

#temp17 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp17 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp17 .personalinfo.modal-info p {
    font-size: 16px;
}

#temp17 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp17 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

#temp17 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
}

#temp17 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
}

#temp17 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
}

#temp17 .carousel-btn {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background: #D58F2D;
    color: #ffffff;
    /* border-radius: 50%; */
    font-size: 16px;
    margin: 0 10px 15px 0px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    /* display: none; */
}

#temp17 .carousel-container {
    display: flex;
    justify-content: flex-start;
    width:100%;
    justify-content: center;
}
#temp17 .carousel-containerExternal {
  /* margin: 0 0 10px 0; */
  padding-bottom: 20px;
}


#temp17 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp17 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp17 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp17 .twoimages {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

#temp17 .skeleton-blog {
    margin-bottom: 2em;
    animation: scale 4s infinite;
}

#temp17 .skeleton-footer {
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
}

@media (max-width:400px) {
    #temp17 .footerleft {
        width: 80px;
        height: 80px;
        border: 4px solid #24292ce0;
    }

    #temp17 .qrcodeshaere {
        font-size: 40px;
    }
}

@media (max-width:520px) {
    #temp17 .fixedd {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        /* background: #fcfbfb; */
        transform: translate(0%, 0px);
        padding: 8px 0;
        box-shadow: 0px 0px 18px 5px #0000000d;
        z-index: 98;
    }
}

#temp17 .qr-logo-image {
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width: 80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
    margin: 0 auto;
    background: white;
}

#temp17 canvas {
    border-radius: 20px;
}

#temp17 .canvas-container {
    display: flex;
    justify-content: center;
}

#temp17 .calanderIcon {
    position: absolute;
    top: 18px;
    right: 15px;
    cursor: pointer;
}

#temp17 .extmakeappointmentform{
    background-color: #fff !important;
    box-shadow:rgba(0, 0, 0, 0.15) 0px 4px 10px;
    border-radius: 20px !important;
    color: #1c1a1a !important;
}

#temp17 .extmakeappointmentsubmitbtn{
    background: #1BBFA2 ;
    width: 100% !important;
    color: #fff ;
    border-radius: 20px !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
  }

  #temp17 .extmakeappointmentformplaceholder input::placeholder {
    color: #999999 !important;
}
#temp17 .contact-form input::placeholder,
#temp17 .contact-form textarea::placeholder {
    color: #999999 !important;
}
#temp17 .makeappitmneets  .contact-form h5{
    color: #ffff;
    font-size: 12px;
    /* display: none; */
}
#temp17 .makeappitmneets  label{
    display: block;
    color: #F6F6F6;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 8px;
   
}
#temp17 .contactformLableName {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 5px;
    
   
}


/* input[type="date"] {
    -webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../../../Images/calendarIcondays.png');
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 20px;
    color: white !important;
    position: absolute;
    top: 20px;
  }

  input[type="date"]:focus {
    outline: none; 
    border-color: blue; 
  } */
  @media screen and (max-width:414px) {

  }

  @media screen and (max-width:400px) {
        

  }


  @-moz-document url-prefix() {
    .firefox-hide {
        display: none !important;
    }
}



#temp17 .reviewratingcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp17 .reviewrating{
  display: flex;
  justify-content: baseline;
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #95A0AD;
 
}

#temp17 .ratingspan{
    font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #95A0AD;
  padding-right: 5px;
}


#temp17 .modal-content .form-control{
border: 0;
box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
    border-radius: 20px !important;
}