.asyncPaginate *{
z-index: 99 !important;
}

@media(min-width: 900px) and (max-width:991px){
  .DetailsSelectLogo{
      width: 250px;
      margin-left: 75px;
      
}
}


@media(min-width: 830px) and (max-width:900px){
.DetailsSelectLogo{
  width: 250px;
  margin-left: 120px;
  
}
}

@media(min-width: 780px) and (max-width:830px){
.DetailsSelectLogo{
  width: 250px;
  margin-left: 130px;
  
}
}

@media(min-width: 767px) and (max-width:780px){
.DetailsSelectLogo{
  width: 250px;
  margin-left: 40px;
  
}
}

@media (min-width: 960px) {
.dine_available {
  text-align: center;
  margin-top: 48px;
}

}

/*
@media(min-width: 767px) and (max-width:991px){
.DetailsSelectLogo{
  width: 250px;
  margin-left: 72px;
  
}
} */


.mr-2{
margin-right: 10px;
}

.i-agree {
margin-bottom: 30px;
display: flex;
align-items: center;
}
.radiobtn{
margin-right: 3px;
margin-top: 3px;
}

.selectreviewverifiedbtn{
padding: 18px 8px !important;
border-radius: 4px;
/* margin-right: 13px; */
cursor: pointer;
box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
color: #fff !important;
background: green !important;
font-weight: 400;
text-decoration: none;
border: 1px solid #a5a5a5;
font-size: 14px;
/* line-height: 24px; */
margin-top: 0px;
width: 100%;
}

.verifybusinesswtd{
min-width: 135px;
}



.influencerproductctn{
position: relative;
display: flex;
justify-content: center;
margin-bottom: 30px;
}

.influencerproductdv{

position: absolute;
right: -10px;
top: -10px;

}

.influencerproductimg{
width: 24px;
height: 24px;
cursor: pointer;
}

.influencerproductimgcont{
justify-content: center;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
width: 150px;
}
.infaddproduct{
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-gap: 20px;
}
.Influencerproductname{
/* display:flex; */
align-items: center;
text-align: center;
justify-content: center;
margin-top: 10px;
height: 49px !important;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
white-space: normal;
word-wrap: break-word;
}


@media (max-width: 1700px) {
.infaddproduct{
  grid-template-columns: 1fr 1fr 1fr;
} 
}

@media (max-width: 1440px) {
.infaddproduct{
  grid-template-columns: 1fr 1fr;
} 
}

@media (max-width: 500px) {
.infaddproduct{
  grid-template-columns: 1fr;
} 
}


.color-picker-container { 
display: grid; 
/* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));  */
grid-template-columns: repeat(2, 1fr); 
/* gap: 1rem;  */
gap: 1rem 2rem; 
margin: 20px 0; 
} 
.color-picker-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: relative;

    border: 1px solid #ddd; 
    border-radius: 8px; 
    background: #fff; 
}
.color-picker-item { 
/* background: #fff;  */
padding: 13px; 
/* border: 1px solid #ddd; 
border-radius: 8px;  */
height: fit-content;
/* max-width: 300px; */
width: 100%;
display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
/* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */ 
} 
.sketch-picker {
  z-index: 111;
  /* right: -30%; */
  /* right: -25%; */
  /* width: 200px; */
  position: absolute;
  /* top: -400%; */
  top: -800%;
  left: 107%;
}
.color-info { 
margin-top: 20px; 
font-weight: bold; 
} 

.color-preview { 
display: flex; 
align-items: center; 
margin-top: 10px; 
} 

.color-box { 
  height: 30px;
  width: 30px;
  border: 1px solid #000;
  cursor: pointer;
  border-radius: 5px;
} 
.colorpicker-name{
  font-size: 15px;
  /* margin-top: 20px; */
  font-weight: bold;
}
.color-box-container{
  border: 1px solid #fff;
  padding: 2px;
  border-radius: 5px;
  position: relative;
}
.color-picker-info{
  display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    gap: 40px;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid #ddd;
    /* max-width: 230px; */
}
.color-picker-info-detail{
  display: flex;
  align-items: center;
  gap: 6px;
}
.reset-button { 
  color: grey;
/* margin-left: 20px;  */
padding: 5px; 
/* border: 1px solid black;  */
border-radius: 5px; 
/* background: #f5f5f5;  */
cursor: pointer; 
/* transition: background 0.3s ease;  */
} 
.hex-color-name{
  font-size: 13px;
  white-space: nowrap;
}
.reset-button:hover { 
/* background: #e0e0e0;  */
} 

@media (max-width: 1470px) { 
.color-picker-container { 
/* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));  */
grid-template-columns: repeat(1,1fr);
gap: 1rem 3rem;
} 
} 

@media (max-width: 480px) { 
.color-picker-container { 
grid-template-columns: 1fr; 
gap: 1rem; 
} 
.color-picker-info{
  gap: 35px;
}
.color-picker-item { 
padding: 15px; 
} 

.reset-button { 
margin-left: 10px; 
padding: 5px; 
} 
}
