.second-sec{
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    height: auto;
}
.align-center{
    align-items: center;
}
.blue-button{
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #0E7ADE;
    box-shadow: 4px 8px 16px 0px rgba(14, 122, 222, 0.25);
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    color: #fff;
}
.second-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 12% !important;
}
.second-text h2{
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    color: #fff;
    position: relative;
    z-index: 999999;
}
.second-text h5{
    color:#D3D3D3;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding: 20px 0;
}
.border-blue{
    border-top: 1px solid #0E7ADE;
    padding: 100px 0 0;
}

.left-slide{
    position: absolute;
    top: 35%;
    left: -270px;
    animation: leftslideanim 5s ease-in-out infinite;
}

.right-slide{
    position: absolute;
    top: 17%;
    right: 0;
    animation: rightslideanim 5s ease-in-out infinite;
    
}
.relative{
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

@keyframes leftslideanim {
    0% {
      transform: translateX(-50px);
    }
    20% {
      transform: translateX(265px);
    }
    80% {
      transform: translateX(265px);
    }
    100% {
      transform: translateX(-50px);
    }
  }
@keyframes rightslideanim {
    0% {
      transform: translateX(320px);
    }
    20% {
      transform: translateX(-10px);
    }
    80% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(320px);
    }
  }

  @media screen and (max-width: 767px) {
    .second-sec .relative img{
      width: 50%;
    }
    .left-slide{
      animation: none;
      top: 35%;
      left: 20px;
    }
    .left-slide img{
      width: 60%;
    }
    .right-slide{
      animation: none;
      right: -83px;
    }
    .right-slide img{
      width: 70%;
    }
    .second-text h2 {
      font-size: 26px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
  }
  .second-text h5 {
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    padding: 10px 0;
  }
  .second-text{
    padding-right: 0 !important;
    align-items: center;
  }

}