.ProfileUpdatedButton{
    background: linear-gradient(90deg,#01c3ccdb,#2a76e8f2) !important;
    border: none ;
    border-radius: 25px ;
    color: #fff ;
    font-size: 18px ;
    font-weight: 500 ;
    margin: 0 5px ;
    padding: 10px 30px ;
    z-index: 99 ;
}


.ProfileUpdatedButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ProfileNameWid{
    width: 100% !important;
}

.ProfileChangepassWid{
    width: 100% !important;
    margin: 5px 0 !important;
}
.UpdatePasswordWrapper{
    display: flex;
    flex-direction: column;
}


@media(max-width:991px){
    .ProfileContainer{
        display: flex;
        flex-direction: column;
    }
}

@media(max-width: 991px){
    .ProfileWrapper{
        width: 100% !important;
    }
}

@media(max-width:991px){
    .ProfileflexContainer{
        display: flex !important;
    }
}

@media(max-width: 991px){
    #PersonalInput{
        width: 50% !important;
    }
}