
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
/* templat css */
/* #temp3 .main-temaplt{  font-family: 'lato', sans-serif !important;} */
#temp3 .wtemplate{background: #fcfbfb;}
#temp3 ul.gallerylist li {
    margin: 4px 4px;
    width: 28%;
    border-radius: 18px;
}


#temp3 .theme3-gallery-wrapper{
    position: relative;
  }
  #temp3 .theme3-gallery-inner-wrapper{
    position: absolute;
    top: -30px;
    right: 6px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
  }
  
  #temp3 .theme3-gallery-inner-wrapper img {
    cursor: pointer;
  }



#temp3 ul.gallerylist li img {
    border-radius: 16px;
}
#temp3 h3.sectitle {
    font-size: 22px;
    margin-bottom: 15px;
    color: #1e1e1e;
    font-weight: 400;
}
#temp3 .servesbox {
    padding: 0;
    margin: 30px 6px 0;
}
#temp3 p.para {
    font-size: 15px;
    font-weight: 500;
    word-break: break-word;
}
    
#temp3 .sec {
    background: #f1f1ee;
    border-radius: 14px;
    padding: 30px;
    margin-bottom: 18px;
    margin: 23px 23px;
}

#temp3 .servicesName.sec {
    /* padding: 30px 30px 0; */
}

#temp3 article.abouttemplate.sec {
    text-align: center;
}

#temp3 h3.resume-username {
    color: #ff5a22;
    font-size: 30px;
    font-weight: 700;
    word-break: break-word;
}

#temp3 input.form-control {
    border-radius: 11px;
}
#temp3 textarea.form-control{ border-radius: 11px;}

#temp3 select.form-select{ border-radius: 11px;}

#temp3 button.btn.btn-submitform {
    background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgb(0 0 0), rgb(0 0 0));
    width: 100%;
    color: #fff;
    border-radius: 7px;
    padding: 15px 0;
    font-size: 18px;
}
#temp3 ul.socil-link li {
    width: 60px;
    height: 60px;
    display: flex;
    text-align: center;
    line-height: 40px;
    font-size: 34px;
    border-radius: 9px;
    background: #FFFCFC;
    box-shadow: 0px 4px 10px 0px rgba(179, 156, 156, 0.25);
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
#temp3 article.imgsechere.sec {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 0;
}

#temp3 article.personalinfo {
    text-align: center;
}

#temp3 article.qrcode-open{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp3 .logo-profile {
    position: absolute;
    top: 90px;
    left: 0px;
    right: 0px;
    z-index: 2;
}

#temp3 article.personalinfo {
    margin-top: 60px;
}

#temp3 button.btn.btnmakeappitmnets{    background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    width: 100%;
    color: #fff;
    border-radius: 7px;
    padding: 15px 0;
    font-size: 18px;
}
#temp3 .timelist{    
    margin: 10px 0 0;
    padding: 0;
}

#temp3 ul.timelist li .day {
    margin-right: 28px;
    font-size: 15px;
    font-weight: 500;
    width: 30%;
}

#temp3 ul.servesbox li {
    position: relative;
}

#temp3 ul.conlink li i {
    min-width: 40px;
    height: 40px;
    background: antiquewhite;
    text-align: center;
    line-height: 40px;
    background: linear-gradient(99.5deg, #FE8F43 -20.5%, #FF9635 112.82%);
    color: #fff;
    border-radius: 55px;
}
#temp3 ul.conlink{
    padding: 0px;
}
#temp3 ul.conlink li span{
    padding-left: 10px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 800;
    color: #505050;
    text-align: left;
    padding-top: 8px;
}
#temp3 ul.conlink li {
    margin-bottom: 19px;
    display: flex;
}
#temp3 ul.conlink li a{
    display: flex;
    align-items: flex-start;
}

#temp3 p.titleuser {
    color: #808080;
    margin: 0;
    font-size: 18px;
    word-break: break-word;
}
#temp3 article.businesshour.sec h3 {
    text-align: center;
}
#temp3 ul.servesbox li{ position: relative;}

#temp3 ul.servesbox li:before {
    content: "";
    width: 15px;
    height: 15px;
    background: #ff752a;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: -9px;
    top: 0px;
}
#temp3 ul.servesbox li {
    font-weight: 600;
    font-size: 18px;
    color: #202020;
    padding-left: 15px;
    padding-top: 0px;
    padding-bottom: 40px;
    /* border-left: 2px solid #ff5c23; */
    line-height: 15px;
}
#temp3 ul.timelist li {
    position: relative;
}
#temp3 ul.timelist li:before {
    content: "\f017";
    font-family: 'FontAwesome';
    color: #ff5520;
    margin-right: 11px;
}

#temp3  .time {
    font-weight: 400;
    font-size: 15px;
    font-weight: 500;
}


#temp3 .testimoniyalbox.sec {
    background: #f8f8f8;
    color: #000;
  }

  #temp3 .testimonialimg {
    border: 5px solid #ffc708;
  }
  #temp3 .testimonialcontent {
    display: flex;
    margin: 10px 0px;
    align-items: flex-start;
  }
  
  #temp3 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
  }
  
  #temp3 .testiprofile {
    width: 170px;
    height: 170px;
  }
  
  #temp3 .testimonialname {
    font-size: 20px;
  }
  #temp3 .testimonialdescription {
    font-size: 15px;
    text-align: left;
    line-height: 22px;
    padding-top: 5px;
  }
  #temp3 .testibox {
    text-align: center;
    width: 460px;
  }
  #temp3 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
  }
  #temp3 .testimonials {
    display: flex;
    width: max-content;
  }
  #temp3 .testiimg {
    max-width: 90px;
    max-height: 90px;
  }
  #temp3 .testimonialcontent img {
    /* border: 3px solid #ffc708 !important; */
    margin: 0 !important;
    border-radius: 55px;
    object-fit: cover;
    width: 90px;
    height: 90px;
  }
  #temp3 .sectitle.testimonial {
    color: #000;
  }
#temp3 .testibox {
    text-align: justify;
    position: relative;
}
#temp3 label.form-label {
    color: #FF9635 !important;
    font-size: 14px;
}

#temp3 .clintname {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    width: 50%;
}


/* comman css */

#temp3 .testibox {
    text-align: center;
}



#temp3 h1, h2, h3, h4, h5, h6, p{ margin: 0px;}
#temp3 .main-temaplt ul{ list-style-type: none; padding: 0px;}
#temp3 ul {
    margin: 0px;
}
#temp3 .main-temaplt a{ text-decoration: none; color: #000;}
#temp3 .logo-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
#theme3 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}
ul.startaring {
    margin-top: 16px;
}
img.mx-auto.d-block.img-fluid.coverimghere {

}

.wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
}
#temp3 ul.socil-link {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px;
    margin-top: 30px;
    gap: 10px;
}
#temp3 ul.socil-link-inner {
    display: flex;
    justify-content: center;
    padding: 0px;
    margin-top: 30px;
    gap: 8px;
}
#temp3 ul.timelist li {
    display: flex;
    margin-bottom: 14px;
    align-items: center;
}
#temp3 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}
#temp3 ul.gallerylist li {
    margin: 4px 4px;
}
#temp3 article.fixedfooter {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    background: transparent;
}
#temp3 .personalinfo.modal-info {
    text-align: center;
}
#temp3 .fixedd {
    position: fixed;
    bottom: 0px;
    width: inherit;
    background: #fcfbfb;
    transform: translate(0%, 0px);
    padding: 0;
    box-shadow: 0px 0px 18px 5px #0000000d;
    z-index: 98;
}

#temp3 .addtocinatct {
    background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%),
linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));

    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px;
}
#temp3 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp3 .footerleft {
    background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%),
linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));

    text-align: center;
    line-height: 35px;
    color: #fff;
    border-radius: 50%;
}



#temp3 .qrcodeshaere {
    cursor: pointer;
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
}

#temp3 .addtocinatct {
    cursor: pointer;
}

@media (max-width: 480px){
    .fixedd{ width: 100vw;}
.coverimghere {
    width: 100% !IMPORTANT;
}
    ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
}

.wtemplate {
    padding: 0px;
}
#temp3 .sec {
    background: #F8F8F8;
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 18px;
    margin: 21px 9px;
}



}

#temp3 .modal-content{
    width: 80%;
    border-radius: 20px;
  } 
  #temp3 .personalinfo.modal-info{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  #temp3 .personalinfo.modal-info h3{
    padding-top: 10px;
    font-size: 22px;
  }
  #temp3 .personalinfo.modal-info p {
    font-size: 16px;
  }
  #temp3 .personalinfo canvas{
    width: 260px !important;
    height: 260px !important;
  }

  #temp3 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }

  #temp3 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
  }
  #temp3 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
  }
  #temp3 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
  }
  #temp3 .carousel-btn {
    background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    color: #ffffff;
    border-radius: 50%;
    font-size: 18px;
    margin: 0 10px 0 0px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
  }
  #temp3 .carousel-container {
    display: flex;
    justify-content: center;
    padding-left: 0;
  }

  #temp3 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}
#temp3 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}
#temp3 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp3 .twoimages{
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  #temp3 .skeleton-blog{
    margin-bottom: 2em;
    animation: scale 4s infinite;
   }
  
   #temp3 .skeleton-footer{
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
   }

   @media (max-width:520px){
    #temp3 .fixedd {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        background: #fcfbfb;
        transform: translate(0%, 0px);
        padding: 0;
        box-shadow: 0px 0px 18px 5px #0000000d;
        z-index: 98;
    }
   }

#temp3   .qr-logo-image{
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width:80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
  margin: 0 auto;
  border: 3px solid #FF6F28;
  background: white;
  }
#temp3  canvas{
  border-radius: 20px;
  }
#temp3  .canvas-container{
    display: flex;
    justify-content: center;
  }

  #temp3 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}
#temp3 .calanderIcon{
    position: absolute;
    top: 15px;
    right: 12px;
    cursor: pointer;
  }

  #temp3 .extmakeappointmentsubmitbtn{
    background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    width: 100% !important;
    color: #fff;
    border-radius: 20px !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
  }

