@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,500;1,600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
/* templat css */
:root {
  --bg-color: #fffaec;
  --heading-color: #fff; 
  --text-color: #fff;
  --input-bg: #fff;  
  --input-color: #D7BD97;
  --section-spacing: 32px;
  --footer-spacing:64px; 
}
body {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

#temp40 .theme15-gallery-wrapper{
  position: relative;
}
#temp40 .theme15-gallery-inner-wrapper{
  position: absolute;
  top: -40px;
  right: 6px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

#temp40 .theme15-gallery-inner-wrapper img {
  cursor: pointer;
  filter: invert(1);
}


#temp40 .main-temaplt { 
  font-family: "Work Sans", sans-serif !important;
  margin-bottom: 10px;
}
#temp40 .wtemplate {
  box-sizing: border-box;
   background: #242424; 
   border-radius: 0 0 22px 32px;
  width: 520px;
  position: relative;
}
#temp40 .header-wrapper{
  box-sizing: border-box;
  width:100%;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#temp40 .header-right{
  width: 66px;
}
#temp40 .header-left{
  width:44px;
}
#temp40 .aboutus-desc {
 color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
}
#temp40 .strip {
  width: 100%;
  position: absolute;
  top: 174px;
}
#temp40 .stripservice {
  width: 100%;
}
#temp40 article.personalinfo.sec {
  /* background: url('../../../../Images/thene15_rectangle_bg.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  height: 650px;
  padding: 19px;
  position: relative;   
  margin: 40px 27px 32px 27px;
  background-color: #383838;
  border-radius: 180px 180px 50px 50px;
}
#temp40 article.personalinfo.extheight {
  height:800px;
}
#temp40 .bannerimg-info{
    height: 480px;
    width: 100%;
    border-radius: 180px;
    overflow: hidden;
}

#temp40 article.personalinfo .banner{
  width:100%;
  /* min-height: 480px;
  max-height:480px; */
}

#temp40 .sectitle.testimonial {
  padding: o;
}
#temp40 .sectitle.social {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color:#fff;
  padding-bottom: 0;
}
#temp40 .sectitle.gallery {
  color: #fff;
}
#temp40 .owl-carousel .owl-nav button.owl-prev {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-left: 85%;
}
#temp40 .owl-carousel .owl-nav button.owl-next {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-right: 10%;
}
#temp40 .owl-carousel button.owl-dot {
  background-color: #ffc708 !important;
}
#temp40 .carousel-btn {
  background-color: #fff;
  border: 1px solid #fff;
  color: #000;
  border-radius: 50%;
  font-size: 18px;
  margin: 0 10px 0 0px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#temp40 .carousel-container {
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-top: 15px;
}
#temp40 .transition-prop {
  transition: transform 2s ease-in-out;
}
#temp40 article.businesshour.sec {
  background-color: transparent !important;
  color: #000;
  /*background-image: url("../../../../Images/theme6buildings.png");
  background-position: center bottom;
  background-repeat: no-repeat;*/
  padding: 0 20px 0 20px;
}
#temp40 h3.sectitle { 
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
  text-align: center;
}
#temp40 h3.sectitle.business {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
  text-align: center !important;
}
#temp40 h1 img.mx-auto.d-block.img-fluid.logo-profile {
  position: absolute;
  top: 65px;
  left: 9px;
  border: -26px;
}


#temp40 article.conatctform.sec {
  background-color: transparent;
  margin: 0;
}
#temp40 .innerinfo {
 position:absolute;
 width:100%;
 left:0;
 bottom:20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}

#temp40 h3.username { 
  font-size: 26px;
  font-weight: 700;
  color: #fff; 
  padding: 0; 
  height: 34px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
}
#temp40 button.btn.btnmakeappitmnets {
  background: #fff;
  width: 100%;
  color: #181818 ;
  border-radius: 0;
  padding: 18px 0;
  font-size: 14px;
  text-transform: uppercase;
}
#temp40 .makeappoint {
  color: #181817 ;
}

#temp40 .footshadow {
  padding: 0 10px !important;
}

#temp40 button.btn.btn-submitform {
  margin-top: 11px;
  width:100%;
  background: #fff;
  border:0;
  outline: 0;
  height:46px;
  text-align: center;
  color:#fff;
  font-size: 14px;
  font-weight: 500;  
  border-radius: 0;
}

#temp40 button.btn.btn-submitform {
  color: #181818 ;
}

#temp40 p.titleuser {
  color: #fff;
  word-break: break-word;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
#temp40 ul.socil-link li a {
  color: #242424;
  font-size: 18px;
  width: 35px !important;
  height: 35px !important;
  background-color: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  }
#temp40 img.mx-auto.d-block.img-fluid.logo-profile {
  position: relative;
  z-index: 1;
  margin: 0 auto 0 auto;
}

#temp40 .contact.sec {
  width: 100%;
}
#temp40 .conlink {
  background-color: transparent;
  width: 100%;
}
#temp40 ul.conlink li {
  margin-bottom: 17px;
}
#temp40 article.gallery.sec {
  background: transparent;
  color: #fff;
  margin-top: 32px;
}
#temp40 ul.socil-link li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#temp40 article.contact.sec {
  background: transparent;
}
#temp40 .contact-us-grid {
  align-items: center;
  display: flex;
  padding: 15px 0 0 0;
  flex-direction: column;
}
#temp40 .contact-info-container {
  padding: 0 30px;
}
#temp40 .vertical-line {
  width: 1px;
  height: 10px; /* Set the same height as the grid items */
  background: transparent; /* Set your desired line color */
  margin: 0 10px; /* Adjust the margin as needed */
}
#temp40 .contact-info-details a  {
  text-align: center;
}
#temp40 .contactinfotext {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-align: center;
}
#temp40 .contact-info-label {
  font-size: 15px;
  color: #181818;
  text-transform: uppercase;
  font-weight: 600;
}
#temp40 .contact-info-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#temp40 .contact-info-icon {
  font-size: 18px;
  color: #fff;  
  margin-right: 10px;
}
#temp40 .contactinfolink {
}
#temp40 ul.servesbox li {
  /* margin: 19px; */
  /* box-shadow: 0px 1px 15px 2px #0165FC4D; */
  /* background: #0165FC; */
  /* color: #fff; */
  /* padding: 5px 15px; */
  /* border-radius: 23px; */
  font-size: 15px;
  padding-bottom: 20px;
  position: relative;
  list-style: unset;
  padding-left: 25px;
  /* padding-left: 15px; */
}
#temp40 ul.servesbox li:before {
  width: 15px;
  height: 15px;
  position: absolute; 
  left: 0;
  top: 6px; 
  content: ""; 
  border-radius: 50%; 
  background: #ffc708;
}
#temp40 ul.servesbox {
  list-style-type: none !important;
  margin: 20px 0 0;
  padding: 0;
}

#temp40 article.abouttemplate.sec {
  margin: 10px 0 0 0;
}
#temp40 .social-link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#temp40 .social-name {
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 10px;
  color: #181818;
}
#temp40 .sectitle.desc {
  font-size: 12px;
  color: #fff ;
  text-align: center;
  font-weight: 400;
  line-height: 18px;
  word-break: break-word;
}
#temp40 .sec {
  padding: 0 25px 32px 25px;
}
#temp40 .testimoniyalbox.sec {
  background: transparent;
  color: #000;
  padding: 0 30px 30px 30px;
}
#temp40 .testimonialimg {
  border: 5px solid #ffc708;
}
#temp40 .testimonialcontent {
 
  display: flex;
    margin: 0 auto;
    align-items: flex-start;
}

#temp40 .testimonialcontent .testiprofile {
  display: flex;
  margin: 25px;
  align-items: center;
}

#temp40 .testiprofile {
  width: 170px;
  height: 170px;
}

#temp40 .testimonialname {
  font-size: 24px;
  color:#fff;
  text-transform: uppercase;
  font-weight: 600;
}
#temp40 ul.timelist li .day {
  /* margin-right: 19px; */
  width: 30%;
}
#temp40 ul.timelist li .time {
  /* margin-right: 19px; */
  width: 65%;
  font-weight: 600;
}
#temp40 ul.timelist li .colon {
  /* margin-right: 19px; */
  width: 5%;
}
#temp40 .testimonialdescription {
  width: 100%;
  font-size: 15px;
  text-align: left;
  line-height: 22px;
  padding-top: 5px;
  color:#fff;
}
#temp40 #temp40 label.form-label {
  display: none;
}
/* comman css */

#temp40 .testibox {
  text-align: center;
  width: 460px;
  max-height:250px;
  position: relative;
}
#temp40 .testimonials-container {
  overflow: hidden;
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out 0s;
}
#temp40 .testimonials {
  display: flex;
  width: max-content;
}

#temp40 h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}
#temp40 .main-temaplt ul {
  list-style-type: none;
  padding: 0;
  /* padding: 0px 40px; */
}
#temp40 ul {
  margin: 0px;
}
#temp40 .main-temaplt a {
  text-decoration: none;
  /* color: #000; */
  color: #fff;
  text-align: center;
}
#temp40 .logo-profile {
  box-sizing: border-box;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 10px solid #242424;
    background-color: #fff;
    /* padding: 20px; */
}
#temp40 .clint-img2 {
  width:100%;
  border-radius: o;
  margin: 0 auto;
}

#temp40 .testiimg {
  width:100%;
  max-width: 100%; 
  background: #000;
  opacity: 0.6;
}

#temp40 .clintname {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 16px;
  position: absolute;
  bottom: 15px;
}
#temp40 .testimonialcontent img {
  border: 0 solid #000 !important;
  margin: 0  auto !important;
}
#temp40 ul.startaring {
  /* margin-top: 16px; */
  /* margin: 0 10px; */
  margin: 0;
}
#temp40 img.mx-auto.d-block.img-fluid.coverimghere {
  width: 500px;
  height: 300px;
}


#temp40 ul.socil-link {
  display: flex;
  justify-content: center;
  gap: 10px;
margin: 0;
  padding: 0;
  flex-wrap: wrap;
}
#temp40 .service-list {
}

#temp40 .service-list {
  position: relative;
}
#temp40 .service-list::before {
  content: "";
  position: absolute;
  min-width: 13px;
  min-height: 13px;
  border-radius: 50px;
  top: 5px;
  left: 0;
}

#temp40 article.servicesName.sec {
  color: #fff;
  position: relative;
}
#temp40 .sectitle.service {
  /* color: #000; */
  color: #fff;
  text-align: left;
  font-size: 20px;
}
#temp40 .sectitle.about {
  color: #fff;
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
#temp40 .sectitle.testimonial {
  color: #fff;
}
#temp40 .sectitle.contact {
font-size:24px;
line-height: 46px;
font-weight: 600;
text-transform: uppercase;
  color: #fff;
  margin: 0;
  padding: 0 0 15px 0;
}
#temp40 .sectitle.contactinfo {
  color: #fff;
  margin: 0;
  padding: 0;
}
#temp40 ul.timelist li {
  display: flex;
  margin-bottom: 10px;
  font-size: 16px;
  color: #fff;
}
#temp40 ul.timelist {
  margin: 20px 0 0;
  padding: 0;
}
#temp40 ul.gallerylist {
  display: flex;
  flex-wrap: wrap;
}
#temp40 ul.gallerylist li {
  margin: 4px 4px;
  width: 46%;
}
#temp40 article.fixedfooter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #fff ;
  align-items: center;
}
#temp40 .personalinfo.modal-info {
  text-align: center;
}
#temp40 .fixedd {
  position: fixed;
  bottom: 0px;
  width: inherit;
  background: #fff;
  transform: translate(0%, 0px);
  padding: 0 10px;
  box-shadow: 0px 0px 13px 5px #00000014;
  z-index: 98;
  /* border-radius: 0 0 20px 20px; */
}

@media(max-width: 520px){
  #temp1 .fixedd {
      width: 100vw;
    }
  }
  
#temp40 .space-bottom {
  margin-top: 60px;
}

#temp40 .addtocinatct {
  color: #000;
  padding: 10px 15px;
  border-radius: 23px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#temp40 ul.startaring {
  display: flex;
  justify-content: center;
  color: #ffc107;
  font-size: 10px;
}

#temp40 .footerleft {
  background: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -35px;
  width: 110px;
  height: 110px;
  margin: 0 auto;
  border: 8px solid #fff;
}

#temp40 .extmakeappointmentsubmitbtn{
  background: #000 ;
    width: 100% !important;
    color: #fff;
    border-radius: 15px !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
}

#temp40 .qrcodeshaere {
  cursor: pointer;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 56px;
  color: #fff;
}
#temp40 .addtocinatct {
  cursor: pointer;
  color: #000;
}

#temp40 .sec-common {
  /* margin-top: 32px; */
}



/*
Image carousel
*/
#temp40 .image-carousel {
  position: relative;
  width: 60%;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

#temp40 button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

#temp40 .image-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}


#temp40 .modal-content{
  width: 80%;
  border-radius: 20px;
} 
#temp40 .personalinfo.modal-info{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
#temp40 .personalinfo.modal-info h3{
  padding-top: 10px;
  font-size: 22px;
}
#temp40 .personalinfo.modal-info p {
  font-size: 16px;
}
#temp40 .personalinfo canvas{
  width: 260px !important;
  height: 260px !important;
}

#temp40 .twoimages{
  display: flex;
  flex-direction: row;
  gap: 15px;
}

#temp40 article.gallery.sec.sec-common h3.sectitle {
  padding-bottom: 0;
  font-weight: 600 !important;
}

#temp40 .skeleton-blog{
  margin-bottom: 2em;
  animation: scale 4s infinite;
 }

 #temp40 .skeleton-footer{
  height: 60px;
  width: 100%;
  border-radius: 6px;
  background: #E5E4E2;
  margin-top: 10px;
 }

 #temp40 input[type="date"]::placeholder {
  color: black; 
}

@media screen and (max-width:520px) {
  #temp40 .fixedd {
    position: fixed;
    bottom: 0px;
    width: 100vw;
    background: #fff;
    transform: translate(0%, 0px);
    padding: 10px;
    box-shadow: 0px 0px 13px 5px #00000014;
    z-index: 98;
  }   
}

#temp40 .qr-logo-image{
  width:80px;
  height: 80px;
  position: absolute;
  top: 150px;
  left: 0px;
  right: 0px;
  border-radius: 50%;
  margin: 0 auto;
  border: 4px solid #FFC708;
  background: white;
}
#temp40 canvas{
border-radius: 20px;
}

#temp40 .canvas-container{
  display: flex;
  justify-content: center;
}

#temp40 input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 18px;
  color: white !important;
  display: none;
  /* background-color: #fff; */
}

#temp40 .contact-info-wrapper{
  display: flex;
  flex-wrap: nowrap;
}
::placeholder {
  color:var(--text-color);
  font-weight: 300;
  font-size: 12px;
  font-family: "Lexend", sans-serif;    
}
#temp40 .conatctform .form-control{
    border: 0; 
    border-radius: 0;
    box-shadow: none; 
    display: block;
    border:0;
    outline: 0;
    width:100%;
    box-sizing: border-box;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    margin-bottom: 4px;
    background-color: #fff;
    color:var(--text-color);
    font-size: 12px;
    font-weight: 300;
    font-family: "Lexend", sans-serif;
    position: relative;   
}
#temp40 .contact-form input, #temp40 .contact-form select{
    background-color: #fff;
    color: #878787;
    font-size: 16px;
    font-weight: 500;
    font-family: "Lexend", sans-serif;
    box-shadow: none !important;
}
#temp40 .calanderIcon{
  position:absolute;
  right:10px;
  top:15px;
}

@-moz-document url-prefix() {
  .firefox-hide {
      display: none !important;
  }
}




#tempr15 .reviewratingcontainer{

}

#temp40 .reviewrating{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #fff;
}

#temp40 .ratingspan{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  padding-right: 5px;
}









