@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,500;1,600&display=swap");
/* templat css */
#temp30 .main-temaplt {
  font-family: "Arimo", sans-serif !important;
}
#temp30 .wtemplate {
  /* background: #f7fdff; */
  /* background: #262b2e; */
  background: #01132C;
}

#temp30 .circle {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  /* background: radial-gradient(circle at top, #da5afa 0%, #3570ec 60%); */
  background: radial-gradient(circle at top, #023770 100%, #1970D1 100%);

  /* background: radial-gradient(100% 0 at 100% 0, #DA5AFA 0%, #3570EC 60%); */
  opacity: 60%;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  right: -145px;
  top: -175px;
  transform: rotate(250deg);
}
#temp30 .circle2 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle at top, #023770 100%, #1970D1 100%);
  /* background: radial-gradient(100% 0 at 100% 0, #DA5AFA 0%, #3570EC 60%); */
  opacity: 60%;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  left: -130px;
  top: 208px;
  transform: rotate(250deg);
}
#temp30 article.personalinfo.sec {
  /* background: linear-gradient(89.08deg, #0165FC -3.38%, #122B65 175.37%); */
  /* background-color: #262B2E; */
  padding-top: 75px;
  padding-bottom: 0px;
}
#temp30 h3.sectitle {
  font-size: 22px;
  font-weight: 500;
  margin: 10px 25px;
  color: #fff;
  display: flex;
  justify-content: center;
  font-family: "Oswald", sans-serif;

}
#temp30 .contactinfotext {
  font-size: 18px;
  color: #fff;
  line-height: 20px;
}
#temp30 p.aboutus_paragraph {
  text-align: center;
  width: 100%;
  padding: 5px 0;
  word-break: break-word;
  color: #95A0AD;
  font-size: 14px;
  font-family: "Lato";
}
h1 #temp30 img.mx-auto.d-block.img-fluid.logo-profile {
  position: absolute;
  top: 30px;
  left: 9px;
  border: -26px;
}

#temp30 article.personalinfo.sec {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

#temp30 .innerinfo {
  margin: 15px 0;
  /* margin-bottom: 20px; */
  /* margin-left: 44px; */
}
#temp30 .theme5-gallery-inner-wrapper{
  position: absolute;
  top: -40px;
  right: 0;
  display: flex;
  gap: 20px;
}
#temp30 .theme5-gallery-wrapper{
position: relative;
}

#temp30 .theme5-gallery-inner-wrapper img{
filter: invert(1);
}


#temp30 .social_media_li {
  margin: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  /* background: linear-gradient(99.5deg, #00A7D6 -20.5%, #00D2C9 112.82%); */
}
#temp30 h3.username {
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  word-break: break-word;
  /* margin-bottom: 9px; */
}
#temp30 button.btn.btnmakeappitmnets {
  padding: 13px 15px;
  border-radius: 23px;
  background: #1970d1;
  background-color: transparent;
  width: 100%;
  color: #fff;
  font-size: 14px;
  border: none;
}
.businessHours_sec{
  margin: 0 auto;
    padding: 10px 30px;
    width: 90%;
    border-radius: 10px;
    background: var(--Elements, linear-gradient(202deg, #9365FF 17.75%, #529BFF 85.68%));
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.30);
}
#temp30 button.btn.btn-submitform {
  padding: 15px;
  border-radius: 23px;
  /* background: linear-gradient(to right, #9365ff, #529bff); */
  background:#1970D1;
  /* background-color: transparent; */
  width: 100%;
  font-size: 18px;
  border: none;
  font-family: "Oswald", sans-serif;
}

#temp30 p.titleuser {
  color: #676d75;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  word-break: break-word;
  font-family: "Lato";
}
#temp30 ul.socil-link li a {
  color: #fff;
  font-size: 35px;
  padding: 0;
  
}

#temp30 .gradian-icon{
  font-weight: 800;
  background: -webkit-linear-gradient(#9365ff, #529bff);
  /* background: -webkit-linear-gradient(#1D94F0, #67D8F9); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.infomain {
  color: #fff !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  padding-bottom: 15px;
  align-items: baseline;
}

.info_icons{
  background: -webkit-linear-gradient(#9365FF, #529BFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#temp30 img.mx-auto.d-block.img-fluid.logo-profile {
  /* position: absolute; */
  /* left: 14px; */
  /* top: 15px; */
}
#temp30 ul.conlink li {
  text-align: center;
}
#temp30 article.businesshour.sec {
  /* background: #0165fc; */
  color: #fff;
}
#temp30 ul.socil-link li {
  background-color: #023C7A;
  border-radius: 50%;
  min-width: 60px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

#temp30 article.contact.sec {
  background-color: #022A51;
  margin: 30px 10px;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
}

#temp30 ul.servesbox li {
  margin: 5px;
background: transparent;
color: #fff;
padding: 5px 15px;
font-size: 12px;
border: 1px solid transparent; 
border-image: linear-gradient(to bottom, #6477D6, #02284D);
border-image-slice: 1; 
border-radius: 10px;
  border: 1px solid #6477D6;
  border-radius: 20px;
  padding: 6px 15px;
  color: white;
  font-size: 15px;
  margin: 8px 5px;
}

#temp30 ul.servesbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#temp30 article.abouttemplate.sec {
  /* margin-top: 70px; */
  background-color: #022A51;	
  margin: 10px 10px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
}

#temp30 .social-links-sec {
  background-color: #022A51;
  margin: 30px 10px;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
}
#temp30 .social-name {
  color: #fff;
}
#temp30 .sec {
  padding: 15px 30px;
  /* z-index: 1; */
  position: relative;
}
#temp30 .testimoniyalbox.sec {
  background-color: #022A51;
  margin: 30px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
}
#temp30 .testimonialimg {
  border: 5px solid #ffc708;
}
#temp30 .testimonialcontent {
  display: flex;
  margin: 10px 0;
  align-items: flex-start;
}

#temp30 .testimonialcontent .testiprofile {
  display: flex;
  margin: 25px;
  align-items: center;
}

#temp30 .testiprofile {
  width: 170px;
  height: 170px;
}

#temp30 .testimonialname {
  font-size: 20px;
  text-transform: capitalize;
}
#temp30 .testimonialdescription {
  font-size: 15px;
  text-align: left;
  line-height: 22px;
  padding-top: 5px;
  color: #fff;
}
#temp30 .testibox {
  text-align: center;
  width: 460px;
  @media (max-width:500px){
    width: 380px;
  }
  @media (max-width:400px){
    width: 330px;
  }
}
#temp30 .testimonials-container {
  overflow: hidden;
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out 0s;
}
#temp30 .testimonials {
  display: flex;
  width: max-content;
}
#temp30 .testiimg {
  max-width: 90px;
  max-height: 90px;
}
#temp30 .clintname {
  width: 75%;
  padding-left: 15px;
    min-width: 90px;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #fff;
}
#temp30 .space-bottom {
  margin-top: 75px;
}
#temp30 .testimonialcontent img {
  /* border: 3px solid #ffc708 !important; */
  margin: 0 !important;
  border-radius: 55px;
  width: 90px;
  height: 90px;
}
#temp30 .sectitle.testimonial {
  color: #ffffff;
}
#temp30 ul.timelist li .day {
  margin-right: 19px;
  font-size: 15px;
}
#temp30 ul.timelist li .time {
  margin-left: 19px;
  font-size: 15px;
  font-weight: 600;
}
#temp30 label.form-label {
  display: none;
}
/* comman css */
#temp30 .clear_string {
  color: #fff;
}
.clor-blk {
  color: black;
}
#temp30 .form-control.clear_string.popup::placeholder {
  color: #fff;
}
#temp30 .form-control.clear_string.popup {
  color: #fff !important;
}
#temp30 .form-select {
  background-color: #032346 ;
  color: #fff;
/* 
  border-top: 1px solid #896dff;
  border-bottom: 1px solid #7c78ff;
  background: transparent;
  border-left: 1px solid #797bff;
  border-right: 1px solid #797bff; */
  border: 1px solid #1970D1;
  padding: 14px 7.5px;
  /* background-color: #383f46; */
  opacity: 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../../../../Images/d-arrow-w.png);
  background-repeat: no-repeat;
  background-position: right 20px center;
}
#temp30 .form-select option {
  color: #fff;
}
#temp30 .testibox {
  text-align: center;
}

#temp30 h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}
#temp30 .main-temaplt ul {
  list-style-type: none;
  padding: 0px;
}
#temp30 ul {
  margin: 0px;
  padding: 0 10px;
}
#temp30 .main-temaplt a {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: white;
  /* padding: 10px 0; */
  line-height: 50px;
}
#temp30 .logo-profile {
  /* background: linear-gradient(#d68b64, #e6497e); */
  /* padding: 2px; */
  border: 2px solid #003d78 !important;
  padding: 6px;
  
  /* box-shadow: 0 0 0 6px #262B2E, 0 0 0 8px #de6a71; */

  width: 110px !important;
  height: 110px !important;
  border-radius: 50%;
}
#temp30 .clint-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 12px 0px;
}
#temp30 ul.startaring {
  margin-top: 16px;
}
#temp30 img.mx-auto.d-block.img-fluid.coverimghere {
  width: 500px;
  height: 300px;
}

#temp30 .wtemplate {
  width: 520px;
  /* position: relative; */
  padding: 0px;
  /* overflow: hidden; */
}
#temp30 .about-desc {
  font-size: 15px;
  color: #95A0AD;
  text-align: center;
}
#temp30 ul.socil-link {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  padding: 15px 0;
  gap: 10px;
}
#temp30 ul.timelist li {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  color: white;
  margin-bottom: 8px;
}
#temp30 ul.gallerylist {
  display: flex;
  flex-wrap: wrap;
}
#temp30 ul.gallerylist li {
  margin: 4px 4px;
  width: 46%;
}
#temp30 article.fixedfooter {
  display: flex;
  justify-content: space-between;
}
#temp30 .personalinfo.modal-info {
  text-align: center;
}
#temp30 .fixedd {
  position: fixed;
  bottom: 0px;
  width: inherit;
  background: #1970D1;
  transform: translate(0%, 0px);
  padding: 0px;
  box-shadow: 0px 0px 6px;
  z-index: 98;
}

#temp30 .addtocinatct {
  background: transparent;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 23px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#temp30 ul.startaring {
  display: flex;
  justify-content: center;
  color: #ffc107;
  font-size: 10px;
}

#temp30 .footerleft {
  position: absolute;
  display: flex;
  top: -30px;
  left: 0;
  width: 100px;
  height: 100px;
  background: linear-gradient(to right, #023770, #1970D1);
  border: 8px solid #0A1B32;
  text-align: center;
  line-height: 35px;
  color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  right: 0;
  margin: 0 auto;
  /* box-shadow: 0px 0px 9px 2px #000; */
}

#temp30 .qrcodeshaere {
  cursor: pointer;
  font-size: 50px;
}
#temp30 .addtocinatct {
  cursor: pointer;
  
}

@media (max-width: 480px) {
  .fixedd {
    width: 100vw;
  }
  #temp30 .coverimghere {
    width: 100% !important;
  }
  #temp30 ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
  }

  #temp30 .innerinfo {
    /* margin-bottom: 20px; */
  }

  #temp30 .wtemplate {
    padding: 0px;
  }
  #temp30 .fixedd {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
}

#temp30 .servicesName.sec {
  background-color: #022A51;
  margin: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
}

#temp30 .Service_name {
  padding: 15px 0px;
  background-color: #022A51;
  margin: 30px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
}

#temp30 .businesshour.sec {
  /* background: linear-gradient(to bottom, #9365ff, #529bff); */
  background: #022A51;
  margin: 30px 15px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#temp30 .Conatctform_sec {
  padding: 15px 10px;
  background-color: #022A51;
  margin: 30px 25px;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
}

#temp30 .contact-form {
  padding: 0 5px;
  margin: 15px 0;
}

#temp30 .contact-us-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#temp30 .contact-info-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
#temp30 .contact-info-icon i{
  /* color: linear-gradient(to bottom, #023A77, #046CDD); */
  color: #fff;
  /* background: linear-gradient(to bottom, #023A77, #046CDD);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-size: 24px;
}
#temp30 .form-control {
  /* background-color: transparent;
    border: 1px solid transparent;
    border-image: linear-gradient(to right, #9365FF 0%, #529BFF 100%);
    border-image-slice: 1;
    border-radius: 10px; */
  /* border-image: linear-gradient(to bottom, #D68B64, #E6497E); */
  /* border-top: 1px solid #896dff; */
  border: 1px solid #1970D1;
  /* border-bottom: 1px solid #7c78ff; */
  /* background: transparent; */
  /* border-left: 1px solid #797bff; */
  /* border-right: 1px solid #797bff; */
  padding: 14px 7.5px;
  background-color: #032346;
  opacity: 50%;
  color: white;
}
#temp30 .form-control::placeholder {
  color: #555d65;
  font-size: 12px;
}

#temp30 .Contact_Form_sectitle {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 25px 20px 25px;
  color: #fff;
  display: flex;
  justify-content: center;
}

#temp30 .Contact_Form_sectitle input::placeholder {
  color: #555d65;
}

#temp30 .Contact_circle {
  width: 200px;
  height: 200px;
  background-color: gray;
}


#temp30 .modal-content{
  width: 80%;
  border-radius: 20px;
} 
#temp30 .personalinfo.modal-info{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
#temp30 .personalinfo.modal-info h3{
  padding-top: 10px;
  font-size: 22px;
}
#temp30 .personalinfo.modal-info p {
  font-size: 16px;
}
#temp30 .personalinfo canvas{
  width: 260px !important;
  height: 260px !important;
}

#temp30 .image-carousel {
  position: relative;
  width: 60%;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

#temp30 .owl-carousel .owl-nav button.owl-prev {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-left: 85%;
}
#temp30 .owl-carousel .owl-nav button.owl-next {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-right: 10%;
}
#temp30 .owl-carousel button.owl-dot {
  background-color: #ffc708 !important;
}
#temp30 .carousel-btn {
  background-color: #1970D1;
  border: 1px solid #1970D1;
  color: #ffffff;
  border-radius: 50%;
  font-size: 18px;
  margin: 0 10px 0 0px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#temp30 .carousel-container {
  display: flex;
  justify-content: center;
  padding-left: 0px;
}

#temp30 .personalinfo canvas {
  width: 260px !important;
  height: 260px !important;
}

#temp30 .personalinfo.modal-info h3 {
  padding-top: 10px;
    font-size: 22px;
    font-weight: 600;
    color: #181817;
    text-transform: uppercase;
}

#temp30 .personalinfo.modal-info p {
  font-size: 16px;
  color: #181817;
}


#temp30 .personalinfo.modal-info {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

#temp30 .twoimages{
  display: flex;
  flex-direction: row;
  gap: 15px;
}

#temp30 .skeleton-blog{
  margin-bottom: 2em;
  animation: scale 4s infinite;
 }

 #temp30 .skeleton-footer{
  height: 40px;
  width: 100%;
  border-radius: 6px;
  background: #E5E4E2;
  margin-top: 10px;
 }

 @media (max-width:520px){
  #temp30 .fixedd {
    position: fixed;
    bottom: 0px;
    width: 100vw;
    background: #181818;
    transform: translate(0%, 0px);
    padding: 0px;
    box-shadow: 0px 0px 6px;
    z-index: 98;
  }
  #temp30 .sec {
    padding: 15px 10px;
}
 }
 @media (max-width:400px){
    #temp30 .footerleft {
      width: 80px;
      height: 80px;
      border: 4px solid #24292ce0;
    }
    #temp30 .qrcodeshaere {
      font-size: 40px;
    }
    #temp30 ul.socil-link {
      gap: 10px;
    }
 }
 /* @media (max-width:480px){
  #temp30 .testibox {
    width: 330px;
}
 } */

#temp30  .qr-logo-image{
  /* style={{
    width: "80px",
    height: "80px",
    position: "absolute",
    top: "150px",
    left: "150px",
    borderRadius: "50%",
  }} */
  width:80px;
  height: 80px;
  position: absolute;
  top: 150px;
  left: 0px;
  right: 0px;
  border-radius: 50%;
  margin: 0 auto;
  border: 4px solid #000;
  background: white;
}
#temp30 canvas{
border-radius: 20px;
}
#temp30 .canvas-container{
  display: flex;
  justify-content: center;
}

#temp30 input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 18px;
  color: white !important;
 display: none;
}

#temp30 .calanderIcon{
  position: absolute;
  top: 13px;
  right: 15px;
  cursor: pointer;
}


#temp30 .extmakeappointmentform{
  background-color: #fff !important;
  box-shadow:rgba(0, 0, 0, 0.15) 0px 4px 10px !important;
  border-radius: 15px !important;
  color: #1c1a1a !important;
  border: none !important;
}

#temp30 .extmakeappointmentformplaceholder input::placeholder {
  color: #837e7e !important;
  
}




@-moz-document url-prefix() {
  .firefox-hide {
      display: none !important;
  }
}

#temp30 .reviewratingcontainer{

}

#temp30 .reviewrating{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial;
  font-weight: 400; 
  font-size: 13px;
  color: #95A0AD;
 
}

#temp30 .ratingspan{
  font-family: Arial;
  font-weight: 400;
  font-size: 20px;
  color: #95A0AD;
  padding-right: 5px;
}