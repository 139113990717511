
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,500;1,600&display=swap');
/* templat css */
#temp4 .main-temaplt{  font-family: 'Arimo', sans-serif !important;}
#temp4 .wtemplate {
    background: #f7fdff;
}


#temp4 article.personalinfo.sec {
    background: linear-gradient(89.08deg, #0165FC -3.38%, #122B65 175.37%);
    height: 180px;
}
#temp4 h3.sectitle {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #303030;
    text-align: left;
}
#temp4 p.titleuser {
    font-size: 14px;
    text-align: justify;
    padding-right: 20px;
    word-break: break-word;
}

h1
#temp4 img.mx-auto.d-block.img-fluid.logo-profile {
    position: absolute;
    top: 30px;
    left: 9px;
    border: -26px;
}

#temp4 article.personalinfo.sec {
    display: flex;
    justify-content: center;
    margin: 0;
}
#temp4 article ul.timelist {
    margin-top: 20px;
    list-style-type: none;
    font-size: 16px;
    font-weight: 500;
}

#temp4  .innerinfo {
    margin-left: 10px;
    margin-top: 15px;
}

#temp4 h3.resume-username {
    font-size: 22px;
    color: #fff;
    margin-bottom: 3px;
    font-weight: normal;
    word-break: break-word;
}

#temp4 .topprofiletext {
    position: absolute;
    top: 70px;
    left: 30px;
    display: flex;
}
#temp4  button.btn.btnmakeappitmnets {
    background: #0165FC;
    width: 100%;
    color: #fff ;
    padding: 12px;
    border-radius: 10px;
    font-size: 18px;
}
button.btn.btn-submitform{  background: #0165FC;
    background: #0165FC;
    width: 100%;
    color: #fff ;
    padding: 12px;
    border-radius: 10px;
    font-size: 18px;
}

#temp4 p.titleuser {
    color: #fff;
    word-break: break-word;
    margin: 0;
}
#temp4 ul.socil-link li a {
    color: #0261f0;
    font-size: 24px;
}
#temp4  img.mx-auto.d-block.img-fluid.logo-profile {

}
#temp4 ul.conlink{
  padding: 0;
}
#temp4 ul.conlink li {
    margin-bottom: 17px;
    display: flex;
}

#temp4 ul.conlink li a{
    display: flex;
    align-items: flex-start;
    width: 100%;
}
#temp4 ul.conlink li a span{
    font-size: 17px;
    font-weight: 500;
}
#temp4 ul.conlink li i{
    width: 30px;
    height: 30px;
    background: #8ab9ff;
    color: #0261f0;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

#temp4 article.businesshour.sec {
    background: #0165fc;
    color: #fff;
}
#temp4 ul.socil-link li {
    width: 60px;
    height: 60px;
    background: #8ab9ff;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    align-items: center;
}

#temp4 article.contact.sec {
    background: #F8F8F8;
    padding: 30px;
}
#temp4 article.businessHours.sec {
    padding: 30px;
    background-color: #0165fc;
    color: white;
}

#temp4 ul.servesbox li {
    margin: 10px 5px;
    box-shadow: 0px 1px 15px 2px #0165FC4D;
    background: #0165FC;
    color: #fff;
    padding: 10px 25px;
    border-radius: 23px;
    font-size: 14px;
}

#temp4 ul.servesbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px 0;
    /* border-top: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf; */
}

#temp4 article.abouttemplate.sec {
    margin-top: 70px;
}
#temp4 article.abouttemplate p {
    font-size: 15px;
    text-align: justify;
    word-break: break-word;
}

#temp4 .sec {
    padding: 15px 30px;
    margin: 0px 16px 14px;
    border-radius: 4px;
}
#temp4 .testimoniyalbox.sec {
    background: #0165fc;
    color: #fff;
}

  #temp4 .sectitle.testimonial{
    /* padding: 20px; */
    color: #fff;
  }
  #temp4 .testimonialimg {
    border: 5px solid #ffc708;
  }
  #temp4 .testimonialcontent {
    display: flex;
    margin: 10px 0px;
    align-items: flex-start;
  }
  
  #temp4 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
  }
  
  #temp4 .testiprofile {
    width: 170px;
    height: 170px;
  }
  
  #temp4 .testimonialname {
    font-size: 20px;
  }
  #temp4 .testimonialdescription {
    font-size: 15px;
    text-align: left;
    line-height: 22px;
    padding-top: 5px;
    color: #fff;
    width: 90%;
  }
  #temp4 .testibox {
    text-align: left;
    width: 460px;
  }
  #temp4 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
  }
  #temp4 .testimonials {
    display: flex;
    width: max-content;
  }
  #temp4 .testiimg {
    max-width: 90px;
    max-height: 90px;
  }
  #temp4 .clintname {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
  }
  #temp4 .testimonialcontent img {
    border: 3px solid #ffffffb8 !important;
    margin: 0 !important;
    border-radius: 50px;
    object-fit: cover;
    width: 90px;
    height: 90px;
  }
#temp4 ul.timelist li .day {
    margin-right: 19px;
    width: 30%;
}

#temp4 label.form-label {
    display: none;
}
/* comman css */

 h1, h2, h3, h4, h5, h6, p{ margin: 0px;}
 #temp4 .main-temaplt ul{ list-style-type: none; padding: 0px;}
 ul {
    margin: 0px;
}
#temp4 .main-temaplt a{ text-decoration: none; color: #000;}
#temp4 .logo-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
#temp4 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}
#temp4 ul.startaring {
    margin-top: 16px;
}
#temp4 img.mx-auto.d-block.img-fluid.coverimghere {
    width: 500px;
    height: 300px;
}

#temp4 .wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
}
#temp4 ul.socil-link {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 10px;
}
#temp4 ul.timelist li {
    display: flex;
    margin-bottom: 8px;
}
#temp4 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}
#temp4 ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
}
#temp4 article.fixedfooter {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    background: transparent;
}
#temp4 .personalinfo.modal-info {
    text-align: center;
}
#temp4 .fixedd {
    position: fixed;
    bottom: 0px;
    width: inherit;
    background: #f7fdff;
    transform: translate(0%, 0px);
    padding: 10px;
    z-index: 98;
    box-shadow: 0px 0px 8px 1px #00000010;
}

#temp4 .addtocinatct {
    background: #0165fc;
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px;
}
#temp4 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp4 .footerleft {
    background: #0165fc;
    text-align: center;
    line-height: 35px;
    color: #fff;
    border-radius: 50%;
}




#temp4 .qrcodeshaere {
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
}
#temp4 .addtocinatct {
    cursor: pointer;
}


#temp4 .modal-content{
    width: 80%;
    border-radius: 20px;
  } 
  #temp4 .personalinfo.modal-info{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  #temp4 .personalinfo.modal-info h3{
    padding-top: 10px;
    font-size: 22px;
  }
  #temp4 .personalinfo.modal-info p {
    font-size: 16px;
  }
  #temp4 .personalinfo canvas{
    width: 260px !important;
    height: 260px !important;
  }

  #temp4 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }

  #temp4 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
  }
  #temp4 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
  }
  #temp4 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
  }
  #temp4 .carousel-btn {
    background-color: #ffc708;
    border: 1px solid #ffc708;
    color: #2b2626;
    border-radius: 50%;
    font-size: 18px;
    margin: 0 10px 0 0px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #temp4 .carousel-container {
    display: flex;
    justify-content: center;
    padding-left: 0px;
  }

  #temp4 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
  }
  
  #temp4 .personalinfo.modal-info h3 {
    padding-top: 10px;
      font-size: 22px;
      font-weight: 600;
      color: #0b3781;
      text-transform: uppercase;
  }
  
  #temp4 .personalinfo.modal-info p {
    font-size: 16px;
    color: #181817;
  }
  
  
  #temp4 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  #temp4 .twoimages{
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  #temp4 .skeleton-blog{
    margin-bottom: 2em;
    animation: scale 4s infinite;
   }
  
   #temp4 .skeleton-footer{
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
   }

   #temp4 .businessHours h3.sectitle{
    color: #fff;
   }

   @media (max-width:520px){
    #temp4 .fixedd {
      position: fixed;
      bottom: 0px;
      width: 100vw;
      background: #f7fdff;
      transform: translate(0%, 0px);
      padding: 10px;
      z-index: 98;
      box-shadow: 0px 0px 8px 1px #00000010;
    }
   }
   @media (max-width:480px){
    #temp4 .testibox {
      width: 330px;
    }
   }
   

#temp4   .qr-logo-image{
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width:80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
    margin: 0 auto;
    background: white;
  
  }
#temp4  canvas{
  border-radius: 20px;
  }
#temp4  .canvas-container{
    display: flex;
    justify-content: center;
  }


  #temp4 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}
#temp4 .calanderIcon{
    position: absolute;
    top: 15px;
    right: 12px;
    cursor: pointer;
  }


  #temp4 .extmakeappointmentsubmitbtn{
    background: #0165FC;
    width: 100% !important;
    color: #fff ;
    border-radius: 20px !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
  }


  #tempr4 .reviewratingcontainer{

  }
  
  #temp4 .reviewrating{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Arial;
    font-weight: 400;
    font-size: 13px;
    color: #CECECE;
    margin: 0;
  }
  
  #temp4 .ratingspan{
    font-family: Arial;
    font-weight: 600;
    font-size: 16px;
    color: #FFA538;
    padding-right: 5px;
  }