/* fonts.css */
@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('./fonts/Jost-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('./fonts/Jost-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body{
  overflow-x: hidden !important;
}

.hero-content{
  font-family: 'Jost';
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}
.header img{
  width: 120px;
}

.stats-main ul{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  list-style: none;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 50px 0;
}

@media(max-width: 550px){
  .stats-main ul{
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    justify-content: center;
   width: 100%;
    text-align: center;
    padding: 50px 0;
  }
}

@media(max-width: 991px){
  .stats-main ul{
    gap: 50px;
    
  }
}
.stats-main ul h2{
  color: #0359A4;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
}
.stats-main ul h5{
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  padding-top: 20px;
}

.heromain{
    background: linear-gradient(151deg, rgb(24 117 202) 0%, rgba(1,13,15,1) 50%);
    /* height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #424242;
    overflow: hidden;
    position: relative;
}
.heromain h1{
    color: #fff;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    padding-bottom: 30px;
}

.heromain h2{
    color: #D3D3D3;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}
.top-lines{
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}
.top-lines img{
    width: 100%;
}
.trans-lines{
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}

@media(min-width: 1300px) and (max-width: 1600px){
  .trans-lines{
    position: absolute;
    top: 0;
    left: 300px;
    pointer-events: none;
}
}
@media(min-width: 1024px) and (max-width: 1300px){
  .trans-lines{
    position: absolute;
    top: 0;
    left: 120px;
    pointer-events: none;
}
}

@media(min-width: 768px) and (max-width: 1024px){
  .trans-lines{
    position: absolute;
    top: 10px;
    left: 40px;
    pointer-events: none;
}
}

@media(min-width: 425px) and (max-width: 768px){
  .trans-lines{
    top: -100px;
    right: -300px;
}
}

@media(min-width: 350px) and (max-width: 425px){
  .trans-lines{
    top: -150px;
    right: -350px;
}
}


.nfc-card{
    position: absolute;
    top: 200px;
    right: 250px;
    display: flex;
    animation: rotateAndScale 6s infinite;
    animation-delay: 1.5s;
}

@media(max-width: 1200px){
  .nfc-card{
    top: 200px;
    right: 170px;
}
}

@media(max-width: 1024px){
  .nfc-card{
    top: 200px;
    right: 90px;
}
}
@media(max-width: 830px){
  .nfc-card{
    top: 200px;
    right: 40px;
}
}

@keyframes rotateAndScale {
    0% {
      transform: translateX(700px);
    }
    20% {
      transform: translateX(0px);
    }
    25% {
      transform: translatey(-15px);
    }
    30% {
      transform: translatey(0px);
    }
    40% {
      transform: translateX(0px);
    }
    60% {
      transform: translateX(0px);
    }
    80% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(700px);
    }
  }

.blue-button{
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: #0E7ADE;
    box-shadow: 4px 8px 16px 0px rgba(14, 122, 222, 0.25);
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    color: #fff;
}
.dark-button{
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 54px;
    box-shadow: 4px 8px 16px 0px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    color: #fff;
    margin-left: 20px;
    border: 1px solid #ffffff7d;
}
.hero-buttons{
    display: flex;
    margin-top: 20px;
}
.phone-still{
    width: 40%;
    z-index: 9;
    transition: 1s ease-in-out;
}
.align-center{
    align-items: center;
}
.phones{
    display: flex;
    justify-content: center;
}
.nfc-card img{
  width: 100%;
}

@media screen and (max-width: 767px) {
  .col-reverse{
     flex-direction: column-reverse;
     padding-bottom: 80px;
  }
  .stats-main{
    /* display: none; */
    
  }
  .heromain h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    padding-bottom: 10px;
    padding-top: 20px;
    text-align: center;
  }
  .heromain h2 {
    color: #D3D3D3;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }
  .hero-buttons {
    display: flex;
    margin-top: 20px;
    justify-content: center;
  }
  .phones{
    padding-bottom: 50px;
  }
  .nfc-card{
    /* right: 0;
    top: 150px;
    animation: rotateAndScalemobile 3s infinite;
    animation-delay: 1s; */
    right: 0px;
    /* right: auto; */
    left: 80px;
    top: 150px;
    animation: none;
  }
  .nfc-card img{
    width: 90%;
  }
  @keyframes rotateAndScalemobile {
    0% {
      transform: translateX(700px);
    }
    20% {
      transform: translateX(50px);
    }
    25% {
      transform: translatey(15);
      transform: translateX(50px);
    }
    30% {
      transform: translatey(0);
      transform: translateX(50px);
    }
    40% {
      transform: translateX(50px);
    }
    60% {
      transform: translateX(50px);
    }
    80% {
      transform: translateX(50px);
    }
    100% {
      transform: translateX(700px);
    }
  }
}