@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Yesteryear&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Yesteryear&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Yesteryear&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lexend:wght@100..900&family=Yesteryear&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lexend:wght@100..900&family=Yesteryear&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lexend:wght@100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap");


#temp11 .main-temaplt {
  font-family: 'Inter', sans-serif !important;
}


#temp11 .theme11-gallery-wrapper{
  position: relative;
}
#temp11 .theme11-gallery-inner-wrapper{
  position: absolute;
  top: -35px;
  right: 6px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

#temp11 .theme11-gallery-inner-wrapper img {
  cursor: pointer;
  filter: invert(1);
}


#temp11 article.imgsechere.sec {
  position: relative;
  padding: 0px;
  height: 100vh;
}

#temp11 .logo-profile {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 2px solid #fff;
  padding: 10px;
  margin-bottom: 30px;
}

#temp11 .coverimghere {
  clip-path: polygon(58% 0%, 103% 0, 100% 40%, 101% 93%, 58% 75%, 45% 74%, 48% 74%, 0 88%, 0% 42%, 0 0);
}

#temp11 label.form-label {
  display: none;
}

#temp11 .sectitle {
  text-align: center;
  color: #484848;
  font-size: 48px;
  font-family: "Yesteryear", cursive;
  font-weight: 400;
  font-style: normal;
}

#temp11 .abouttemplate p {
  font-size: 20px;
  color: #fff;
  font-weight: 300;
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  text-align: center;
  line-height: 26px;
  word-break: break-word;
}

#temp11 .sec {
  margin-bottom: 40px !important;
  padding: 0;
  margin: 0px 40px;
}

#temp11 .sec11 {
  margin-bottom: 40px !important;
  padding: 0;
  margin: 0px 80px;
  align-items: center;
}
#temp11 .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

@media(max-width: 470px){
  #temp11 .sec11 {
    margin-bottom: 40px !important;
    padding: 0;
    margin: 0px 40px;
  }
}

#temp11 h3.sectitle {
  margin-bottom: 14px;
  color:#fff;
}

#temp11 article.personalinfo.sec {
  position: absolute;
  text-align: center;
  bottom: 8%;
  width: 100%; 
  display: flex;
  flex-direction: column; 
  margin:0 auto;
}
#temp11 article.personalinfo.sec h3, #temp11 article.personalinfo.sec p{
  margin:0;
  padding: 0;
}
#temp11 h3.username {
  font-size: 36px;
  font-family: "Yesteryear", cursive;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  height: 44px !important;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
}

#temp11 p.titleuser {
  font-size: 22px;
  font-weight: 400;
  word-break: break-word;
  color: #fff;
  font-family: "Cinzel", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

#temp11 .wtemplate {
  /*background: #F9FAFA;*/
  text-align: justify;
  background: transparent;
  background: black;
  position: relative;
  z-index: 999;
  background: transparent;
}

#temp11 article.personalinfo.sec {
  background: none;
  box-shadow: none;
}

#temp11 article.imgsechere.sec {
  background: none !important;
  box-shadow: none !important;
}

#temp11 article.imgsechere.sec {
  margin: 0;
  /* height: 170px; */
  margin-bottom: 0 !important;
}

#temp11 .day {
  width: 30%;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}


#temp11 .time {
  font-size: 16px;
  color: #ffff;
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

#temp11 button.btn.btn-submitform {
  background: #fff; 
  width: 100%;
  color: #000 ;
    height: 46px;    
    border-radius: 0px;
    font-size: 14px;
    font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

#temp11 button.btn.btnmakeappitmnets {
  background: #fff;
  color: #000;
  width: 100%; 
  padding: 12px 0;
  font-size: 14px;
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

#temp11 ul.socil-link {
  display: flex;
  justify-content: center;
  align-items: center; 
}

#temp11 ul.socil-link li {
  text-align: center;
  font-size: 24px;
  display: inline-block;
  margin-right: 10px;
}

#temp11 ul.socil-link li:last-child {
  margin-right: 0;
}

#temp11 ul.socil-link li a {
  color: #fff;
  display: inline-block;
}

#temp11 ul.conlink {
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#temp11 ul.conlink li i {
  font-size: 14px;
  width: 25px;
  height: 25px;
  background: #F8F8F8;
  text-align: center;
  line-height: 25px;
  border-radius: 5px;
  margin-right: 15px;
}

#temp11 ul.conlink li span {
  font-size: 16px;
  color: #949494;
  font-weight: 600;
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto; 
  font-style: normal;
  color:#fff;
}
#temp11 ul.conlink li span.social-text {
  color:#fff;
}
#temp11 ul.conlink li {
  margin-bottom: 5px;
  text-align: center;
}

#temp11 ul.servesbox {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

#temp11 ul.servesbox li {
  margin: 5px 8px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 50px;
  border: 1px solid #ddd;
  color:#fff;
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
#temp11 .thmem11-gallery{
  margin-top: 25px;
}
#temp11 .addtocinatct {  
  color: #000; 
  
  font-size: 15px;
}

#temp11 .footerleft { 
 display: flex;
 flex-direction: column;
  color: #000;
  font-size: 19px;  
  justify-content: center;
}

#temp11 .addtocinatct { 
  color: #000;   
  font-size: 15px; 
  display: flex;
    flex-direction: column;
    align-items:center;
}

#temp11 ul.timelist li {
  margin-bottom: 14px;
  font-weight: 600;
}

/* comman css */

#temp11 .testibox {
  text-align: center;
}

#temp11 .testimoniyalbox.sec {
  /* background: #0165fc; */
  /* color: #fff; */
  background: transparent;
  color: #fff;
}

#temp11 .sectitle.testimonial {
  padding: 0 20px;
}

#temp11 .testimonialimg {
  border: 5px solid #ffc708;
}

#temp11 .testimonialcontent {
  display: flex;
  margin: 0 60px 25px 60px;
  align-items: flex-start;
}

#temp11 .testimonialcontent .testiprofile {
  display: flex;
  margin: 25px;
  align-items: center;
}

#temp11 .testiprofile {
  width: 170px;
  height: 170px;
}



#temp11 .testimonialdescription {
  font-size: 16px;
  text-align: justify;
  line-height: 24px;
  padding-top: 0;
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto; 
  font-style: normal;
  font-weight: 400;
  color: #fff;
}

#temp11 .testibox {
  text-align: center;
  width: 430px;
}

#temp11 .testimonials-container {
  overflow: hidden;
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out 0s;
}

#temp11 .testimonials {
  display: flex;
  width: max-content;
}

#temp11 .testiimg {
  max-width: 90px;
  max-height: 90px;
}

#temp11 .clintname {
  display: flex;
  padding-left: 0px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width:100%;
}

#temp11 .testimonialcontent img {
  border: 3px solid #065ba4 !important;
  margin: 0 !important;
  border-radius: 50px;
}

#temp11 .sectitle.testimonial {
  background-color: transparent;
  color: #fff;
}
#temp11 form input, #temp11 form textarea, #temp11 input{
  /* background: #797979; */
  background: #646464ad;
  border: 0.5px solid rgba(255, 255, 255, 0.33);
}

#temp11 select.form-select {
  border-radius: 11px;
  background-color: #646464ad;
  border: 0.5px solid rgba(255, 255, 255, 0.33);
  border-radius: 0px;
  color: #949494;
  height:55px; 
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAJCAYAAAACTR1pAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABtSURBVHgBjY8BDYAwDAQrYRImAQk4AQk4GBJwAI6QgAQklDaQ8GnWdZd8tmW9rSUSmHmkTqQ2a3Sz8MveIQ2SW4MHpQQ/XV/dZl+qykY67OXMP8WRTq8dlCdJAknXRI1ZVpBRyhRRkWPJyK70AIxHzvOygakzAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 17px;
  font-size: 15px;
}
#temp11 select.form-select option{
  /* color: #949494; */
  color: #fff;
}

#temp11 form .form-control, #temp11 .form-control{
  box-sizing: border-box;
  padding: 16px 20px;   
  margin-bottom: 4px;
  /* height:55px; */
  border-radius: 0px;
  border:none;
  /* line-height: 45px; */
  border: 0.5px solid rgba(255, 255, 255, 0.33);
  /* color: #949494; */
  color:#fff;
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

#temp11 h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

#temp11 .main-temaplt ul {
  list-style-type: none;
  padding: 0px;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
}

#temp11 .mainservicebox  ul.servesbox {
display: flex;
flex-wrap: wrap;
flex-direction: row;
}

#temp11 ul {
  margin: 0px;
}

#temp11 .main-temaplt a {
  text-decoration: none;
  color: #000;
}

#temp11 .clint-img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  margin: 12px 0px;
}

#temp11 ul.startaring {
  margin-top: 16px;
}

#temp11 img.mx-auto.d-block.img-fluid.coverimghere {
  /* width: 500px;
    height: 300px; */
}

#temp11 .wtemplate {
  width: 520px;
  position: relative;
  padding: 0px;
}

#temp11 ul.socil-link {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row;
}

#temp11 ul.timelist li {
  display: flex;
  width: 100%;
  justify-content: center;
}

#temp11 ul.gallerylist {
  display: flex;
  flex-wrap: wrap;
}

#temp11 ul.gallerylist li {
  margin: 4px 4px;
  width: 46%;
}

#temp11 article.fixedfooter {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  background: transparent;
  box-shadow: none;
  margin: 0 !important;
}

#temp11 .qrcodeshaere {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
}

#temp11 .personalinfo.modal-info {
  text-align: center;
}

#temp11 .fixedd {
  position: fixed;
  bottom: 0px;
  width: inherit;
  background: #fff;
  transform: translate(0%, 0px);
  padding:15px 10px;
  z-index: 98;
 /* box-shadow: 0px 0px 8px 1px #00000010;*/ 
}
#temp11 .fixedd p{
  font-size: 16px;
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  text-align: center;
}
#temp11 ul.startaring {
  display: flex;
  justify-content: center;
  color: #FFC107;
  font-size: 10px;
}


@media (max-width: 480px) {
  #temp11 .fixedd {
    width: 100vw;
  }

  #temp11 .coverimghere {
    width: 100% !IMPORTANT;
  }

  #temp11 ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
  }

  #temp11 .wtemplate {
    padding: 0px 15px;
  }

}

#temp11 .modal-content {
  width: 80%;
  border-radius: 20px;
}

#temp11 .personalinfo.modal-info {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

#temp11 .personalinfo.modal-info h3 {
  padding-top: 10px;
  font-size: 22px;
}

#temp11 .personalinfo.modal-info p {
  font-size: 16px;
}

#temp11 .personalinfo canvas {
  width: 260px !important;
  height: 260px !important;
}

#temp11 .image-carousel {
  position: relative;
  width: 60%;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

#temp11 .twoimages {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

#temp11 .skeleton-blog {
  margin-bottom: 2em;
  animation: scale 4s infinite;
}

#temp11 .skeleton-footer {
  height: 60px;
  width: 100%;
  border-radius: 6px;
  background: #E5E4E2;
  margin-top: 10px;
}

@media (max-width:520px) {
  #temp11 .fixedd {
    position: fixed;
    bottom: 0px;
    width: 100vw;
    background: #fff;
    transform: translate(0%, 0px);
    padding: 10px;
    z-index: 98;
    box-shadow: 0px 0px 8px 1px #00000010;
  }
}

@media (max-width:480px) {
  #temp11 .testibox {
    width: 300px;
  }
}

#temp11 .qr-logo-image {
  /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
  width: 80px;
  height: 80px;
  position: absolute;
  top: 150px;
  left: 0px;
  right: 0px;
  border-radius: 50%;
  margin: 0 auto;
  border: 4px solid #000;
  background: white;
}

#temp11 canvas {
  border-radius: 20px;
}

#temp11 .canvas-container {
  display: flex;
  justify-content: center;
}

#temp11 input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 18px;
  color: white !important;
  display: none;
  /* background-color: #fff; */
}

#temp11 .calanderIcon {
  position: absolute;
  top: 15px;
  right: 12px;
  cursor: pointer;
}


#temp11 .extmakeappointmentsubmitbtn {
  background: #065BA4;
  width: 100% !important;
  color: #fff ;
  border-radius: 20px !important;
  padding: 12px 0 !important;
  font-size: 18px !important;
  border: none !important;
}

#temp11 .testimonials-outer{
  position: relative;
}
#temp11 .testimonialname {
  font-size: 24px;
  font-family: "Yesteryear", cursive;
  font-weight: 400;
  font-style: normal;
  text-transform: capitalize;
}
#temp11 .main-temaplt a button{
  padding: 12px 20px !important;
}
#temp11 .carousel-container {
  position: absolute;
  width:100%;
  left:0;
  top:10px;
  display: flex;
  justify-content: space-between;  
}

#temp11 .owl-carousel .owl-nav button.owl-prev {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-left: 85%;
}

#temp11 .owl-carousel .owl-nav button.owl-next {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-right: 10%;
}

#temp11 .owl-carousel button.owl-dot {
  background-color: #ffc708 !important;
}

#temp11 .carousel-btn {
  background-color: #fff;
  border: 1px solid #065ba466;
  color: #000;
  border-radius: 50%;
  font-size: 14px;
  margin: 0 10px 0 0px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.portfolio-content.sec{
  margin: 0 auto;
  width:100%;  
}

.portfolio-content{ 
  width:100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto; 
  margin-top: 25px;
}
.p-left{
  width:50%;
  display: flex;
  flex-direction: column; 
  gap: 8px;
}
.left-img img{
 width:100%;  
}

.p-right{
  width:47.5%;
  background: green;
}
.p-right img{ 
width:100%;
}
#temp11 .footer{
  background: #fff;
  width:100%;
  height: 75px;
  border-radius: 6px 6px 26px 26px;
  padding: 0 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
} 
#temp11 .footer-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#temp11 .contact-form h5{
  color:#fff;
}

#temp11 .SkeletonLoader{
  display: flex;
  justify-content: center;
  align-items: center;
}

@-moz-document url-prefix() {
  .firefox-hide {
      display: none !important;
  }
}


@media(max-width: 900px){
  #temp11 .photographyappointment{
     width: 70% !important;
  }
}

@media(max-width: 650px){
  #temp11 .photographyappointment{
     width: 100% !important;
  }
}


#temp11 .reviewratingcontainer{
  display: flex;
  flex-direction: column;
  }
  
  #temp11 .reviewrating{
    display: flex;
    justify-content: center;
    align-items: baseline;
    text-align: center;
    font-family: Arial;
    font-weight: 400;
    font-size: 16px;
    color: #CACBCF;
    margin-top: -5px;
   
  }
  
  #temp11 .ratingspan{
      display: flex;
      justify-content: center;
      align-items: baseline;
      text-align: center;
    font-family: Arial;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
    /* padding-right: 5px; */
  }
  
  #temp11 .reviewssix{
    text-align: center;
    font-family: "Alata", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #505050;
  }