.requestcard_updateaction_form{
    width: 100% !important;
}

@media(min-width:300px) and (max-width: 768px){
  .requestcard_updateaction_form{
    margin-left: 0px !important;
    margin-right: 3px !important;
}
}

.userUpdate_action_form{
    width: 100% !important;
}


  .requestcardfilterbuttonWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }


  @media(max-width:600px){
    .requestcardfilterbuttonWrapper{
      /* display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: center; */
      z-index: 9999;
    }
    
  }
.requestcardfilterbuttonstyle{
  background: linear-gradient(90deg,#01c3ccdb,#2a76e8f2);
  border: none;
  border-radius: 25px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  /* margin-top: 10px; */
  margin:0px 5px;
  padding: 10px 30px;
  z-index: 99;
}

.xeceklbtn{
  background: linear-gradient(90deg,#01c3ccdb,#2a76e8f2);
  border: none;
  border-radius: 25px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  /* margin-top: 10px; */
  margin:0px 5px;
  padding: 10px 30px;
  z-index: 99;
  height: 50px;
}



@media(min-width:601px) and (max-width: 767px){
  .xeceklbtndiv {
    right: auto;
    top: 130px;
}
}
/* @media(max-width:600px){
  .requestcardfilterbuttonstyle{
  width: 100% !important;
  }
} */

/* @media(max-width:600px){
  .data-table-extensions-filter{
    width: 100% !important;
  }
} */



.filter-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.filter-popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.ModalPopupContainer{
  display: flex;
justify-content: center;
  align-items: center;
  text-align: center;
}

.ModalPopupWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  outline: none;
}

#ModalSelectfield{
  width: 100% !important;
}

.ModalSelectfield{
  width: 100%;
}



.sticky-header-container{
  /* margin-top: -67px !important; */
  margin-top: 16px;
}
@media(max-width:600px){
  .sticky-header-container{
    /* margin-top: -67px !important; */
    margin-top: 16px;
  }
}

/* @media(max-width:900px){
  .sticky-header-container{
    margin-top: 0px !important;
  }
} */

.FilterIconTextWrapper{
  visibility: hidden;
}

@media(max-width:600px){
  .FilterIconTextWrapper{
   visibility: visible;
     font-size: 18px;
    cursor: pointer;
    /* background: gray; */
     padding: 10px 0 10px 0px;
     /* border-radius: 50px; */
     color: white;
     z-index: 999;
  }
}

@media(max-width:600px){
  .FilterIcon{
   display: flex;
   color: gray;
   font-size: 12px;
   justify-content: center;
   align-items: center;
  }
}
@media(max-width:600px){
  .filtertext{
    padding: 0 5px;
  }
}
@media(max-width:370px){
  .filtertext{
    padding: 0 2px;
  }
}

@media(max-width:600px){
  #FilterIconText{
    display: none;
  }
}


.Contact_filter_fieldbutton_wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: flex-start;
  
}

.Contact_filter_field_wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* text-align: center; */
  width: 100%;
}

.Contact_filter_Inputfield{
  margin: 0 10px;
  position: relative;
  width: max-content !important;
}
.contact_filter_buttonstyle{
  border: none !important;
  background: #2196F3 !important;
  padding: 15px 30px !important;
  border-radius: 10px !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-left: 10px !important;
}

.contact_filter_buttonwrapper{
  margin-top: 20px !important;

}

.contact_filter_buttonwrapper :hover{
  background: #545EDF !important;
}

.contact_filter_toDate_calander_icon{

  position: absolute;
  top: 10px;
  right: 27px;
  font-size: 25px;
  cursor: pointer;
  color: #bebebe;
}

.dateiconmain{
  position: relative;
  /* width: 245px; */
}



.Contact_filterandsearch_wrapper{
  display: flex !important;
  justify-content: center;
}

.react-datepicker-wrapper{
  width: 100% !important;
}

.ChangesEditform{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}


@media(max-width:600px){
  .EditStatus_form{
    margin-right: 10px !important;
  }
}


.UserEditForme{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}



/* .data-table-extensions{
  margin-top: -55px;
} */



#exampleModalLabelContainer{
  margin-right: 15px !important;
}


 
.bylRxZ{
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
 
}

.ilZqYz {
  /* overflow: visible !important; */
}

.modalparacontainer{
  width: 100%;
  display: flex;
}

.modalheadingdiv{
  width: 30%;
  min-width: 145px;
  font-size: 16px;
  padding: 5px;
  font-weight: 600;
}

.modalcontentdiv{
  min-width: 65%;
  font-size: 16px;
  padding: 5px 10px;
  word-wrap: break-word; 

}

@media(max-width:550px){
  .modalheadingdiv{
    width: 30%;
    min-width: 30%;
  }
}

.chatbtngreen{
  background-color: #3d9e3e;
  padding: 12px 10px;
  color: #fff;
  text-align: center;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.listdropdownselect{
  width: 250px;
  cursor: pointer;
}
.listdropdown{
  margin: 0 0 30px 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}


.custom-dropdown {
  position: relative;
  display: inline-block;

  > .custom-dropdown-button {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
    font-size: 14px;
    border-radius: 8px;
    width: 160px;
  }

  >  .custom-dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    right: 0;
    border-radius: 8px;
    font-size: 14px;

    > .custom-dropdown-item {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: start;
      gap: 8px;
      /* >i{
    
      } */
    }
  }
}

.custom-dropdown-item:hover {
  background-color: #f1f1f1;
}



.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child){
  font-size: 18px;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child){
  font-size: 18px;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child){
  font-size: 18px;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active{
  background-color: black !important;
  color: white !important;
  border: 1px solid white !important; 
  outline: none !important;
 
}


.fc .fc-button-primary{
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #dfdfdf !important;
  outline: none !important;
  box-shadow: none !important;
}

.fc-toolbar-title{
  font-size: 18px !important;
    font-weight: 400 !important;
    font-family: 'Jost' !important;
}