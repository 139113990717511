@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
/* templat css */
#temp55 .main-temaplt{   font-family: 'Inter', sans-serif !important;}
#temp55 article.imgsechere.sec{ position: relative; padding: 0px;}
#temp55 .logo-profile {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: -30px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    z-index: 2;
}


#temp55 .coverimghere {
            clip-path: polygon(58% 0%, 103% 0, 100% 40%, 101% 93%, 58% 75%, 45% 74%, 48% 74%, 0 88%, 0% 42%, 0 0);
 }
 #temp55  label.form-label {
    display: none;
}
#temp55 .sectitle {
    font-size: 20px;
    font-weight: 600;
    color: #484848;
}

#temp55 .abouttemplate  p{
    font-size: 16px;
    color: #3a3a3a;
    font-weight: 500;
    word-break: break-word;
}

#temp55 .sec {
    margin-bottom: 14px !IMPORTANT;
    box-shadow: 0px 4px 74px 0px #00000012;
    padding: 20px;
    border-radius: 6px;
    background: #fff;
    margin: 0px 16px;
}
#temp55 h3.sectitle {
    margin-bottom: 18px;
}
#temp55 article.personalinfo.sec {
    text-align: center;
}
#temp55 h3.username {
  font-size: 28px;
  word-break: break-word;
  font-weight: normal;
  word-break: break-word;
  height: 33px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  color: #fff;
}
#temp55 p.titleuser {
    font-size: 15px;
    font-weight: 600;
    word-break: break-word;
}

#temp55 .wtemplate {
    background: #F9FAFA;
    text-align: justify;
}

#temp55 article.personalinfo.sec {
    background: none;
    box-shadow: none;
}

#temp55 article.imgsechere.sec {
    background: none !important;
    box-shadow: none !important;
}

#temp55 .theme1-gallery-inner-wrapper img {
  filter: invert(1);
  cursor: pointer;
}

#temp55 article.imgsechere.sec {
    margin: 0px;
    /* height: 170px; */
    margin-bottom: 30px !important;
}
#temp55 .day {
    width: 30%;
    font-size: 16px;
    color: #303030;
    font-weight: 600;
}
#temp55 .time {
    font-size: 16px;
    color: #303030;
    font-weight: 600;
}
#temp55 button.btn.btn-submitform {
    background: #065BA4;
    color: #fff;
    width: 100%;
}
#temp55 button.btn.btnmakeappitmnets{ background: #065BA4;
    color: #fff;
    width: 100%;
    font-size: 16px;
  padding: 12px 0;
  }

#temp55 ul.socil-link li {
    width: 60px;
    height: 60px;
    background: #065ba4;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin-bottom: 10px;
}
#temp55 ul.socil-link li a{color: #fff; justify-content: center;}

#temp55 ul.conlink{
padding: 0;
}

#temp55 ul.conlink li i {
    font-size: 18px;
    width: 40px;
    height: 40px;
    background: #F8F8F8;
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
}
#temp55 ul.conlink li span {
    font-size: 18px;
    color: #303030;
    font-weight: 600;
}



#temp55 ul.conlink li {
    margin-bottom: 15px;
}

#temp55 ul.servesbox {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

#temp55 ul.servesbox li {
    margin: 10px;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 20px;
    border-radius: 50px;
    border: 1px solid #303030;
}

#temp55 .addtocinatct {
    background: #000;
    color: #fff;
    padding: 3px 15px;
    border-radius: 18px;
    font-size: 15px;
}
#temp55 .footerleft {
    background: #000;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 19px;
    border-radius: 50%;
}

#temp55 .addtocinatct {
    background: #000;
    color: #fff;
    padding: 3px 14px;
    border-radius: 18px;
    font-size: 15px;
    line-height: 34px;
}

#temp55 ul.timelist li {
    margin-bottom: 14px;
    font-weight: 600;
}
/* comman css */

#temp55 .testibox {
    text-align: center;
}
#temp55 .testimoniyalbox.sec {
    /* background: #0165fc; */
    /* color: #fff; */
    background: #f8f8f8;
    color: #000;
  }
  #temp55 .sectitle.testimonial{
    padding: 20px;
  }
  #temp55 .testimonialimg {
    border: 5px solid #ffc708;
  }
  #temp55 .testimonialcontent {
    display: flex;
    margin: 10px 25px;
    align-items: flex-start;
  }
  
  #temp55 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
  }
  
  #temp55 .testiprofile {
    width: 170px;
    height: 170px;
  }
  
  #temp55 .testimonialname {
    font-size: 20px;
    text-transform: capitalize;
  }
  #temp55 .testimonialdescription {
    font-size: 15px;
    text-align: justify;
    line-height: 22px;
    padding-top: 5px;
  }
  #temp55 .testibox {
    text-align: center;
    width: 460px;
    @media (max-width:500px){
      width: 380px !important;
    }
    @media (max-width:400px){
      width: 330px !important;
    }
  }
  #temp55 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
  }
  #temp55 .testimonials {
    display: flex;
    width: max-content;
  }
  #temp55 .testiimg {
    max-width: 90px;
    max-height: 90px;
  }
  #temp55 .clintname {
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  #temp55 .testimonialcontent img {
    border: 3px solid #065ba4 !important;
    margin: 0 !important;
    border-radius: 50px;
  }
  #temp55 .sectitle.testimonial {
    background-color: #fff;
    color: #000;
  }
#temp55 h1, h2, h3, h4, h5, h6, p{ margin: 0px;}
#temp55 .main-temaplt ul{ list-style-type: none; padding: 0px;}
#temp55 ul {
    margin: 0px;
}
#temp55 .main-temaplt a{ text-decoration: none; color: #000; }

#temp55 .clint-img {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    margin: 12px 0px;
}
#temp55 ul.startaring {
    margin-top: 16px;
}
#temp55 img.mx-auto.d-block.img-fluid.coverimghere {
    /* width: 500px;
    height: 300px; */
}

#temp55 .wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
}
#temp55 ul.socil-link {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
#temp55 ul.timelist li {
    display: flex;
}
#temp55 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}
#temp55 ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
}
#temp55 article.fixedfooter {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    background: transparent;
    box-shadow: none;
    margin: 0 !important;
}
#temp55 .qrcodeshaere {
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
}
#temp55 .personalinfo.modal-info {
    text-align: center;
}
#temp55 .fixedd {
    position: fixed;
    bottom: 0px;
    width: inherit;
    background: #fff;
    transform: translate(0%, 0px);
    padding: 10px;
    z-index: 98;
    box-shadow: 0px 0px 8px 1px #00000010;
}
#temp55 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}


@media (max-width: 480px){
    #temp55 .fixedd{ width: 100vw;}
    #temp55 .coverimghere {
    width: 100% !IMPORTANT;
}
#temp55 ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
}

#temp55 .wtemplate { padding: 0px 15px;}

}

#temp55 .modal-content{
    width: 80%;
    border-radius: 20px;
  } 
  #temp55 .personalinfo.modal-info{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  #temp55 .personalinfo.modal-info h3{
    padding-top: 10px;
    font-size: 22px;
  }
  #temp55 .personalinfo.modal-info p {
    font-size: 16px;
  }
  #temp55 .personalinfo canvas{
    width: 260px !important;
    height: 260px !important;
  }
  #temp55 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }

  #temp55 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
  }
  #temp55 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
  }
  #temp55 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
  }
  #temp55 .carousel-btn {
    background-color: #065ba4;
    border: 1px solid #065ba466;
    color: #ffffff;
    border-radius: 50%;
    font-size: 18px;
    margin: 0 10px 0 0px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #temp55 .carousel-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 110px;
  }

  
  #temp55 .twoimages{
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  #temp55 .skeleton-blog{
    margin-bottom: 2em;
    animation: scale 4s infinite;
   }
  
   #temp55 .skeleton-footer{
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
   }

   @media (max-width:520px){
      #temp55 .fixedd {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        background: #fff;
        transform: translate(0%, 0px);
        padding: 10px;
        z-index: 98;
        box-shadow: 0px 0px 8px 1px #00000010;
      }
   }
   @media (max-width:480px){
    #temp55 .testibox {
      width: 330px;
  }
   }

#temp55 .qr-logo-image{
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width:80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
  margin: 0 auto;
  border: 4px solid #000;
  background: white;
  }
#temp55  canvas{
  border-radius: 20px;
  }
#temp55  .canvas-container{
    display: flex;
    justify-content: center;
  }

  #temp55 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}
#temp55 .calanderIcon{
    position: absolute;
    top: 15px;
    right: 12px;
    cursor: pointer;
  }


  #temp55 .extmakeappointmentsubmitbtn{
    background: #065BA4 !important;
    width: 100% !important;
    color: #fff !important;
    border-radius: 20px !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
  }


  @-moz-document url-prefix() {
    .firefox-hide {
        display: none !important;
    }
}



#tempr1 .reviewratingcontainer{

}

#temp55 .reviewrating{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial;
  font-weight: 400;
  font-size: 13px;
  color: #fff;
}

#temp55 .ratingspan{
  font-family: Arial;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    
}

#temp55 .secondspanrating{
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  color: #A6A6A6;
  padding-right: 5px;
}