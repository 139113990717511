

@media(min-width:300px) and (max-width:1260px){
    .planmarissueres{
        margin: 17px 0 !important;
    }
}

@media(min-width:300px) and (max-width:1260px){
    .planpadissueres{
        padding: 35px 15px;
    }
}

/* @media() */

/* .app-sidebar */

@media(max-width:769px){
    .app-sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        margin-top: 0px;
        width: 90%;
        overflow: auto;
        z-index: 10;
        background-color: #fdfdfd;
        -webkit-transition: left 0.3s ease, width 0.3s ease;
        transition: left 0.3s ease, width 0.3s ease;
      }
}