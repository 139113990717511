.modal-overlaynew {      
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3333;
}

.modal-content2{
  background-color: #fff;
    min-width: 95%;
    max-width: 95%;
    height: 100%;
    min-height:90vh;
    max-height: 90vh;
    margin: 0 auto;
    position: absolute;
    right: 0;
    left: 0;
    top: 2%;
    bottom: 0;
    padding: 0;
}
.modalclosebtn{
    position: absolute;
    right: -10px;
    top: -12px;
}

.selectbusinessoncondition{
  text-align: center;
  padding: 100px 0;
  font-size: 16px;
  color: #4a4a4a;;
}

.selectbusinessoncondition span{
  font-weight: 700;
  text-decoration: none;
  border: 0;
}
.modalbackbtn{
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
    cursor: pointer;
    color: #000;
}
.modalcontantinner{
    /* padding: 30px; */
    padding: 30px;
    min-height: 100%;
    /* overflow: scroll; */
    background-color: #ffffff;
}

/* Dropdown.css */
.containertop{
    /* display: flex;
    gap: 20px;
    padding: 20px;
    flex-direction: column;
    max-width: fit-content; */
    display: flex;
    gap: 20px;
    padding: 20px 20px 10px 10px;
    max-width: 100%;
    justify-content: space-between;
}

.dropdowndiv{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}
.dropdown {
    position: relative;
    /* width: 200px; */
    font-family: Arial, sans-serif;
}

.dropdown-header {
    /* padding: 10px;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center; */
    padding: 9px 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    font-size: 12px;
    color: #000;
}

.dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 10;
    max-height: 300px;
    overflow: scroll;
}

.dropdown-item {
    padding: 10px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
}

.dropdown-icon {
    transition: transform 0.3s;
}

.dropdown-icon.open {
    transform: rotate(180deg);
}

.containermiddle{  
    padding: 5px;
    min-height: 80%;
}

.containerbottom{
    padding: 5px;
    text-align: right;
    padding-right: 24px;
}
.containerbottom button{
    background: #145388;
    color: #fff;
    padding: 10px 20px;
    width: 150px;
    width: 100%;
    max-width: 150px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 20px;
}

/*-------------------------Table====================*/

/* ProductTable.css */

.table-container {   
    font-family: Arial, sans-serif;
    padding: 20px;
    color: #333;
    overflow: scroll;
    height: 100%;
    max-height: 450px;
  }
  
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .table-actions{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .search-input {
    padding: 9px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
  }
  
  .filter-button,
  .add-button {
    padding: 6px 12px;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
  }
  
  .filter-button {
    background-color: #d8b1f0;
  }
  
  .add-button {
    background-color: #6a1b9a;
  }
  
  .table {
    width: 100%;
    border-spacing: 0;
    border-top: 2px solid #d8b1f0;
  }
  
  .table-row {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .table-head {
    font-weight: bold;
    color: #666;
  }
  
  .table-cell {
    flex: 2;
    padding: 5px;
    display: flex;
    align-items: center;
  }
  
  .table-cell:first-child {
    flex: 1;
  }


  .prodname{
    width: 100%;
    min-width: 230px;
  }
  
  .product-image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .status-badge {
    padding: 4px 8px;
    border-radius: 15px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }
  
  .status-badge.active {
    background-color: #ba68c8;
  }
  
  /* .status-badge["low in stock"] {
    background-color: #ffcc80;
    color: #333;
  }
  
  .status-badge["sold out"] {
    background-color: #8e24aa;
    color: #fff;
  }
   */


   @media screen and (max-width:576px) {
        .modal-content {        
            /* min-height: 100vh; */
            max-height: 100vh;
        }
        .containertop{
            flex-direction: column;
        }
        .containermiddle {
            min-height: 75%;
        }
        .modalcontantinner {
            padding: 10px;
            min-height: 100%;
        }
   }