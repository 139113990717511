/* .tab-item.active {
    border: 3px solid #000;
    padding: 3px;
} */


#card1 .logoimg{
    left: 20px;
    top: 61px;
}
#card1 .scan {
    top: 90px;
    left: 0%;
    right: 0px;
    display: block;
    margin: auto;
}

#card1 .logoimg {
      left: 20px;
      top: 61px;
      position: static;
      margin-top: -200px;
      display: none;
  }
  #card1 .u-name {
      bottom: 146px;
      font-size: 35px;
      text-align: center;
      width: 100%;
      font-family: 'Patrick Hand', cursive;
      font-weight: 100;

  }
  #card1 .u-deg {
      bottom: 116px;
      /* left: 20px; */
      color: #642540;
      font-size: 14px;
      text-align: center;
      width: 100%;
      font-weight: bold;
      font-family: 'Patrick Hand', cursive;
  }


  #card1 p.u-weblink-back.pos-ab {
      display: none;
  }
  #card1 p.u-deg-back.pos-ab {
      display: none;
  }
  #card1 h1.u-name-back {
      display: none;
  }
  #card1 .u-weblink{ display: none;}


 /*Card 2*/

 #card2 .scan {
    top: 60px;
    left: 0%;
}

  /* .u-deg {
      bottom: 26px;
      left: 20px;
      color: #fff;
  } */
  #card2 .logoimg{
      left: 20px;
      top: 61px;
  }

  #card1 .scan {
    top: 90px;
    left: 0%;
    right: 0px;
    display: block;
    margin: auto;
}

    #card2  .logoimg {
        left: 20px;
        top: 61px;
        position: static;
        margin-top: -200px;
        display: none;
    }
    #card2 .u-name {
        bottom: 146px;
        /* left: 20px; */
        /* color: #fff; */
        font-size: 35px;
        text-align: center;
        width: 100%;
        font-family: myFirstFont;
        font-weight: 100;

    }

    #card2 .u-deg {
        bottom: 106px;
        /* left: 20px; */
        color: #131416;
        font-size: 14px;
        text-align: center;
        width: 100%;

    }



    #card2 p.u-deg-back.pos-ab {
        display: none;
    }
    #card2 h1.u-name-back.pos-ab {
        display: none;
    }
    #card2 p.u-weblink-back.pos-ab {
        width: 100%;
        bottom: 14px;
        font-size: 17px;
      display: block;
    }


    #card2 p.u-weblink.pos-ab {
        display: none;
    }


    .activeThumbnailCard{
        /* box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px; */
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        border: 3px solid #fff;
        }
        
        .slick-dots li button:before{
        font-size: 12px !important;
        }
        .slick-dots li.slick-active button:before{
        font-size: 12px !important;
        }
        
        .slick-dots li button{
        font-size: 12px !important;
        }
        .slick-dots{
        /* margin:0 0 20px 0 !important; */
        }


        .slick-list{
            padding: 10px 0 !important;
        }