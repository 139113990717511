/* .data-table-extensions{
    display: none;
  }
  .kheight{
    height: 120px;
  } */

  /* 2711 */


  .Remainingclaimableamt{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .claimbtn{
    font-size: 16px;
  }

  .claimbtnspan{
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 25px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background: #5C53E1;
    color: white;
    cursor: pointer;
  }

  .disableclaimbtnspan{
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px 25px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background: #5c53e13b;
    color: white;
    cursor:default
  }
  .disableclaimbtnspan button{
    cursor:default;
  }


  .data-table-extensions-filter{
    /* display: none !important; */
  }

  .data-table-extensions{
    display: none;
  }