@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
/* templat css */
#temp12 .main-temaplt{   font-family: 'Inter', sans-serif !important;}
#temp12 article.imgsechere.sec{ position: relative; padding: 0px;}
#temp12 .logo-profile {
    /* position: absolute; */
    left: 0px;
    right: 0px;
    /* bottom: -30px; */
    width: 110px;
    height: 110px;
    border-radius: 50%;
    z-index: 2;
    margin-top: 40px;
}


/* #temp12 .logo-profile {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -30px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  z-index: 2;
} */


#temp12 .coverimghere {
            clip-path: polygon(58% 0%, 103% 0, 100% 40%, 101% 93%, 58% 75%, 45% 74%, 48% 74%, 0 88%, 0% 42%, 0 0);
 }
 #temp12  label.form-label {
    display: none;
}
/* #temp12 .sectitle {
    font-size:  20px;
    font-weight: 600;
    color: #484848;
} */

#temp12 h3.sectitle {
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  margin: 10px 25px;
  padding: 15px 10px;
  color: #fff;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
}

#temp12 .sectitleDescription{
  color: white;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 10px;
  margin-top: -15px;
}

/* #temp12 .abouttemplate  p{
    font-size: 16px;
    color: #3a3a3a;
    font-weight: 500;
} */
#temp12 article.abouttemplate.sec {
  /* margin-top: 70px; */
  background-color: #1D1D1D;	
  margin: 10px 10px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);

}

#temp12 .sec {
    /* margin-bottom: 14px !IMPORTANT; */
    box-shadow: 0px 4px 74px 0px #00000012;
    padding: 0 20px 10px 20px !important;
    border-radius: 6px;
    background: #1D1D1D;
    margin: 15px 16px;
}
#temp12 h3.sectitle {
    /* margin-bottom: 18px; */
}
#temp12 article.personalinfo.sec {
    text-align: center;
}
#temp12 h3.username {
    font-size: 20px !important;
    font-weight: 500;
    color: #fff;
    font-family: "Red Hat Display", sans-serif;
    word-break: break-word;
}
#temp12 h3.Extusername {
  font-size: 20px !important;
  font-weight: 500;
  color: #fff;
  font-family: "Red Hat Display", sans-serif;
  text-transform: capitalize;
  height: 24px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
  
}
#temp12 p.titleuser {
    font-size: 20px;
    word-break: break-word;
    font-weight: 400;
    color: #A5A5A5;
    font-family: "Red Hat Display", sans-serif;
    padding: 1px 0;
}

#temp12 p.companeyname {
  font-size: 14px;
  font-weight: 400;
  color: #686868;
  font-family: "Red Hat Display", sans-serif;
  padding: 1px 0;
}

#temp12 .horizontalLine .Line{
background: red;
width: 100% !important;
height: 20px;
}

#temp12 p.userDesignation {
  font-size: 14px;
  font-weight: 400;
  color: #686868;
  font-family: "DM Sans", sans-serif;
}

#temp12 .horzontalLine{
  background: linear-gradient(to right, #161616 0%, #999999 50%, #161616 100%);
  height: 20px;
  width: 100px;
}

#temp12 .wtemplate {
    background: #161616;
    text-align: justify;
}

#temp12 article.personalinfo.sec {
    background: none;
    box-shadow: none;
}

#temp12 article.imgsechere.sec {
    background: none !important;
    box-shadow: none !important;
}

/* #temp12 article.imgsechere.sec {
    margin: 0px;
    margin-bottom: 30px !important;
} */


#temp12 article.imgsechere.sec {
  margin: 0px;
  /* margin-bottom: 30px !important; */
}
#temp12 .day {
    width: 30%;
    font-size: 16px;
    color: #303030;
    font-weight: 600;
}
#temp12 .time {
    font-size: 16px;
    color: #303030;
    font-weight: 600;
}
#temp12 button.btn.btn-submitform {
    background: #065BA4;
    color: #fff;
    width: 100%;
}
#temp12 button.btn.btnmakeappitmnets{ background: #065BA4;
    color: #fff;
    width: 100%;
    font-size: 16px;
  padding: 12px 0;
  }

#temp12 ul.socil-link li {
    width: 60px;
    height: 60px;
    background: #262626;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin-bottom: 10px;
}
#temp12 ul.socil-link li a{color: #fff;}

#temp12 ul.conlink{
padding: 0;
}

#temp12 ul.conlink li i {
    font-size: 18px;
    width: 40px;
    height: 40px;
    /* background: #F8F8F8; */
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
}
#temp12 ul.conlink li span {
    font-size: 20px;
    /* color: #fff; */
    font-weight: 400;
    font-family: "DM Sans";
}



#temp12 ul.conlink li {
    margin-bottom: 8px;
    text-align: center;
}

#temp12 ul.servesbox {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

#temp12 ul.servesbox li {
    margin: 10px;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 20px;
    border-radius: 50px;
    border: 1px solid #303030;
}

#temp12 .addtocinatct {
    background: #000;
    color: #fff;
    padding: 3px 15px;
    border-radius: 18px;
    font-size: 15px;
}
#temp12 .footerleft {
    background: #000;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 19px;
    border-radius: 50%;
}

#temp12 .addtocinatct {
    background: #000;
    color: #fff;
    padding: 3px 14px;
    border-radius: 18px;
    font-size: 15px;
    line-height: 34px;
}

#temp12 ul.timelist li {
    margin-bottom: 14px;
    font-weight: 600;
}
/* comman css */

#temp12 .testibox {
    text-align: center;
}
#temp12 .testimoniyalbox.sec {
    /* background: #0165fc; */
    /* color: #fff; */
    background: #f8f8f8;
    color: #000;
  }
  #temp12 .sectitle.testimonial{
    padding: 20px;
  }
  #temp12 .testimonialimg {
    border: 5px solid #ffc708;
  }
  #temp12 .testimonialcontent {
    display: flex;
    margin: 10px 25px;
    align-items: flex-start;
  }
  
  #temp12 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
  }
  
  #temp12 .testiprofile {
    width: 170px;
    height: 170px;
  }
  
  #temp12 .testimonialname {
    font-size: 20px;
    text-transform: capitalize;
  }
  #temp12 .testimonialdescription {
    font-size: 15px;
    text-align: justify;
    line-height: 22px;
    padding-top: 5px;
  }
  #temp12 .testibox {
    text-align: center;
    width: 460px;
  }
  #temp12 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
  }
  #temp12 .testimonials {
    display: flex;
    width: max-content;
  }
  #temp12 .testiimg {
    max-width: 90px;
    max-height: 90px;
  }
  #temp12 .clintname {
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  #temp12 .testimonialcontent img {
    border: 3px solid #065ba4 !important;
    margin: 0 !important;
    border-radius: 50px;
  }
  #temp12 .sectitle.testimonial {
    background-color: #fff;
    color: #000;
  }
#temp12 h1, h2, h3, h4, h5, h6, p{ margin: 0px;}
#temp12 .main-temaplt ul{ list-style-type: none; padding: 0px;}
#temp12 ul {
    margin: 0px;
}
#temp12 .main-temaplt a{ 
  text-decoration: none;
  color:#828282;
 }

#temp12 .clint-img {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    margin: 12px 0px;
}
#temp12 ul.startaring {
    margin-top: 16px;
}
#temp12 img.mx-auto.d-block.img-fluid.coverimghere {
    /* width: 500px;
    height: 300px; */
}

#temp12 .wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
    border-radius: 5px;
}
#temp12 ul.socil-link {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
#temp12 ul.timelist li {
    display: flex;
}
#temp12 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}
#temp12 ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
}
#temp12 article.fixedfooter {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    background: transparent;
    box-shadow: none;
    margin: 0 !important;
}
#temp12 .qrcodeshaere {
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
}
#temp12 .personalinfo.modal-info {
    text-align: center;
}
#temp12 .fixedd {
    position: fixed;
    bottom: 0px;
    width: inherit;
    background: #fff;
    transform: translate(0%, 0px);
    padding: 10px;
    z-index: 98;
    box-shadow: 0px 0px 8px 1px #00000010;
}
#temp12 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}


@media (max-width: 480px){
    #temp12 .fixedd{ width: 100vw;}
    #temp12 .coverimghere {
    width: 100% !IMPORTANT;
}
#temp12 ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
}

#temp12 .wtemplate { padding: 0px 15px;}

}

#temp12 .modal-content{
    width: 80%;
    border-radius: 20px;
  } 
  #temp12 .personalinfo.modal-info{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  #temp12 .personalinfo.modal-info h3{
    padding-top: 5px;
    font-size: 22px;
  }
  #temp12 .personalinfo.modal-info p {
    font-size: 16px;
  }
  #temp12 .personalinfo canvas{
    width: 260px !important;
    height: 260px !important;
  }
  #temp12 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }

  #temp12 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
  }
  #temp12 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
  }
  #temp12 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
  }
  #temp12 .carousel-btn {
    background-color: #065ba4;
    border: 1px solid #065ba466;
    color: #ffffff;
    border-radius: 50%;
    font-size: 18px;
    margin: 0 10px 0 0px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #temp12 .carousel-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 110px;
  }

  
  #temp12 .twoimages{
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  #temp12 .skeleton-blog{
    margin-bottom: 2em;
    animation: scale 4s infinite;
   }
  
   #temp12 .skeleton-footer{
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
   }

   @media (max-width:520px){
      #temp12 .fixedd {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        background: #fff;
        transform: translate(0%, 0px);
        padding: 10px;
        z-index: 98;
        box-shadow: 0px 0px 8px 1px #00000010;
      }
   }
   @media (max-width:480px){
    #temp12 .testibox {
      width: 330px;
  }
   }

#temp12 .qr-logo-image{
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width:80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
  margin: 0 auto;
  border: 4px solid #000;
  background: white;
  }
#temp12  canvas{
  border-radius: 20px;
  }
#temp12  .canvas-container{
    display: flex;
    justify-content: center;
  }

  #temp12 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}
#temp12 .calanderIcon{
    position: absolute;
    top: 15px;
    right: 12px;
    cursor: pointer;
  }


  #temp12 .extmakeappointmentsubmitbtn{
    background: #065BA4 !important;
    width: 100% !important;
    color: #fff !important;
    border-radius: 20px !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
  }



  #temp12 .ScanQrcode{
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    font-family: "DM Sans", sans-serif;
    margin: 25px 0 10px 0;
  }


  #temp12 .ExtScanQrcode{
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    font-family: "Red Hat Display", sans-serif;
    margin: 20px 20px 10px 20px;
    padding: 0px;
  
  }


  #temp12 .ReviewUser{
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    font-family: "Red Hat Display", sans-serif;
    padding-bottom: 8px;
  }

  #temp12 .googlestar{
    padding-top: 5px;
    width: 150px;
  }

  #temp12 .ExtWeburl{
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    font-family: "DM Sans", sans-serif;
    padding: 15px;
  }

  
  #temp12 .Googlewebsite{
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    font-family: "DM Sans", sans-serif;
    padding-bottom: 20px;
  }


#temp12 .HorizontalLine {
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #161616 0%, #161616 33%, #605e5e 33%, #605e5e 66%, #161616 66%, #161616 100%);
    margin: 10px 0 25px 0;
    position: relative; 
  }

  #temp12 .HorizontalLine::before,
  #temp12 .HorizontalLine::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 85px; 
      top: 0; 
  }
  
  #temp12 .HorizontalLine::before {
      left: 70px;
      background: linear-gradient(to right, #000, #605e5e); 
  }
  
  #temp12 .HorizontalLine::after {
      right: 70px;
      background: linear-gradient(to left, #000, #605e5e); 
  }
  

  #temp12 .btn-gradient-2 {
    background: linear-gradient(black, black) padding-box,
                linear-gradient(126deg, #3174F1, #E63125, #F6AD01, #3174F1, #249A41, #E63125) border-box;
    border-radius: 20px;
    border: 4px solid transparent;
    width:260px;
    height: 255px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  color: #000;
  cursor: pointer;
  }
  


  #temp12 .parent {
    /* padding-top: 100px; */
    /* width: 400px; */
    display: flex;
    justify-content: center;
    margin: 0 auto
} 


#temp12 .googlestarwrapperr{
  display: flex;
  justify-content: center;
  align-items: start;
  text-align: center;
}


#temp12 .googlestarvalue{
  color: #fff;
  font-weight: 400;
  color: #D9D9D9;
  font-family: "Red Hat Display", sans-serif;
  font-size: 15px;
  /* margin-top: 10px; */
}

#temp12 .Sharereviewpos{
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
}

#temp12 .Sharereview{
  width: 35px;
}

@-moz-document url-prefix() {
  .firefox-hide {
      display: none !important;
  }
}


.googleContainericon{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}




#temp12 article.contact.sec {
  background-color: #1D1D1D;
  margin: 30px 10px;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
}