@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&display=swap');
/* templat css */
#temp20 {  font-family: 'Instrument Sans' !important;}
#temp20 .wtemplate {

    background: #fff;
    /* min-height: 100vh; */
    max-height: max-content;
    min-height: max-content;
}

#temp20 ul.gallerylist li {
    margin: 4px 4px;
    width: 28%;
    border-radius: 18px;
}

#temp20 ul.gallerylist li img {
    border-radius: 16px;
}

#temp20 h3.sectitle {
    font-size: 22px;
    margin-bottom: 15px;
    color: #fff;
    font-weight: 600;
    text-align: start;
}

#temp20 p.para {
    font-size: 15px;
    font-weight: 500;
    color: #B5B5B5;
    text-align: start;
    word-break: break-word;
}

#temp20 .sec {
    /* background: #f1f1ee; */
    border-radius: 14px;
    /* padding: 30px 0; */
    padding: 10px 0;
    margin-bottom: 18px;
    margin: 23px 23px;
}

#temp20 .servicesName.sec {
    /* padding: 30px 30px 0; */
}

#temp20 article.abouttemplate.sec {
    text-align: center;
}

#temp20 h3.username {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    word-break: break-word;
}

#temp20 input.form-control {
    border-radius: 10px;
    background-color: #f1f1f1;
    border:1px solid #8080809c !important;
    /* width: 91% !important; */
    color: #0e0e0e;
    font-size: 18px;
    padding: 30px 60px !important;
}

.react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    border-right: 1px solid #e1e1e1;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    background: transparent;
}

#temp20 input.form-control::placeholder {
    color: #505050 !important;
}

#temp20 textarea.form-control::placeholder {
    color: #505050 !important;
}

#temp20 textarea.form-control {
    border-radius: 15px;
    background-color: #212121;
    border: 0.5px solid rgba(255, 255, 255, 0.33);

}

#temp20 select.form-select {
    border-radius: 11px;
    background-color: #212121;
    border: 0.5px solid rgba(255, 255, 255, 0.33);
    border-radius: 50px;
    color: #fff;
    padding: 15px 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add custom arrow */
    /* background-image: url(../../../../Images/d-arrow-w.png); */
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 17px;

}

#temp20 button.btn.btn-submitform {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgb(0 0 0), rgb(0 0 0)); */
    background: #1BBFA2;
    width: 100%;
    color: #fff;
    border-radius: 50px;
    padding: 15px 0;
    font-size: 18px;
}

#temp20 ul.socil-link li {
    width: 40px;
    height: 40px;
    display: flex;
    text-align: center;
    line-height: 40px;
    font-size: 25px;
    border-radius: 50px;
    /* background: #936f5e; */
    /* box-shadow: 0px 4px 10px 0px rgba(179, 156, 156, 0.25); */
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    box-shadow: 0px 0px 16px 4px #00000014;
}

#temp20 article.imgsechere.sec {
    position: relative;
    width: 100%;
    height: 280px;
    overflow: hidden;
    border-radius: 0;
}

#temp20 article.personalinfo {
    /* text-align: center; */
    text-align: start;

}

#temp20 article.qrcode-open {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp20 .logo-profile {
    position: absolute;
    top: 230px;
    left: 20px;
    right: 0px;
    z-index: 2;
    border: 1px solid #1BBFA2;
}

#temp20 article.personalinfo {
    margin-top: 0px;
    position: absolute;
    left: 160px;
    /* top: 270px; */
    top: 300px;
    /* margin-top: 60px; */

}

#temp20 button.btn.btnmakeappitmnets {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background-color: #1BBFA2;
    width: 100%;
    color: #fff;
    border-radius: 50px;
    padding: 15px 0;
    font-size: 18px;

}

#temp20 .timelist {
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
}



#temp20 ul.timelist li .day {
    /* margin-right: 28px; */
    font-size: 15px;
    font-weight: 500;
    /* width: 27%; */
    padding: 0 10px;
    color: #fff;
}

#temp20 ul.servesbox li {
    position: relative;
}

#temp20 ul.conlink li i {
    min-width: 40px;
    height: 40px;
    /* background: antiquewhite; */
    text-align: center;
    line-height: 40px;
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%); */
    color: #de2f35;
    border-radius: 5px;
    font-size: 20px;
}

#temp20 ul.conlink li span {
    padding-left: 10px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    color: #101010;
    text-align: left;
}

#temp20 ul.conlink li {
    /* margin-bottom: 19px; */
    /* display: flex; */
    /* background-color: #a3a4a5; */
}

#temp20 ul.conlink {
    padding: 0px;
}

#temp20 ul.conlink li a {
    display: flex;
    align-items: center;
    flex-direction: column !important;
}

#temp20 p.titleuser {
    color: #8f8d8d;
    margin: 0;
    word-break: break-word;
    font-size: 14px;
    /* padding-left: 70px; */
}

#temp20 article.businesshour.sec h3 {
    text-align: center;
}

#temp20 ul.servesbox li {
    position: relative;
}

#temp20 ul.servesbox li:before {
    content: "";
    width: 15px;
    height: 15px;
    /* background: #ff752a; */
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: -9px;
    top: 0px;
}

#temp20 ul.servesbox {
    display: flex;
    flex-wrap: wrap;         
    gap: 10px;    
    justify-content: center;            
}

#temp20 ul.servesbox li {
    font-size: 13px;
    color: #fff;
    text-transform: capitalize;
}

#temp20 ul.timelist li {
    position: relative;
}

#temp20 ul.timelist li:before {
    content: "\f017";
    font-family: 'FontAwesome';
    color: #6A6A6A;
    /* margin-right: 11px; */
    position: absolute;
    right: 20px;
    font-size: 24px;
}

#temp20 .time {
    font-weight: 400;
    font-size: 15px;
    font-weight: 500;
}


#temp20 .testimoniyalbox.sec {
    /* background: #f8f8f8; */
    background: transparent;
    color: #000;
}

#temp20 .testimonialimg {
    border: 5px solid #ffc708;
}

#temp20 .testimonialcontent {
    display: flex;
    margin: 10px 0px;
    align-items: flex-start;
}

#temp20 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
}

#temp20 .testiprofile {
    width: 170px;
    height: 170px;
}

#temp20 .testimonialname {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

#temp20 .testimonialdescription {
    font-size: 15px;
    text-align: justify;
    /* line-height: 22px; */
    padding-top: 5px;
    font-size: 12px;
    color: white;
    /* width: 70%; */
}

#temp20 .testibox {
    text-align: center;
    width: 480px;
}

#temp20 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
}

#temp20 .testimonials {
    display: flex;
    width: max-content;
    /* width: 200% !important; */

}

#temp20 .testiimg {
    max-width: 90px;
    max-height: 90px;
}

#temp20 .testimonialcontent img {
    /* border: 3px solid #ffc708 !important; */
    margin: 0 !important;
    border-radius: 50px;
}

#temp20 .sectitle.testimonial {
    /* color: #000; */
    color: #fff;
}

#temp20 .testibox {
    text-align: justify;
    position: relative;
}

#temp20 label.form-label {
    color: #FF9635 !important;
    font-size: 14px;
}

#temp20 .clintname {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
}


/* comman css */

#temp20 .testibox {
    text-align: center;
}



#temp20 h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
}

#temp20 .main-temaplt ul {
    list-style-type: none;
    /* display: flex; */
    /* justify-content: center; */
    padding: 10px 0;
}

#temp20 ul {
    margin: 0px;
}

#temp20 .minimarketname{
    text-decoration: none;
    color: #936f5e;
    height: 44px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-wrap: break-word;
}

#temp20 .main-temaplt a {
    text-decoration: none;
    color: #936f5e;
}

#temp20 .logo-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 !important;
}

#theme3 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}

ul.startaring {
    margin-top: 16px;
}

img.mx-auto.d-block.img-fluid.coverimghere {
    width: 100vw;
    height: 285px;
}


#temp20 .wtemplate {
    width: 520px;
    position: relative;
    padding: 10px 20px;
    @media (max-width:400px){
        padding: 10px;
    }
}



#temp20 ul.socil-link {
    display: flex;
    /* justify-content: space-around; */
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0px;
    gap: 25px;
    row-gap: 12px;
    margin: 10px 0px ;
}

#temp20 ul.socil-link-inner {
    display: flex;
    justify-content: start;
    padding: 0px;
    margin-top: 30px;
    gap: 0px;
    color: #936f5e;
}

#temp20 ul.socil-link-inner li a {
    /* color: transparent; */

}

#temp20 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}

#temp20 ul.timelist li {
    display: flex;
    margin-bottom: 14px;
    align-items: center;
    border-radius: 55px;
    border: 0.5px solid rgba(255, 255, 255, 0.33);
    background: #212121;
    backdrop-filter: blur(40px);
    padding: 20px 10px;
}

#temp20 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}

#temp20 ul.gallerylist li {
    margin: 4px 4px;
}

#temp20 article.fixedfooter {
    display: flex;
    justify-content: space-around;
    /* margin: 20px 0px 0; */
    /* margin: 30px 0px 0; */
    background: #936F5E;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
    padding: 15px 0;
    border-radius: 5px 5px 0 0;
}

@media(max-width: 380px){
    #temp20 article.fixedfooter{
        justify-content: space-between;
    }
}

#temp20 .personalinfo.modal-info {
    text-align: center;
}

#temp20 .fixedd {
    position: fixed;
    bottom: 0px;
    width: inherit;
    background: transparent;
    transform: translate(0%, 0px);
    padding: 0;
    /* box-shadow: 0px 0px 18px 5px #0000000d; */
    z-index: 98;
    margin: 0;
    margin-left: -21px !important;
}

#temp20 .addtocinatct {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px; */
    background: transparent;
    color: #fff;
    padding: 0 15px;
    border-radius: 23px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    justify-content: center;
    gap:3px;
}

#temp20 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp20 .footerleft {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0px;
    gap:4px;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 0px;
    color: #fffffffa;
    justify-content: center;
    align-items: center;
    right: 0;
    left: 0;
    margin: 0px auto;


    /* position: absolute;
    display: flex;
    top: -30px;
    left: -30px;
    width: 90px;
    height: 90px;
    background: white;
    border: 4px solid #0000009e;
    text-align: center;
    line-height: 35px;
    color: #0000009e;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    right: 0;
    left: 0;
    margin: 0px auto; */
    /* box-shadow: 0px 0px 9px 2px #000; */
}



#temp20 .qrcodeshaere {
    /* cursor: pointer;
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    border: 6px solid #fff;
    border-radius: 100px;
    padding: 38px; */

    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:40px;
    /* border: 4px solid #936f5e; */
    border-radius: 50px;
    /* padding: 36px; */
    background-color: transparent;
    color: #ffffff;
    /* box-shadow: 0px 0px 10px 4px #00000024; */
}

#temp20 .addtocinatct {
    cursor: pointer;
    justify-content: center;

    >p{
        color: #fff;
        margin-top: 0px;
    }
}

@media (max-width: 480px) {
    .fixedd {
        width: 100vw;
    }

    .coverimghere {
        width: 100% !IMPORTANT;
    }

    ul.gallerylist li {
        margin: 4px 4px;
        width: 46%;
    }

    .wtemplate {
        padding: 0px;
    }

    #temp20 .sec {
        /* background: #F8F8F8; */
        border-radius: 14px;
        /* padding: 20px; */
        margin-bottom: 18px;
        margin: 21px 9px;
    }



}

#temp20 .modal-content {
    /* width: 80%; */
    width: 92%;
    border-radius: 20px;
    bottom: 0 !important;
}
#temp20 input.form-control.clear_string.login_inputfield{
    padding: 18px 48px !important;
    margin: 5px 0;
}
#temp20 .innercreatetitle{
    padding-bottom: 15px;
    padding-top: 5px;
    text-align: center;

}
#temp20 .innerform-box {
    padding-bottom: 10px;
    position: relative;
}
#temp20 .input_icons {
    left: 20px !important;
    top: 22px !important;
}
#temp20 .heading{
    margin: 0;
}
#temp20 .inputbuttoncontainer {
    margin: 0 ;
}
#temp20 .innermodal-close {
    color: #b1b1b1;
    font-size: 14px;
    padding-top: 20px;
}
#temp20 .donthaveaccount {
    color: #535353;
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0 0;
    padding-top: 5px;
}
#temp20 .donthaveaccount span {
    color: #0861c1 !important;
    font-weight: 600;
    padding-left: 10px;
}
#temp20 .login-inner-form input.form-control.register_inputfield {
    background-color: #f6f6f6;
    border: 1px solid #8080809c !important;
    box-shadow: none !important;
    margin: 5px auto;
    padding: 13px 20px !important;
    width: 100% !important;
}
#temp20 input.form-control.register_inputfield.country_mobile.registerCountarycodestyle {
    padding: 28px 0 28px 70px !important;
}
#temp20 .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 52px;
    height: 100%;
    padding: 0 0 0 11px;
    border-radius: 3px 0 0 3px;
}
#temp20 .react-tel-input .flag-dropdown {
    background-color: initial !important;
    /* left: 20px !important; */
}
#temp20 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp20 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp20 .personalinfo.modal-info p {
    font-size: 16px;
}

#temp20 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp20 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

#temp20 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
}

#temp20 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
}

#temp20 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
}

#temp20 .carousel-btn {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background: #1BBFA2;
    color: #ffffff;
    border-radius: 50%;
    font-size: 18px;
    margin: 0 10px 0 0px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
}

#temp20 .carousel-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 110px;
}

#temp20 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp20 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp20 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp20 .twoimages {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

#temp20 .skeleton-blog {
    margin-bottom: 2em;
    animation: scale 4s infinite;
}

#temp20 .skeleton-footer {
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
}

@media (max-width:400px) {
    #temp5 .footerleft {
        width: 80px;
        height: 80px;
        border: 4px solid #24292ce0;
    }

    #temp5 .qrcodeshaere {
        font-size: 40px;
    }
}

@media (max-width:520px) {
    #temp20 .fixedd {
        right: 0;
        position: fixed;
        bottom: 0px;
        width: 100vw;
        /* background: #fcfbfb; */
        /* transform: translate(0%, 0px); */
        padding: 0;
        box-shadow: 0px 0px 18px 5px #0000000d;
        z-index: 98;
    }
}

#temp20 .qr-logo-image {
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width: 80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
    margin: 0 auto;
    background: white;
}

#temp20 canvas {
    border-radius: 20px;
}

#temp20 .canvas-container {
    display: flex;
    justify-content: center;
}

.logoTextContainer {
    /* position: absolute;  */
    /* display: flex; */
    padding: 20px 0;
    margin: 30px;

}

#temp20 .calanderIcon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

#temp20 .extmakeappointmentform{
    background-color: #fff !important;
    box-shadow:rgba(0, 0, 0, 0.15) 0px 4px 10px;
    border-radius: 20px !important;
    color: #1c1a1a !important;
}

#temp20 .extmakeappointmentsubmitbtn{
    background: #1BBFA2 !important;
    width: 100% !important;
    color: #fff !important;
    border-radius: 20px !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
  }

  #temp20 .extmakeappointmentformplaceholder input::placeholder {
    color: #a4a4a4 !important;
}

/* input[type="date"] {
    -webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../../../Images/calendarIcondays.png');
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 20px;
    color: white !important;
    position: absolute;
    top: 20px;
  }

  input[type="date"]:focus {
    outline: none; 
    border-color: blue; 
  } */

  .rbd-jfyrjvzqfh-dr{
    width: 100% !important;
  }

  .drawer{
    width: 100% !important;
    max-height: calc(80vh - 25px);
    position: absolute !important;
  }

  .blur-background {
    filter: blur(5px); 
  }

  @-moz-document url-prefix() {
    .firefox-hide {
        display: none !important;
    }
}


@media(min-width: 520px){

.ContactSupplier{
 width: 480px !important;
}
}

@media(max-width: 520px){

    .ContactSupplier{
     width: 92% !important;
    }
}

@media(max-width: 480px){

    .ContactSupplier{
     width: 91% !important;
    }
}
@media(max-width: 430px){

    .ContactSupplier{
     width: 90% !important;
    }
}
@media(max-width: 395px){

    .ContactSupplier{
     width: 89% !important;
    }
}


.slide-up {
    transition: transform 0.3s ease-in-out;
    transform: translateY(0);
}

.navbackdetail{
    left: 15px;
    top: 15px;
  
}

.DetailmidLine{
    height: 1px;
    width: 90%;
}

.DetailProductImage{
    min-height: 384px !important;
    max-height: 384px !important;
    width: 100% !important;
}

.prodimg {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 10px;
}
.orderdetail_icon{
    min-height: 160px !important;
    max-height: 160px !important;
}   

.orderdetail_valuecontainer{
    display: flex !important;
    flex-direction: column !important;
}

.Complate_order_card{
border: 2px solid #80808036 !important;
}

/* .ext_contect_buttontab{
    width: 100% !important;
} */

@media(max-width: 425px){
    .Exthome_tab_spacing{
        padding-left:0px !important;
        padding-right:0px !important;
      
    }
}

.minimarketabouttext{
    padding-bottom: 20px;
}

.enquire-now-btn{
    /* padding: 10px 25px !important; */
}
.DetailProductImage {
    min-height: 100px !important;
    width: auto !important;
}
.carousel .thumb {
    transition: border .15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px;
    height: 80px !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 10px;
    border: 1px solid;
}
.carousel .thumb img {
    vertical-align: top;
    object-fit: contain;
}
.carousel .thumb.selected, .carousel .thumb:hover {
    border: 1px solid #333;
    border-radius: 10px;
}


.minimarketheading{
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 15px;
    padding-right: 10px;
    height: 52px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-wrap: break-word;
}

.minimarketaddress{
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
    padding-right: 10px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-wrap: break-word;
}



#temp20 .paginationContainer{
display: flex;
justify-content: center;
align-items: "center";
text-align: center;
}

#temp20 .theme20CartP{
    position: relative;
    display: flex;
    justify-content: flex-end;
    /* cursor: pointer; */
    padding-bottom: 0px;
    padding-right: 5px;
    margin-block:1px;
}

#temp20 .theme20CartP span{
    position: absolute;
    top: -10px;
    right: -5px;
    background: #FF441B;
    color: #fff;
    border-radius: 50%;
    padding: 2px 7px;
    font-size: 12px;
}

#temp20 #theme20-orderDeatilshhistory.product-popup {
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    z-index: 2;
    position: fixed;
    /* top: 0; */
    left: 0;
    /* bottom: 50px !important; */
    /* transform: translateY(-111%); */
    transition: 0.5s ease-out;

    >.product-popup-inner {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        padding-top: 0;



        >article {
            width: 100%;
            max-width: 520px;
            /* background: #000; */
            background: #fff;
            margin-top: 0;
            /* padding-bottom: 40px; */
            padding-top: 35px;


            >.cross {
                /* color: #fff; */
                color: #000;
                position: relative;

                >i {
                    position: absolute;
                    /* top: 10px; */
                    top: -40px;
                    font-size: 15px;
                    right: 10px;
                    cursor: pointer;

                }

            }

            >.product-inner {
                display: grid !important;
                grid-template-columns: 1fr 1fr;
                overflow: scroll;
                height: 100vh;
                /* padding: 0 20px; */
                padding: 10px 0 250px 0;
                
            }

        }
    }
}
#temp20 .ordr-detail-page {
    
    display: flex;
    color: #ffffff;
    /* text-align: center; */
    height: 86vh;
    overflow: scroll;
    padding-top: 50px;
    flex-direction: column;
    justify-content: space-between;
}
#temp20 .ordrdtlname,
#temp20 .ordrdtlemail {
    /* color: #ffffff; */
    color: #000;
    text-align: center;
    margin-top: 0;
}
#temp20 .order-detail-optionContainer {
    padding-inline: 4rem;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
#temp20 .order-detail-options {
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #565656; */
    border: 1px solid #936F5E;
    /* background-color: #565656; */
    background-color: #936F5E;
    padding: 20px 16px;
    border-radius: 12px;
    align-items: center;
    transition: transform 0.3s ease;
    cursor: pointer;
}
#temp20 .order-detail-options-h3 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
}
#temp20 .login-inner-form{
    >div{
        display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            justify-content: center;
            padding-bottom: 20px;
            >div{
                display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;

                .square-input{
                    border: 1px solid #b0b0b0;
    width: 48px;
    height: 50px;
    border-radius: 8px;
    text-align: center;
                }
            }
    }
}
#temp20 .order-history-page {
    max-height: 95vh;
    width: 100%;
    z-index: 3;
    position: fixed;
    left: 0;
    top: 0;
    transition: 0.5s ease-out;

    /* transform: translateX(10%); */
    >.order-history-page-inner {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        padding-top: 0;
        position: absolute;

        >article {
            width: 100%;
            max-width: 520px;
            /* background: #000; */
            background: #FFFBFA;
            margin-top: 0;
            padding-bottom: 40px;
            height: 91vh;
            overflow: scroll;

            >.sectitle {
                /* color: white; */
                color: #000;
                font-size: 24px;
                margin: 34px 0 15px;
                text-align: center;
            }

            >.backArrowHistory {
                /* color: #fff; */
                color: #000;
                position: relative;

                >span {
                    position: absolute;
                    position: absolute;
                    top: -50px;
                    font-size: 15px;
                    cursor: pointer;
                    left: 30px;
                }

            }
        }

    }
}

#temp20 .order-history-page-inner .orderhistorylist ul {
    list-style-type: none;
    padding: 0px;
    padding-top: 0;
}
#temp20 .orderhistorylist li {
    /* height: 120px; */
    color: #ffffff;
    display: flex;
    gap: 20px;
    border: 1px solid #eff3f8;
    background-color: #fff;
    padding:15px 10px;
    border-radius: 8px;
    align-items: flex-end;
    transition: transform 0.3s ease;
    /* cursor: pointer; */
    width: 90%;
    margin: 0 auto 20px;
    justify-content: space-between;
    font-family: Tajawal;
}
#temp20 .orderhistoryimg {
    display: flex;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: #EFF3F8;
        align-items: center;
        overflow: hidden;

}
#temp20 .orderhistorylist li h2 {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
}
#temp20 .orderhistorylist li h3 {
    color: #555555;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0;
}
#temp20 .orderhistorylist li h5 {
    color: #B7B7B7;
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 5px ;
    margin: 4px 0;
}
#temp20 .orderhistorylist li h4 {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    font-family: Tajawal;
}
#temp20 .orderhistorylist li p {
    color: #000;
    font-size: 14px;
    cursor: pointer;
    font-family: Tajawal;
}
#temp20 .orderhistorylist h3 {
    color: #000;
    /* color: #936F5E; */
    font-size: 16px;
    font-weight: 500;
    padding: 5px 5%;
    display: flex
;
    justify-content: space-between;
}
#temp20 .shippingaddress {
    margin: 0 auto;
    width: 90%;
    padding: 15px;
    border: 1px solid #EFF3F8;
    margin-top: 30px;
}
#temp20 .shippingaddress .title-name{
color:#A1A1A1;
font-family: tajawal;
font-size: 15px;
font-weight: 400;
margin-top: 15px;
margin-bottom: 5px;
}

#temp20 .shippingaddress  .getInvoice{
width: 100%;
color:#000;
background: #EDF1F4;
font-family: tajawal;
font-size: 18px;
text-align: center;
padding: 15px 0 8px 0;
margin-top: 15px;
cursor: pointer;
}


#temp20 .shippingaddress h4 {
    color: #000;
}
#temp20 .shippingaddress p {
    color: #000;
    width: 90%;
    font-size: 16px;
    margin: 0;
    font-family: Tajawal;
    font-weight: 500;
}
#temp20 .invoicemain {
    width: 90%;
    color: #000;
    margin: 20px auto 0;
    text-align: center;
}
#temp20 .invoicemain button {
    background-color: #936F5E;
    color: #fff;
    padding: 12px 22px;
    border-radius: 5px;
    font-size: 16px;
    border: 1px solid #936F5E;
}
#temp20 .inoviceTheme20{
    position: absolute;
    top: 34px;
    right: 24px;
    cursor:pointer;
}

#temp20 .orderDetailData{
    padding:15px 0 15px;
     border:1px solid #EFF3F8;
      background-color:#fff;
      width:90%;
      margin:0 auto;
      border-radius:8px;
       margin-top:30px;

       h3{
        font-size:16px;
font-weight: 400;
font-family: Tajawal;
       }
}

.minimktheader {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    padding: 0px 0px 5px 0px;
    font-size: 20px;
    align-content: center;
}

.minimktheader div {
    display: flex;
    justify-content: flex-end; /* Aligns content inside the div to the right */
    align-items: center;
}

.minimktheader img {
    cursor: pointer; 
    width: 25px;
    height: 25px;
}

.theme20CartP {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 5px; /* Adds space between the cart icon and the number */
}

.theme20CartP img {
    width: 25px;
    height: 25px;
}

#temp20 .Useful_Links {
   padding: 0 10px;
    .ul_title{
        margin-top:10px;
        font-size:16px;
        font-weight: 800;
        color:#000

    }

    ul.servesbox {
        flex-direction: column;
        justify-content: flex-start;

        li a{
            /* padding-left: 10px; */
            color:#000;
        }
    }
    
}
#temp20 .ultitle{
    text-align: left;
    font-size:16px;
    font-weight: 800;
    margin-top: 15px;
}

#temp20 .MainInfoWrapper{
    max-height:calc(100vh - 320px);
    overflow: scroll;

    /* @media(max-width:576px){
        max-height: 60vh;
    } */
}

#temp20  #contact-Info-20  .flex.items-center  span{
color: #000;

}


#temp20 .bg-coffee.rounded  button{
color:#fff
}


@media (max-width:1024px) {
   #temp20   .MainInfoWrapper{
    /* padding-bottom: 80px !important; */
   }
    #temp20::-webkit-scrollbar {
      display: none;
    }


}  