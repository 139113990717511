@import url('https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&display=swap');

#temp53 .wtemplate {
    /* height: 2080px; */
    /* height: 100%; */
    background: #0F0F0F;
    font-family: "DM Sans", sans-serif;
    width: 520px;
}

#temp53 ul.gallerylist li {
    margin: 4px 4px;
    width: 28%;
    border-radius: 18px;
}

#temp53 .shippingaddress {
    margin: 0 auto;
    width: 90%;
    padding-top: 30px;
}

#temp53 .shippingaddress h4 {
    color: #fff;
}

#temp53 .shippingaddress p {
    color: #d4d4d4;
    width: 90%;
    font-size: 16px;
}

#temp53 .square-input {
    border: 1px solid #b0b0b0;
    width: 48px;
    height: 50px;
    border-radius: 8px;
    text-align: center;
}

#temp53 ul.gallerylist li img {
    border-radius: 16px;
}

#temp53 .cart-item {
    display: flex;
    gap: 30px;
    /* box-shadow: rgb(0 0 0 / 6%) 0px 0px 10px 0px; */
    padding: 10px;
    border-radius: 0;
    width: 90%;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    background-color: rgb(255, 255, 255);
    margin: 20px auto;
    border: 1px solid #e9e9e9;
}

#temp53 .cartitemsul {
    max-height: 250px;
    overflow-y: scroll;
    padding: 0 20px;
}

#temp53 .theme53productimgcart {
    width: 100px;
    min-width: 100px;
    height: 119px;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(min-width:992px){
        height: 90px;
    }
}

#temp53 .theme53quantity {
    display: flex;
    padding: 5px 10px;
    font-size: 20px;
    margin: 0;
    border: 1px solid rgb(225, 225, 225);
    border-radius: 24px;
    width: 93px;

    @media(max-width: 450px){
        width: 75px;
        justify-content: center;
    }
}

#temp53 .plusminus {
    padding: 0px 10px;
    font-size: 20px;
    cursor: pointer;
}

#temp53 .theme53price {
    font-weight: 400;
    font-size: 19px;
    color: rgb(141, 146, 163);
    padding: 5px 0px;
    margin: 0;
}

#temp53 .theme53name {
    font-weight: 500;
    font-size: 19px;
    color: rgb(0, 0, 0);

    @media (min-width:992px){
        max-width: 290px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

#temp53 .social-links-sec {
    z-index: 3;
    position: absolute;
    top: 30px;
    right: 20px;
    /* padding: 0 18px; */

}

#temp53 .gallery-popup {
    transition: bottom 0.5s ease-in-out;
    top: auto;
    margin-left: -30px;
    /* transform: translateX(-50% );
    left: 50%; */
    padding: 0px 20px;
    background: #000;
    width: 100%;
    height: 95vh;
    position: fixed;
    /* bottom: 0px; */
    max-width: 520px;
    padding-bottom: 40px;

    >.inner-div {
        max-height: 90% !important;
        overflow: scroll !important;
    }

    >.cross {
        color: #fff;
        position: relative;

        >i {
            position: absolute;
            top: -35px;
            font-size: 15px;
            right: 10px;
            cursor: pointer;

        }
    }

}

#temp53 .gallery.sec .inner-div,
#temp53 .gallery-wrapper .inner-div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    overflow: hidden;
    max-height: 320px;
    transition: 0.5s ease;

    >.item {
        cursor: pointer;
        max-height: 160px;
        min-height: 150px;
        min-width: 150px;
        width: 100%;
        /* max-width: 150px; */
        background: transparent;

        >img {
            width: 100%;
            height: 100%;
            object-fit: cover;

        }
    }
}



#temp53 .gallery-popup .preview-box {
    position: fixed;
    transform: translateY(100%);
    bottom: 100%;
    transition: transform 0.5s ease-out;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    background: rgb(0 0 0 / 0%);

    >img {
        border-radius: 10px;
        min-width: 300px;
        max-width: 300px;
        height: 350px;
    }
}

#temp53 h3.sectitle {
    font-family: Afacad;
    font-size: 18px;
    font-weight: 600;
    line-height: 18.67px;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 34px;
    text-transform: uppercase;
    color: #fff;
}


#temp53 .brand-deals .brand-inner {
    display: flex;
    gap: 15px;
    max-width: 100%;
    overflow-x: scroll;

    >.item {
        border: 2px solid #ffffff6e;
        border-radius: 50%;
        height: 75px;
        width: 75px;
        min-height: 75px;
        min-width: 75px;

        >img {
            width: 100%;
            height: 100%;
        }

    }
}

#temp53 .products.sec .product-inner {
    display: flex;
    overflow: scroll;
    gap: 40px 16px;
    max-width: 520px;
    padding-bottom: 50px;
}

/* #temp53 section .product-popup-inner{
    padding: 0;
} */
#temp53 .ordrdtlname,
#temp53 .ordrdtlemail {
    color: #ffffff;
    text-align: center;
    margin-top: 0;
}

#temp53 .ordr-detail-page {
    color: #ffffff;
    /* text-align: center; */
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    height: 100vh;
    overflow: scroll;
    padding-top: 50px;
}

#temp53 .order-detail-options {
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    border: 1px solid #565656;
    background-color: #565656;
    padding: 12px 16px;
    border-radius: 999px;
    align-items: center;
    transition: transform 0.3s ease;
    cursor: pointer;
}

#temp53 .order-detail-options-h3 {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
}

#temp53 .order-detail-optionContainer {
    padding-inline: 4rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#temp53 .option-arrow i {
    display: inline-block;
    /* Ensure it can rotate */
    transition: transform 0.3s ease;
    /* Smooth transition */
}

#temp53 .option-arrow .rotateRightArrow {
    transform: rotate(90deg);
}

/* order history page */
#temp53 .order-history-page {
    max-height: 95vh;
    width: 100%;
    z-index: 3;
    position: fixed;
    left: 0;
    top: 0;
    transition: 0.5s ease-out;

    /* transform: translateX(10%); */
    >.order-history-page-inner {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        padding-top: 0;
        position: absolute;

        >article {
            width: 100%;
            max-width: 520px;
            background: #000;
            margin-top: 0;
            padding-bottom: 40px;
            height: 89vh;
            overflow: scroll;

            >.sectitle {
                color: white;
                font-size: 24px;
            }

            >.backArrowHistory {
                color: #fff;
                position: relative;

                >span {
                    position: absolute;
                    position: absolute;
                    top: -50px;
                    font-size: 15px;
                    cursor: pointer;
                    left: 30px;
                }

            }
        }

    }
}

#temp53 .product-popup {
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    z-index: 2;
    position: fixed;
    /* top: 0; */
    left: 0;
    /* bottom: 50px !important; */
    /* transform: translateY(-111%); */
    transition: 0.5s ease-out;

    >.product-popup-inner {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        padding-top: 0;



        >article {
            width: 100%;
            max-width: 520px;
            background: #000;
            margin-top: 0;
            /* padding-bottom: 40px; */
            padding-top: 35px;


            >.cross {
                color: #fff;
                position: relative;

                >i {
                    position: absolute;
                    /* top: 10px; */
                    top: -40px;
                    font-size: 15px;
                    right: 10px;
                    cursor: pointer;

                }

            }

            >.product-inner {
                display: grid !important;
                grid-template-columns: 1fr 1fr;
                overflow: scroll;
                height: 100vh;
                /* padding: 0 20px; */
                padding: 10px 0 250px 0;
                
            }

        }
    }
}


/* #temp53 #product-closing{
    position: 'fixed';
    left: 0;

    max-height: 95vh;
    bottom: '-100%' !important; 
    transition: " .5s ease-out";
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    width: '100%',
}
#temp53 #product-open{
    bottom: '-100%'; 
    transition: ".5s ease-out";
} */

#temp53 .products.sec .product-inner .item {
    min-width: 45%;
    text-align: center;

    >.image {
        height: 140px;
        width: 100%;
        background: transparent;

        >img {
            background: #1a1a1a;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    >h6 {
        font-family: Afacad;
        font-size: 16px;
        font-weight: 500;
        line-height: 18.67px;
        text-align: center;
        color: #fff;
        margin: 10px 0;
        height: 40px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-wrap: break-word;
    }

    >p {
        font-family: Afacad;
        font-size: 20px;
        font-weight: 600;
        line-height: 18.75px;
        text-align: center;
        color: #fff;
        margin: 5px 0 10px 0;
    }

    >button {
        background: #fff;
        border: none;
        outline: none;
        font-family: Afacad;
        font-size: 22px;
        font-weight: 700;
        line-height: 15.95px;
        text-align: center;
        width: 150px;

        
    }
    >button.stat{
        padding: 10px 0px;
    }
}


#temp53 .servesbox {
    padding: 0;
    margin: 0;
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;*/
    list-style-type: none;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;

}

#temp53 .addeditem {
    color: #ffffff;
    background-color: #389f49;
    padding: 12px 0;
}

#temp53 .orderhistorylist li {
    color: #ffffff;
    display: flex;
    gap: 20px;
    border: 1px solid #353434;
    background-color: #181818;
    padding: 10px;
    border-radius: 8px;
    align-items: flex-end;
    transition: transform 0.3s ease;
    /* cursor: pointer; */
    width: 90%;
    margin: 0 auto 20px;
    justify-content: space-between;
}

#temp53 .orderhistorylist li p {
    color: #aaaaaa;
    font-size: 12px;
    cursor: pointer;
}

#temp53 .orderhistorylist li h3 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    padding: 5px 0;
}

#temp53 .orderhistorylist h3 {
    color: #a5a2a2;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 5%;
    display: flex;
    justify-content: space-between;
}

#temp53 .orderhistorylist li h4 {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
}

#temp53 .invoicemain {
    width: 90%;
    color: white;
    margin: 20px auto 0;
    text-align: center;
}

#temp53 .invoicemain button {
    background-color: #000000;
    color: #a7a7a7;
    padding: 12px 22px;
    border-radius: 5px;
    font-size: 16px;
    border: 1px solid #838383;
}

#temp53 .invoicemain button:hover {
    background-color: #fff;
    color: #000;
    padding: 12px 22px;
    border-radius: 5px;
    font-size: 16px;
    border: 1px solid #ffffff;
}

#temp53 .orderhistorylist li h2 {
    color: #13b62e;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

#temp53 .orderhistoryimg {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    background-color: #fff;
}

#temp53 .addtocartitem {
    color: #111111;
    background-color: #ffffff;
    padding: 12px 0;
}

#temp53 p.para {
    font-family: Afacad;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;

    color: #A4A4A4;
    text-align: center;
    word-break: break-word;
}

#temp53 .sec {
    font-family: "DM Sans", sans-serif;
    /* border-radius: 8px;    
    padding: 16px 12px; */
    margin-bottom: 18px;
    margin: 23px 18px;
    /* background: linear-gradient(88.9deg, #0B1A39 0.84%, #0F0F0F 98.97%); */
}

#temp53 .reviewrating {
    /* padding-left: 18px; */
    text-align: center;
    color: #6D6E71;
    font-size: 12px;
}

#temp53 .checkout53 {
    background-color: #101010;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    margin:0 24px;
    padding: 10px 15px;
    border-radius: 0;
    width: calc(100% - 48px);
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
}

#temp53 .continue-popup {
    position: fixed;
    bottom: 100px;
    width: 100%;
    max-width: 520px;
    padding: 0 30px;
    margin: 0 auto;
    left: 0;
    right: 0;
}

#temp53 .continue-popupbutton {
    background: #ed1b24;
    box-shadow: 0 8px 24px #0c0c0cf2;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    margin: 20px 0px;
    font-size: 24px;
    padding: 5px 0;
    border-radius: 0;
    width: 100%;
    border: 1px solid #ffffff4f;
    color: white;
    flex-direction: column;
    align-items: center;
}

#temp53 .continue-popupbutton span {
    font-size: 14px;
}

#temp53 .cartdrawer {
    top: 50%;
    transform: translateY(-50%) !important;
    max-width: 520px;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    background: transparent;
    bottom: auto;
}

#temp53 .cartdrawer__content {
    padding: 0 20px;
    max-height: calc(85vh - 25px);
}

#temp53 .theme53heading {
    font-weight: 600;
    font-size: 24px;
    color: #101010;
    text-align: center;
}

#temp53 .cartdrawer__handle-wrapper {
    display: flex;
    justify-content: center;
    padding: 0px 0;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
}

#temp53 .cart-summary {
    background-color: #f9f9f9;
    max-height: 600px;
    min-height: 440px;
    overflow-y: scroll;
    padding: 20px 0;
    border-radius: 15px;
    position: relative;
}

#temp53 .closered {
    position: absolute;
    top: -10px;
    right: -10px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
    transform: rotate(180deg);
    font-weight: 700;
    background-color: #afafaf;
    width: 25px;
    height: 25px;
    border-radius: 50px;
}

#temp53 .mainsection {
    font-family: "DM Sans", sans-serif;
    border-radius: 30px;
    color: #000000;
    z-index: 2;
    position: relative;
    max-width: 520px;
    width: 94%;
    margin: -25px auto 12px;
    padding: 20px 0px 100px 0px;

    background: linear-gradient(180deg, #1E1E1E 0%, #000000 100%);

    /* @media screen and (min-width:520px) {
    width: 405px;
    
} */



    /* @media screen and (max-width:410px) {
    width: 352px;
    
}
@media screen and (min-width:420px) {
    width:407px;
    
} */
}




/* #temp53 .servicesName.sec {
    padding: 30px 30px 0;
} */

#temp53 article.abouttemplate.sec {
    text-align: center;
    button{
        margin-top: 20px;
        background: #fff;
        border: none;
        outline: none;
        font-family: Afacad;
        font-size: 22px;
        font-weight: 700;
        line-height: 15.95px;
        text-align: center;
        padding: 10px 0px;
        width: 150px;
    }
}

#temp53 .chevron {
    text-align: center;
}

#temp53 .carouselmain {
    position: relative;
}



/* #temp53 .theme20-gallery-inner-wrapper{
    position: absolute;

    right: 20px;
   
   margin-top: -35px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    >div{
        cursor: pointer;
    }
   
} */

#temp53 h3.username {
    margin-bottom: 0;
    font-family: Afacad;
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: #fff;

    /* margin-top: 15px; */
    text-align: center;
    padding: 0px 18px;
    word-break: break-word;
    text-transform: uppercase;
}

#temp53 .imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(184.37deg, rgba(244, 244, 244, 0) 34.97%, #000000 100.05%);

}


#temp53 input.form-control {
    border-radius: 0px;
    box-shadow: none !important;
    /* border-bottom: 2px solid #a7a7a7ad; */

    background-color: #F6F6F6;
    width: 100% !important;
    padding: 16px 20px;
    font-size: 18px;
    border: 1px solid #8080809c !important;
    border-radius: 0;
    margin: 5px auto;
}

#temp53 .react-tel-input .selected-flag .flag {
    left: 0;
}

#temp53 .react-tel-input .flag-dropdown {
    left: 20px !important;
    background-color: transparent !important;
}

#temp53 .react-tel-input .selected-flag {
    width: 42px;
}

#temp53 .react-tel-input .form-control {
    padding: 28px 0px 28px 70px !important;
}





#temp53 textarea.form-control {
    resize: none;
    color: #181818;
    border-radius: 0px;
    /* padding-left: 12px; */
    padding: 16px 10px;
    /* background: #212121; */
    font-size: 14px;
    border-bottom: 2px solid #a7a7a7ad;
    background-color: #F6F6F6;
    font-size: 18px;

}

#temp53 select.form-select {
    border-radius: 0;
    background-color: transparent;
    border: 0.5px solid #6C6C6C;
    color: #999999;
    padding: 15px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add custom arrow */
    background-image: url('../../../../Images/d-arrow-w.-new.png');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 17px;
    font-size: 12px;
}

#temp53 select option {
    background: linear-gradient(88.9deg, #0B1A39 0.84%, #0F0F0F 98.97%);
    color: #000;

}

#temp53 button.btn.btn-submitform {
    background: #fff;
    width: 100%;
    color: #000 !important;
    border-radius: 0px;
    padding: 15px 0px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-family: "Afacad";
}


#temp53 ul.socil-link li {
    border-radius: 100px;
    background: #000;
    /* box-shadow: 0px 4px 10px 0px #0000000D;  */
    width: 30px;
    height: 30px;
    display: flex;
    text-align: center;
    font-size: 17px;

    /* background: #FFFCFC; */
    /* box-shadow: 0px 4px 10px 0px rgba(179, 156, 156, 0.25); */
    align-items: flex-end;
    justify-content: center;
}

#temp53 .followers {
    width: 100%;
    z-index: 3;
    position: absolute;
    bottom: 40px;
    display: flex;
    gap: 20px;
    justify-content: center;

    >div {
        width: 50%;
    }

    >.middle {
        margin-top: 0;
        color: #fff;
        font-size: 26px;
    }
}

#temp53 .followers .item {
    display: flex;
    gap: 10px;
    color: #fff;
    align-items: center;
    justify-content: center;
}

/* #temp53 .followers .item:first-child {
    justify-content: flex-end;
} */

#temp53 article.imgsechere.sec {

    position: relative;
    width: 100%;
    height: 540px;
    z-index: 1;
    border-radius: 0;
    /* clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 65%); */
}

#temp53 .logoTextContainer {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    position: absolute;
    padding: 0 20px;
    top: 60px;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

#temp53 .coverimghere {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* filter: grayscale(1); */
}

#temp53 article.personalinfo {
    margin-top: 0px;
    display: flex;
    flex-direction: column;

    /* align-items: center; */
}

#temp53 article.qrcode-open {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp53 .logo-profile {
    padding: 0 10px 6px 10px;
    position: relative;
    border-bottom: 1px solid #Fff;
    width: 98px;
    padding-bottom: 15px;
    height: auto;
    border-radius: 0;
    margin: 0 0 6px 0 !important;
}



#temp53 button.btn.btnmakeappitmnets {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background-color: #D58F2D;
    width: 100%;
    color: #fff;
    border-radius: 0;
    padding: 15px 24px;
    font-size: 12px;
    border: 1px solid #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

#temp53 .timelist {
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
}



#temp53 ul.timelist li .day {
    /* margin-right: 28px; */
    font-size: 15px;
    font-weight: 500;
    /* width: 27%; */
    padding: 0 10px;
    /* color: #fff; */
}

#temp53 ul.servesbox li {
    position: relative;
}

#temp53 ul.conlink li i {
    min-width: 30px;
    font-size: 16px;
    color: #fff;
    border-radius: 0;
    margin-bottom: 20px;
}

#temp53 ul.conlink li span {

    font-family: "Josefin Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    text-align: center;

    color: #fff;

    word-break: break-word;
}

/* #temp53 ul.conlink li {
    margin-bottom: 19px;
    display: flex;
} */

#temp53 ul.conlink {
    padding: 0px;
}

#temp53 ul.conlink li a {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: #000000;
}


#temp53 p.titleuser {
    color: #A4A4A4;
    margin: 0;
    word-break: break-word;
    font-family: Afacad;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    display: inline-block;
    padding-left: 18px;
    /* padding-left: 70px; */
}

#temp53 article.businesshour.sec h3 {
    text-align: center;
}


#temp53 .businessHours p {
    font-size: 15px;
    font-family: "DM Sans";
    font-weight: 400;


}

#temp53 .businessHours h3 {
    position: relative;

    .rightcorner {
        position: absolute;
        right: 0;
        font-size: 15px;
        cursor: pointer;

    }

}

#temp53 .businessHours .bgText {
    font-size: 20px;



}


#temp53 ul.servesbox li {
    position: relative;
}

#temp53 ul.servesbox li:before {
    content: "";
    width: 15px;
    height: 15px;
    /* background: #ff752a; */
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: -9px;
    top: 0px;
}

#temp53 ul.servesbox li {
    font-family: "DM Sans", sans-serif;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
    border-radius: 90px;
    background-color: #000000;
    padding: 8px 12px;
    /* box-shadow: 2px 2px 0px 0px #D58F2D; */
}

#temp53 ul.timelist li {
    position: relative;
}

#temp53 ul.timelist li:before {
    content: "\f017";
    font-family: 'FontAwesome';
    color: #6A6A6A;
    /* margin-right: 11px; */
    position: absolute;
    right: 20px;
    font-size: 24px;
}

#temp53 .time {
    font-weight: 400;
    font-size: 15px;
    font-weight: 500;
}


/* #temp53 .testimoniyalbox.sec {   
    color: #fff;
    background: linear-gradient(88.9deg, #0B1A39 0.84%, #0F0F0F 98.97%);
    position: relative;
} */
/* #temp53 .testimoniyalbox.sec .quoteup {
    position: absolute;
    left:8px;
    top:10px;
    width:40px;
} */

#temp53 .testimonialcontent img {
    /* border: 3px solid #ffc708 !important; */
    /* margin: 0 !important; */
    height: 100%;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50px;
    filter: grayscale(1);
}

/* #temp53 .sectitle.testimonial {
    color: #ffffff;
  } */

#temp53 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
    /* background: linear-gradient(270deg, rgba(229, 49, 110, 0) 0%, rgba(229, 49, 110, 0.36) 23.32%, rgba(229, 49, 110, 0.36) 79.61%, rgba(229, 49, 110, 0) 101.41%);
    */
    margin: 0 -10px;
    padding: 0 10px;
}

#temp53 .testimonials {
    display: flex;
    width: max-content;
}

#temp53 .testimonialname {
    font-size: 20px;
}

#temp53 .testimonialdescription {
    font-size: 15px;
    text-align: left;
    line-height: 22px;
    padding-top: 5px;
    word-break: break-word;
}

#temp53 .testimonialimg {
    border: 5px solid #ffc708;
}

#temp53 .testimonialcontent {
    display: flex;
    /* margin: 10px 0; */
    align-items: flex-start;
}

#temp53 .testimonialcontent>:first-child {
    width: 25%;
}

#temp53 .testimonialcontent>:last-child {
    width: 75%;
}


#temp53 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
}


/* #temp53 .testimoniyalbox.sec {
    background-color: #2f3438;
    margin: 5px 0px;
    border-radius: 10px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
  } */

#temp53 .testimonials-container .slick-list {
    padding-top: 0 !important;
}

/* #temp53 .testimoniyalbox .testimonial-inner {
    border: 1px solid #562435;
    padding: 15px 15px;
    background: linear-gradient(0deg, #201619, #201619),
      linear-gradient(0deg, #562435, #562435);
    border-radius: 5px;
  
  
  } */





/* 

#temp53 .testimoniyalbox.sec .quotedown {
    position: absolute;
    right:8px;
    bottom:10px;
    width:40px;
}
#temp53 .testimonialimg {
    border: 5px solid #ffc708;
}

#temp53 .testimonialcontent {
    display: flex;
    margin: 6px 0 0 0;
    align-items: flex-start;
}

#temp53 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
} */

#temp53 .testiprofile {
    width: 170px;
    height: 170px;
}

/* 
#temp53 .testimonialname {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    padding-top: 30px;
    display: block;
    width: 100%;
    text-align: center;
}

#temp53 .testimonialdescription {      
    line-height: 22px;
    padding-top: 0;
    font-size: 14px;
    color: white;
    text-align: center;
    width: 70%;
} */

#temp53 .testibox {
    text-align: center;
    width: 350px;
    margin: 0 auto;
    /* }

#temp53 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
}

#temp53 .testimonials {
    display: flex;
    width: max-content;
    width: 200% !important; */

}


#temp53 .testiimg {
    height: 100px;
    padding: 10px 0px 10px 10px;
}

/* #temp53 .mainbg {height: 100%;
background-color: #000000;
} */


#temp53 .rating {
    color: #FFA538;
}

/* 
#temp53 .testimonial-rating {
    width:100%;
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
}
#temp53 .testimonialcontent img {
    border: 3px solid #ffc708 !important;
    margin: 0 !important;
    border-radius: 50px;
}

#temp53 .sectitle.testimonial {
    color: #000;
    color: #A0A0A0;
} */

#temp53 .testibox {
    text-align: justify;
    position: relative;
}

#temp53 label.form-label {
    color: #F6F6F6 !important;
    font-size: 12px;
    font-weight: 300;
}

#temp53 .clintname {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px
}


/* comman css */

#temp53 .testibox {
    background: #FFFFFF4D;
    text-align: center;
}



#temp53 h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    color: #000;
}

#temp53 .main-temaplt ul {
    list-style-type: none;
    padding: 0px;
    padding-top: 0;
    /* max-height: 240px; */
    /* overflow-y: overlay; */
}

#temp53 ul {
    margin: 0px;
}

#temp53 .donthaveaccount {
    margin: 0;
    font-size: 14px;
    color: #535353;
    padding-top: 5px;
    font-weight: 500;
    margin-top: 5px;
}

#temp53 .innerform-check-input {
    width: 18px !important;
    height: 18px !important;
    margin-top: 1px;
    position: absolute;
    border: 1px solid #dbdbdb;
    border-radius: 0;
    background-color: #fff;
    margin-left: -18px;
}

#temp53 .innerform-check-input:checked {
    background-color: #053f72 !important;
    border-color: #053f72 !important;
}

#temp53 .innermodal-close {
    padding-top: 20px;
    font-size: 14px;
    color: #b1b1b1;
}

#temp53 .donthaveaccount span {
    color: #0861c1 !important;
    font-weight: 600;
    padding-left: 10px;
}

#temp53 .input_icons {
    left: 20px !important;
    top: 20px !important;
}

#temp53 .innerlogin_button {
    width: 100%;
}

#temp53 .innerlogin {
    max-width: 480px;
    height: auto !important;
    min-height: auto !important;
    max-height: auto !important;
    bottom: auto !important;
}

#temp53 .heading {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-size: 15px;
}

#temp53 .inputbuttoncontainer {
    margin: 0 !important;
}

#temp53 .innerform-box {
    position: relative;
    padding-bottom: 10px;
}

#temp53 .innercreatetitle {
    text-align: center;
    /* padding-bottom: 30px; */
    /* padding-top: 20px; */
    padding-bottom: 15px;
    padding-top: 5px;
}

#temp53 .main-temaplt a {
    text-decoration: none;
    color: #fff;
}


#theme3 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}

ul.startaring {
    margin-top: 16px;
}

img.mx-auto.d-block.img-fluid.coverimghere {
    width: 100vw;
    height: 285px;
}


.wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
}

#temp53 ul.socil-link {
    ROW-GAP: 15PX;
    display: flex;
    /* justify-content: space-around; */
    /* justify-content: start; */
    flex-wrap: wrap;
    padding: 0;
    margin-top: 0;
    column-gap: 25px;
    justify-content: center;
}

#temp53 ul.socil-link-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 11px;
    margin-top: 0px;
    column-gap: 11px;
    color: white;
}

/* #temp53 ul.socil-link-inner li a {
    color: transparent;

} */

#temp53 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}

#temp53 ul.timelist li {
    color: #000000;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    border-radius: 55px;
    /* border: 0.5px solid rgba(255, 255, 255, 0.33); */
    background: #ffffff4D;
    /* -webkit-backdrop-filter: blur(40px); */
    /* backdrop-filter: blur(40px); */
    padding: 16px 10px;
}

#temp54 .gallery.sec.sec-common {
    overflow: hidden;
}

#temp53 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}

#temp53 ul.gallerylist li {
    margin: 4px 4px;
}

#temp53 article.fixedfooter {
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    /* padding: 0 20px; */
    position: relative;
    align-items: center;
    gap: 170px;
    /*
    align-items: center;
    background: transparent; */
    margin: 0px;
}

@media(max-width: 380px) {
    #temp53 article.fixedfooter {
        justify-content: space-between;
    }
}

#temp53 .personalinfo.modal-info {
    text-align: center;
}

#temp53 .fixedd {
    height: 80px;
    /* border-radius: 30px; */
    /* margin-right: 19px; */
    /* margin-left: 105px; */
    position: sticky;
    bottom: 0px;
    width: 100%;
    max-width: 520px;
    /* transform: translate(0%, 0px); */
    /* padding: 20px 15px; */
    /* box-shadow: 0px 0px 18px 5px #0000000d; */
    z-index: 98;
    background: #181818;

    /* padding: 5px;
    padding-left: 30px; */
    @media screen and (max-width:415px) {
        /* max-width: 325px; */
        width: 100%;
    }

    @media screen and (max-width:400px) {
        width: 100vw;
        right: 0;
    }
}

#temp53 .fixed-ext {
    position: fixed;
    bottom: 0;
}

#temp53 .addtocinatct {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px; */
    background: transparent;
    color: #fff;
    padding-top: 4px;
    border-radius: 23px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;

    >p {
        margin-top: 3px;
        color: #fff;
        font-family: Work Sans;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;

    }
}

#temp53 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp53 .footerleft {

    display: flex;

    width: 50px;
    height: 50px;
    padding-top: 15px;

    text-align: center;
    line-height: 35px;
    color: #fff;

    justify-content: center;
    align-items: center;
    border-top: 3px solid #FFf;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}



#temp53 .qrcodeshaere {
    cursor: pointer;
    width: 100%;
    font-size: 35px;

    >p {
        margin-top: 3px;
        color: #fff;
        font-family: Work Sans;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
    }
}

#temp53 .addtocinatct {
    cursor: pointer;
}

@media (max-width: 480px) {
    .fixedd {
        width: 100vw;
    }

    #temp53 .order-detail-optionContainer {
        padding-inline: 2rem;
    }

    .coverimghere {
        width: 100% !IMPORTANT;
    }

    ul.gallerylist li {
        margin: 4px 4px;
        width: 46%;
    }

    .wtemplate {
        padding: 0px;
    }

    #temp53 .sec {
        /* background: #F8F8F8; */
        /* background: #F8F8F8; */
        border-radius: 8px;
        /* padding: 16px 12px; */
        margin-bottom: 18px;
        margin: 21px 0px;
        /* background: linear-gradient(88.9deg, #0B1A39 0.84%, #0F0F0F 98.97%); */
    }
}

#temp53 .modal-content {
    /* width: 80%; */
    width: 92%;
    border-radius: 20px;
    position: fixed;
    bottom: 50vh;
}

#temp53 .modal-close {
    color: #000;
}

#temp53 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp53 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp53 .personalinfo.modal-info p {
    font-size: 16px;
}

#temp53 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp53 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

#temp53 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
}

#temp53 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
}

#temp53 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
}

#temp53 .carousel-btn {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background: #D58F2D;
    color: #ffffff;
    /* border-radius: 50%; */
    font-size: 16px;
    margin: 0 10px 15px 0px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    /* display: none; */
}

#temp53 .carousel-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    justify-content: center;
}

#temp53 .carousel-containerExternal {
    /* margin: 0 0 10px 0; */
    padding-bottom: 20px;
}


#temp53 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp53 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp53 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp53 .twoimages {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    /* flex-wrap: wrap; */
    max-height: 135px;
    overflow: hidden;
    filter: grayscale(0.2);
}

#temp53 .skeleton-blog {
    margin-bottom: 2em;
    animation: scale 4s infinite;
}

#temp53 .skeleton-footer {
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
}

@media (max-width:400px) {
    /* #temp53 .footerleft {
        width: 80px;
        height: 80px;
        border: 4px solid #24292ce0;
    } */

    /* #temp53 .qrcodeshaere {
        font-size: 40px;
    } */
}

@media (max-width:520px) {
    /* #temp53 .fixedd {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        background: #fcfbfb;
        transform: translate(0%, 0px);
        padding: 0;
        box-shadow: 0px 0px 18px 5px #0000000d;
        z-index: 999999;
    } */
}

#temp53 .qr-logo-image {
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width: 80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
    margin: 0 auto;
}

#temp53 canvas {
    border-radius: 20px;
}

#temp53 .canvas-container {
    display: flex;
    justify-content: center;
}

#temp53 .calanderIcon {
    position: absolute;
    top: 18px;
    right: 15px;
    cursor: pointer;
}

#temp53 .extmakeappointmentform {
    background-color: #fff !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
    border-radius: 20px !important;
    color: #1c1a1a !important;
}

#temp53 .extmakeappointmentsubmitbtn {
    background: #1BBFA2 !important;
    width: 100% !important;
    color: #fff !important;
    border-radius: 20px !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
}

#temp53 .extmakeappointmentformplaceholder input::placeholder {
    color: #999999 !important;
}

#temp53 .contact-form input::placeholder,
#temp53 .contact-form textarea::placeholder {
    color: #7e7d7d !important;
}

#temp53 .makeappitmneets .contact-form h5 {
    color: #ffff;
    font-size: 12px;
    display: none;
}

#temp53 .makeappitmneets label {
    display: block;
    color: #F6F6F6;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 8px;

}

#temp53 .contactformLableName {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 5px;


}

#temp53 .product-popup.active {
    transform: translateY(0);
    /* Slide into view */
}

#temp53 .product-popup.inactive {
    transform: translateY(100%);
    /* Slide out of view */
}


/* input[type="date"] {
    -webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../../../Images/calendarIcondays.png');
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 20px;
    color: white !important;
    position: absolute;
    top: 20px;
  }

  input[type="date"]:focus {
    outline: none; 
    border-color: blue; 
  } */
@media screen and (max-width:414px) {}

@media screen and (max-width:400px) {}


#temp53 .emptycartsummaryUl {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 40px;
}

#temp53 .emptycartsummary {
    color: #262626;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: 26px;
    font-weight: 500;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp53 .emptycartsummary span {
    color: rgb(153, 153, 153);
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: 14px;
    padding: 10px 20px;
}

#temp53 .emptycartsummary img {
    width: 160px;
    height: 130px;
}





/* Hire Me Button CSS  */

#temp53 .hire-form{
    height: 95vh;
    max-height: 100vh;
    width: 100%;
    z-index: 2;
    position: fixed;
   overflow: scroll;
    left: 0;
    transition: 0.5s ease-out;
    display: flex;
    align-items: flex-start;
    background: transparent;
    justify-content: center;


}

#temp53 .hire-form  .contactform.sec{
background: linear-gradient(180deg, #1E1E1E 0%, #000000 100%);
max-width:520px;
width: 100%;
padding: 20px;
height: 100%;
margin: 0;

.cross{
    position: relative;
    color :#fff;
    margin-bottom: 40px;
    i{
        position: absolute;
                    top: -40px;
                    font-size: 15px;
                    right: 10px;
                    cursor: pointer;
                
    }
}
}


#temp53 .cartprice_container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: center;

    @media (max-width : 450px){
        text-align: left;
    }
}



#temp53 .subtotal_cartcontainer{
    padding-bottom: 15px;
    padding-inline: 24px;
}
#temp53 .subtotal_cartcontainer p{
    color: #8d92a3;
    display: flex;
    /* padding: 2px 8px ; */
    /* padding: 5px 0; */
    justify-content: space-between;
    align-items: center;
    /* background-color: rgb(255,255,255); */
    /* border: 1px solid #e9e9e9; */
}

#temp53 .subtotal_cartcontainer .applyCodeBtn{
    background-color: #379141;
    padding: 15px 35px;
    color: #fff;
    position: absolute;
    right: 0;
}
#temp53 .subtotal_cartcontainer .applyCodeInput{
    padding: 15px 15px;
    border: 1px solid #e9e9e9;
    /* border-radius: 50px; */
    color: #000;
    width: 100%;
    text-transform: uppercase;
}

#temp53 .cart-summary{
    max-height: 635px;
}
#temp53 .subtotal_heading{
    width: 200px !important;
}

@media (max-width: 450px) {

}