.jSgYCb {
    width: 130px !important;
    overflow: unset !important;
}

/* .data-table-extensions{
    margin-top: 50px !important;
} */

.tlcounts{
    text-align: center;
    font-size: 20px;
}

.tile.bbox.usr h3 {
    margin: 8px 0px;
    font-weight: 400;
    color: #9f9f9f;
    font-size: 18px;
    text-align: center;
}

.sndall{
    padding: 10px 30px !important;
}

.hNhnih {
    padding-right: 0px !important;
}