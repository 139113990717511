.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-target {
  position: relative;
  cursor: pointer;
  color: #007bff; /* Optional: Highlight the target span */
}

.tooltip {
  visibility: hidden;
  width: 200px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 8px;
  position: absolute;
  top: -150%; /* Position above the text */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip-target:hover + .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%; /* Arrow pointing down */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltipimg{
  width: 6px;
  margin: 0 10px;
}

.tooltipcontainer_infdash{
  display: flex;
  justify-content: center;
}