.appointment-sec{
    background-image: url('../../../Images/circles.png');
    background-repeat: no-repeat;
    background-position: right top;
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    height: auto;
    padding: 100px 0;
}
.appointment-schedule{
    position: absolute;
    left: 0;
    bottom: 80px;
}
.book-appointment{
    position: absolute;
    right: -60px;
    top: 100px;
}
.relative2{
    position: relative;
    display: flex;
    justify-content: center;
    overflow: visible;
    padding-left: 7% !important;
    overflow: hidden;
}

.event-sec{
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    height: auto;
    padding: 100px 0;
    text-align: center;
}

.event-sec h2{
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    color: #fff;
}
.event-sec h5{
    color:#D3D3D3;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    padding: 20px 0 40px;
}
.flex-col-center{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.relative2 .book-appointment img{
    width: 85%;
}
.appointment-schedule img{
    width: 100% !important;
}
.relative2 img{
    width: 60%;
}

@media screen and (max-width: 767px) {
    .book-appointment {
        position: absolute;
        right: -160px;
        top: 30px;
    }
    .book-appointment img{
        width: 60% !important;
    }
    .appointment-schedule {
        position: absolute;
        left: 10px;
        bottom: 80px;
    }
    .appointment-schedule img{
        width: 70% !important;
    }
    .relative2 img{
        width: 70%;
    }
    .event-sec{
       padding: 30px 0;
    }                                                                                                                                                           
    .appointment-sec{
        padding: 60px 30px;
    }
    .appointment-sec .align-center{
        display: flex;
        flex-direction:column-reverse;
    }
    .event-sec h5 {
        font-size: 15px;
        line-height: 18px;
        padding: 10px 0 20px;
    }
    .event-sec h2 {
        font-size: 26px;
        line-height: 28px;
    }

}

