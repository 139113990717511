@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel:wght@400..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel:wght@400..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel:wght@400..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel:wght@400..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap'); */
/* templat css */
/* #temp54 .main-temaplt{  font-family: 'lato', sans-serif !important;} */
#temp54 .wtemplate {
    /* height: 2080px; */
    /* height: 100%; */
    background: #0F0F0F;
    font-family: "DM Sans", sans-serif;
}

#temp54 ul.gallerylist li {
    margin: 4px 4px;
    width: 28%;
    border-radius: 18px;
}

#temp54 ul.gallerylist li img {
    border-radius: 16px;
}

#temp54 .social-links-sec{
    padding: 0 18px;
}

#temp54 h3.sectitle {
    font-size: 20px;
    margin-bottom: 16px;
    /* color: #A0A0A0; */
    font-weight: 700;
    text-align: start;
    margin-top: 34px;
    text-transform: uppercase;
    font-family: "DM Sans";
}
/* #temp54 .makeappitmneets h3.sectitle,
#temp54 .testimoniyalbox h3.sectitle {
    text-align: center;
} */

#temp54 .servesbox {
    padding: 0;
    margin: 0;
   /* display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;*/
    list-style-type: none;
    
     display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  gap:16px;
    
}

#temp54 p.para {
    font-family: "DM Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;
    /* color: #fff; */
    text-align: start; 
    word-break: break-word;

    max-height: 300px;
    overflow-y: scroll;
}

#temp54 .sec {
    font-family: "DM Sans", sans-serif;   
    /* border-radius: 8px;    
    padding: 16px 12px; */
    margin-bottom: 18px;
    margin: 23px 18px;
    /* background: linear-gradient(88.9deg, #0B1A39 0.84%, #0F0F0F 98.97%); */
}

#temp54 .mainsection {
    font-family: "DM Sans", sans-serif;   
    border-radius: 30px;  
    color: #000000;  
    z-index: 2;
    position: relative;
    max-width: 420px;
    width:94%;
    margin:-25px  12px 12px 12px;
    padding: 20px 0px 60px 0px;

background: linear-gradient(176.98deg, #f3f3f3 -0.67%, #e3e3e3 104.76%);
/* @media screen and (min-width:430px) {
    width: 405px;
    
} */



/* @media screen and (max-width:410px) {
    width: 352px;
    
}
@media screen and (min-width:420px) {
    width:407px;
    
} */
}




/* #temp54 .servicesName.sec {
    padding: 30px 30px 0;
} */

#temp54 article.abouttemplate.sec {
    text-align: center;
}

#temp54 .chevron{
    text-align: center;
}

#temp54 .carouselmain{
    position: relative;
}



#temp54 .theme20-gallery-inner-wrapper{
    position: absolute;

    right: 20px;
   
   margin-top: -35px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    >div{
        cursor: pointer;
    }
    /* justify-content: center; */
}

#temp54 h3.username {
    margin-bottom: 0;
    color:#000000;
    font-size: 28px;
    font-weight: 900;
    line-height: 34px;
   display: inline-block;
   font-family: "DM Sans";
   
   margin-top: 15px;
   text-align: left;
   padding: 0px 18px;
   text-transform: uppercase;


    height: 68px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-wrap: break-word;
}

#temp54 .imageOverlay{position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(184.37deg, rgba(244, 244, 244, 0) 34.97%, #000000 100.05%);

}


#temp54 input.form-control {
    border-radius:10px;
    background: #FFFFFF4D;
    /* border: 0.5px solid #6C6C6C; */
  height: 50px;
    padding: 10px 10px;
    font-size: 12px;
    line-height: 16px;
    /* margin-top: 5px; */
    box-shadow: none !important;
}
#temp54 input.form-control::placeholder {
    color: #6C6C6C !important; /* Change this to your desired placeholder color */
    opacity: 1; /* Make the placeholder color fully opaque */
}

/* #temp54 input.form-control::placeholder {
    color: #C68539!important;
} */

/* #temp54 textarea.form-control::placeholder {
    color: #C68539 !important;
} */

#temp54 textarea.form-control {
    border-radius: 10px;
    /* padding-left: 12px; */
    padding: 10px;
    background: #FFFFFF4D;
    font-size: 12px ;
    /* border: 0.5px solid #6C6C6C; */
    /* margin-top: 5px; */

}

#temp54 select.form-select {
    border-radius: 10px;
    background-color: transparent;
    /* border: 0.5px solid #6C6C6C;    */
    border: none !important;
    color: #999999;
    padding: 15px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add custom arrow */
    background-image: url('../../../../Images/d-arrow-w.-new.png');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 17px;
    font-size: 12px;
}

#temp54 select option{
background: linear-gradient(88.9deg, #0B1A39 0.84%, #0F0F0F 98.97%);
color:#000;
    
}
#temp54 button.btn.btn-submitform {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgb(0 0 0), rgb(0 0 0)); */
    background: #000000;
    /* box-sizing: border-box; */
    width: 100%;
    color: #fff;
    border-radius: 82px;
    padding: 15px 0px;
    font-size: 15px;
    /* border: 1px solid #fff; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "poppins";
}


#temp54 ul.socil-link li {border-radius: 100px;
    background: #000000;     
    /* box-shadow: 0px 4px 10px 0px #0000000D;  */
    width: 45px;
    height: 45px;
    display: flex;
    text-align: center;  
    font-size: 23px;
 
    /* background: #FFFCFC; */
    /* box-shadow: 0px 4px 10px 0px rgba(179, 156, 156, 0.25); */
    align-items: center;
    justify-content: center;  
}

#temp54 article.imgsechere.sec {
  
    position: relative;
    width: 100%;
    height: 500px;
 z-index: 1;
    border-radius: 0;
    /* clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 65%); */
}

#temp54 .logoTextContainer {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
   position: absolute;
   padding: 0 20px;
   top:60px;
   width:auto; 
   display: flex;
   flex-direction: column;
   justify-content: center;
   width: 100%;
}
#temp54 .coverimghere{
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: grayscale(1);
}
#temp54 article.personalinfo {
    margin-top: 0px;
    display: flex; 
    flex-direction: column;
    
    /* align-items: center; */
}

#temp54 article.qrcode-open {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp54 .logo-profile {
    padding: 0 10px 6px 10px;   
    position: relative;   
    border-bottom: 1px solid #Fff;
    width: 98px;   
    padding-bottom:15px;
    height: auto;
    border-radius: 0;
    margin: 0 0 6px 0 !important;
}



#temp54 button.btn.btnmakeappitmnets {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    /* background-color: #D58F2D; */
    background: #000000;
    width: 100%;
    color: #fff;
    border-radius: 82px;
    padding: 15px 24px;
    font-size: 15px;
    border:1px solid #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

#temp54 .timelist {
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
}



#temp54 ul.timelist li .day {
    /* margin-right: 28px; */
    font-size: 15px;
    font-weight: 500;
    /* width: 27%; */
    padding: 0 10px;
    /* color: #fff; */
}

#temp54 ul.servesbox li {
    position: relative;
}

#temp54 ul.conlink li i {
    min-width: 40px;
    font-size: 20px;
    /* height: auto; */
    /* background: antiquewhite; */
    text-align: center;
    /* line-height: auto; */
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%); */
    /* color: #D58F2D; */
    border-radius: 0;
    margin-bottom: 20px;
}

#temp54 ul.conlink li span {

   font-family: "DM Sans", sans-serif;
    padding-left: 1px;
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
    /* color: #F6F6F6; */
    text-align: left;
    word-break: break-word;
}

/* #temp54 ul.conlink li {
    margin-bottom: 19px;
    display: flex;
} */

#temp54 ul.conlink {
    padding: 0px;
}

#temp54 ul.conlink li a {
    display: flex;    
    align-items: flex-start;
    color: #000000;
}


#temp54 p.titleuser {
    /* color: #ffff; */
    margin: 0;
    word-break: break-word;
    font-size: 18px;
    font-weight: 400;
    font-family: "DM Sans";
    display: inline-block;
    padding: 0 18px;
    /* padding-left: 70px; */
    height: 44px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-wrap: break-word;
    line-height: 21px;
}

#temp54 article.businesshour.sec h3 {
    text-align: center;
}


#temp54  .businessHours p {
   font-size: 15px;
   font-family: "DM Sans";
   font-weight: 400;

  
}
#temp54  .businessHours h3 {
  position: relative;
.rightcorner{position: absolute;
    right: 0;
    font-size: 15px;
    cursor: pointer;
    transition: 0.5s ease-in-out;

}
   
 }
#temp54  .businessHours .bgText {
    font-size: 20px;
   
    
   
 }


#temp54 ul.servesbox li {
    position: relative;
}

#temp54 ul.servesbox li:before {
    content: "";
    width: 15px;
    height: 15px;
    /* background: #ff752a; */
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: -9px;
    top: 0px;
}

#temp54 ul.servesbox li {
    font-family: "DM Sans", sans-serif;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
    border-radius: 90px;   
    background-color: #000000;    
    padding: 8px 12px;  
    /* box-shadow: 2px 2px 0px 0px #D58F2D; */
}

#temp54 ul.timelist li {
    position: relative;
}

#temp54 ul.timelist li:before {
    content: "\f017";
    font-family: 'FontAwesome';
    color: #6A6A6A;
    /* margin-right: 11px; */
    position: absolute;
    right: 20px;
    font-size: 24px;
}

#temp54 .time {
    font-weight: 400;
    font-size: 15px;
    font-weight: 500;
}


/* #temp54 .testimoniyalbox.sec {   
    color: #fff;
    background: linear-gradient(88.9deg, #0B1A39 0.84%, #0F0F0F 98.97%);
    position: relative;
} */
/* #temp54 .testimoniyalbox.sec .quoteup {
    position: absolute;
    left:8px;
    top:10px;
    width:40px;
} */

#temp54 .testimonialcontent img {
    /* border: 3px solid #ffc708 !important; */
    /* margin: 0 !important; */
    height: 100%;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50px;
    filter: grayscale(1);
  }
  
  /* #temp54 .sectitle.testimonial {
    color: #ffffff;
  } */

  #temp54 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
    /* background: linear-gradient(270deg, rgba(229, 49, 110, 0) 0%, rgba(229, 49, 110, 0.36) 23.32%, rgba(229, 49, 110, 0.36) 79.61%, rgba(229, 49, 110, 0) 101.41%);
    */
    margin: 0 -10px;
    padding: 0 10px;
  }
  
  #temp54 .testimonials {
    display: flex;
    width: max-content;
  }

  #temp54 .testimonialname {
    font-size: 20px;
  }
  
  #temp54 .testimonialdescription {
    font-size: 15px;
    text-align: left;
    line-height: 22px;
    padding-top: 5px;
    word-break: break-word;
  }

  #temp54 .testimonialimg {
    border: 5px solid #ffc708;
  }
  
  #temp54 .testimonialcontent {
    display: flex;
    /* margin: 10px 0; */
    align-items: flex-start;
  }
  
  #temp54 .testimonialcontent>:first-child {
   width: 25%;
  }
  #temp54 .testimonialcontent>:last-child {
    width: 75%;
   }


  #temp54 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
  }

  
/* #temp54 .testimoniyalbox.sec {
    background-color: #2f3438;
    margin: 5px 0px;
    border-radius: 10px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
  } */
  
  #temp54 .testimonials-container .slick-list{
    padding-top: 0 !important;
  }

  /* #temp54 .testimoniyalbox .testimonial-inner {
    border: 1px solid #562435;
    padding: 15px 15px;
    background: linear-gradient(0deg, #201619, #201619),
      linear-gradient(0deg, #562435, #562435);
    border-radius: 5px;
  
  
  } */





/* 

#temp54 .testimoniyalbox.sec .quotedown {
    position: absolute;
    right:8px;
    bottom:10px;
    width:40px;
}
#temp54 .testimonialimg {
    border: 5px solid #ffc708;
}

#temp54 .testimonialcontent {
    display: flex;
    margin: 6px 0 0 0;
    align-items: flex-start;
}

#temp54 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
} */

#temp54 .testiprofile {
    width: 170px;
    height: 170px;
}
/* 
#temp54 .testimonialname {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    padding-top: 30px;
    display: block;
    width: 100%;
    text-align: center;
}

#temp54 .testimonialdescription {      
    line-height: 22px;
    padding-top: 0;
    font-size: 14px;
    color: white;
    text-align: center;
    width: 70%;
} */

#temp54 .testibox {
    text-align: center;
    width: 350px;
    margin:0 auto;
/* }

#temp54 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
}

#temp54 .testimonials {
    display: flex;
    width: max-content;
    width: 200% !important; */

} 


#temp54 .testiimg {
    height: 100px;
    padding: 10px 0px 10px 10px;
}

/* #temp54 .mainbg {height: 100%;
background-color: #000000;
} */


    #temp54 .rating{
        color: #FFA538;
    }
/* 
#temp54 .testimonial-rating {
    width:100%;
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
}
#temp54 .testimonialcontent img {
    border: 3px solid #ffc708 !important;
    margin: 0 !important;
    border-radius: 50px;
}

#temp54 .sectitle.testimonial {
    color: #000;
    color: #A0A0A0;
} */

#temp54 .testibox {
    text-align: justify;
    position: relative;
}

#temp54 label.form-label {
    color: #F6F6F6 !important;
    font-size: 12px;
    font-weight: 300;
}

#temp54 .clintname {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:10px
}


/* comman css */

#temp54 .testibox {
    background: #FFFFFF4D;
    text-align: center;
}



#temp54 h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    color:#000;
}

#temp54 .main-temaplt ul {
    list-style-type: none;
    padding: 0px;
}

#temp54 ul {
    margin: 0px;
}

#temp54 .main-temaplt a {
    text-decoration: none;
    color: #fff;
}



#theme3 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}

ul.startaring {
    margin-top: 16px;
}

img.mx-auto.d-block.img-fluid.coverimghere {
    width: 100vw;
    height: 285px;
}


.wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
}

#temp54 ul.socil-link {
    ROW-GAP: 15PX;
    display: flex;
    /* justify-content: space-around; */
    /* justify-content: start; */
    flex-wrap: wrap;
    padding: 0;
    margin-top: 0;
    column-gap: 25px;
    justify-content: center;
}

#temp54 ul.socil-link-inner {
    display: flex;
    justify-content: center;
    row-gap: 15px;
    margin-top: 0px;
    column-gap: 30px;
    color: white;
}

/* #temp54 ul.socil-link-inner li a {
    color: transparent;

} */

#temp54 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}

#temp54 ul.timelist li {
    color: #000000;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    border-radius: 55px;
    /* border: 0.5px solid rgba(255, 255, 255, 0.33); */
    background: #ffffff4D;
    /* -webkit-backdrop-filter: blur(40px); */
    /* backdrop-filter: blur(40px); */
    padding: 16px 10px;

}

#temp54 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}

#temp54 ul.gallerylist li {
    margin: 4px 4px;
}

#temp54 article.fixedfooter {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    /* padding: 0 20px; */
    position: relative;
    /*
    align-items: center;
    background: transparent; */
    margin: 0px;
}

@media(max-width: 380px){
    #temp54 article.fixedfooter{
        justify-content: space-between;
    }
}

#temp54 .personalinfo.modal-info {
    text-align: center;
}

#temp54 .reviewrating {
    padding-left: 18px;
    display: flex;
    align-items: center;
}

#temp54 .fixedd {
    height: 60px;
    border-radius: 30px;
    margin-right: 19px;
    margin-left: 105px;
    position: fixed;
    bottom: 14px;
    width: 280px;
    /* transform: translate(0%, 0px); */
    /* padding: 20px 15px; */
    /* box-shadow: 0px 0px 18px 5px #0000000d; */
    z-index: 98;
    background: #000000;
    padding: 5px;
    padding-left: 20px;
    @media screen and (max-width:415px) {
max-width: 325px;        
    }
    @media screen and (max-width:400px) {
width: 73vw;    
right: 0;
    }
}

#temp54 .addtocinatct {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px; */
    background: transparent;
    color: #fff;
    padding-top: 4px;
    border-radius: 23px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 5px;
}


#temp54 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp54 .footerleft {
     /* position: absolute; */
     display: flex;
     /* top: -36px; */
     width: 50px;
     height: 50px;
     /* background: #D58F2D; */
     text-align: center;
     line-height: 35px;
     color: #fff;
     border-radius: 50%;
     justify-content: center;
     align-items: center;
     border: 2px solid #FFB27F;
     /* left: calc(50% - 37px); */
     /* box-shadow: 0px 0px 9px 2px #000;*/
}



#temp54 .qrcodeshaere {
    cursor: pointer;
    /* cursor: pointer; */
    width: 100%;
    /* height: 55px; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    font-size: 35px;
    /* border-radius: 100px; */
    /* padding: 45px;*/
}

#temp54 .addtocinatct {
    cursor: pointer;
}

@media (max-width: 480px) {
    .fixedd {
        width: 100vw;
    }

    .coverimghere {
        width: 100% !IMPORTANT;
    }

    ul.gallerylist li {
        margin: 4px 4px;
        width: 46%;
    }

    .wtemplate {
        padding: 0px;
    }

    #temp54 .sec {
        /* background: #F8F8F8; */
            /* background: #F8F8F8; */
            border-radius: 8px;
            /* padding: 16px 12px; */
            margin-bottom: 18px;
            margin: 21px 9px;
            /* background: linear-gradient(88.9deg, #0B1A39 0.84%, #0F0F0F 98.97%); */
         }



}

#temp54 .modal-content {
    width: 80%;
    border-radius: 20px;
}

#temp54 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp54 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp54 .personalinfo.modal-info p {
    font-size: 16px;
}

#temp54 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp54 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

#temp54 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
}

#temp54 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
}

#temp54 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
}

#temp54 .carousel-btn {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background: #D58F2D;
    color: #ffffff;
    /* border-radius: 50%; */
    font-size: 16px;
    margin: 0 10px 15px 0px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    /* display: none; */
}

#temp54 .carousel-container {
    display: flex;
    justify-content: flex-start;
    width:100%;
    justify-content: center;
}
#temp54 .carousel-containerExternal {
  /* margin: 0 0 10px 0; */
  padding-bottom: 20px;
}


#temp54 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp54 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp54 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp54 .twoimages {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    /* flex-wrap: wrap; */
    max-height: 135px;
    overflow: hidden;
    filter: grayscale(0.2);
}

#temp54 .skeleton-blog {
    margin-bottom: 2em;
    animation: scale 4s infinite;
}

#temp54 .skeleton-footer {
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
}

@media (max-width:400px) {
    /* #temp54 .footerleft {
        width: 80px;
        height: 80px;
        border: 4px solid #24292ce0;
    } */

    /* #temp54 .qrcodeshaere {
        font-size: 40px;
    } */
}

@media (max-width:520px) {
    /* #temp54 .fixedd {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        background: #fcfbfb;
        transform: translate(0%, 0px);
        padding: 0;
        box-shadow: 0px 0px 18px 5px #0000000d;
        z-index: 999999;
    } */
}

#temp54 .qr-logo-image {
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width: 80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
    margin: 0 auto;
}

#temp54 canvas {
    border-radius: 20px;
}

#temp54 .canvas-container {
    display: flex;
    justify-content: center;
}

#temp54 .calanderIcon {
    position: absolute;
    top: 18px;
    right: 15px;
    cursor: pointer;
}

#temp54 .extmakeappointmentform{
    background-color: #fff !important;
    box-shadow:rgba(0, 0, 0, 0.15) 0px 4px 10px;
    border-radius: 20px !important;
    color: #1c1a1a !important;
}

#temp54 .extmakeappointmentsubmitbtn{
    background: #000000db !important;
    width: 100% !important;
    color: #fff !important;
    border-radius: 20px !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
  }

  #temp54 .extmakeappointmentformplaceholder input::placeholder {
    color: #999999 !important;
}
/* #temp54 .contact-form input::placeholder,
#temp54 .contact-form textarea::placeholder {
    color: #C68539 !important;
} */
#temp54 .makeappitmneets  .contact-form h5{
    color: #ffff;
    font-size: 12px;
    display: none;
}
#temp54 .makeappitmneets  label{
    display: block;
    color: #000000;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 8px;
   
}
#temp54 .contactformLableName {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 5px;
    
   
}

#temp54 .makeappbrd{
    /* border: 1px solid #; */
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 15px;
}


/* input[type="date"] {
    -webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../../../Images/calendarIcondays.png');
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 20px;
    color: white !important;
    position: absolute;
    top: 20px;
  }

  input[type="date"]:focus {
    outline: none; 
    border-color: blue; 
  } */
  @media screen and (max-width:414px) {

  }

  @media screen and (max-width:400px) {
        

  }



  