.Contact_filter_fieldbutton_wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: flex-start;
    
}

.Contact_filter_field_wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* text-align: center; */
    width: 100%;
}

.Contact_filter_Inputfield{
    margin: 0 10px;
    position: relative;
    width: max-content !important;
}
.contact_filter_buttonstyle{
    border: none !important;
    background: transparent !important;
    padding: 15px 0 !important;
    border-radius: 10px !important;
    color: #6a6a6a !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    margin-left: 10px !important;
}

.contact_filter_buttonwrapper{
    margin-top: 20px !important;
    
}

.contact_filter_buttonwrapper :hover{
    color: #494949 !important;
    font-size: 15px !important;
    background: transparent !important;
    text-decoration: underline;
}

.contact_filter_toDate_calander_icon{

    position: absolute;
    top: 10px;
    right: 27px;
    font-size: 25px;
    cursor: pointer;
    color: #bebebe;
}

.dateiconmain{
    position: relative;
    /* width: 245px; */
}



.Contact_filterandsearch_wrapper{
    display: flex !important;
    justify-content: center;
}

.react-datepicker-wrapper{
    width: 100% !important;
}


