.check_box_row {
  padding: 20px 0 !important;
  width: 10px;
  /* padding: 0 !important; */
}
.form-check-input {
  width: 17px !important;
  height: 17px !important;
  cursor: pointer !important;
}
.modal-style {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 70% !important;
  transform: none;
}

@media (max-width: 700px) {
  .modal-style {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    width: 100% !important;
    transform: none;
    /* margin-right: 10px !important; */
    /* margin-right: 0px !important; */
  }
}
.table {
  margin: 0 !important;
}

.form-check {
  /* width: 10px !important; */
  margin-bottom: 0 !important;
  /* padding: 20px 0 !important; */
}

.table.table tr td {
  padding: 0 !important;
  /* padding: 20px 0 !important; */
  line-height: 25px !important;
}
.business_row {
  padding: 0 !important;
  /* padding: 0.5rem 0.5rem !important; */
}

thead,
tbody,
tfoot,
tr,
td,
th {
  /* padding: 20px 0 !important; */
}

.physical_card_row_th {
  padding: 0 !important;
}

.check_box_dashboard {
  /* padding: 20px 0 !important; */
}

#check_box_dashboard_id {
  padding: 18px 0 !important;
}
.check_box_dashboard_id {
  padding: 18px 0 !important;
}

#check_box_dashboard_idMid {
  padding: 10px 0 !important;
}
.check_box_dashboard_idMid {
  padding: 10px 0 !important;
}

.modal-content {
  width: 70%;
}

@media (max-width: 1200px) {
  .modal-content {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .modal-content {
    margin: 0 15px;
  }
}

/* .form-check .form-check-input{
    width: 17px !important;
} */

#flexCheckDefault {
  width: 17px !important;
  /* margin-right: 10px !important; */
}

.addnewRole_Filterwrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.tableusername {
  display: flex;
  padding: 20px 0px !important;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.permissionSlotUpdatedStyle {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-right: 35px !important;
}

@media (max-width: 1450px) {
  .permissionSlotUpdatedStyle {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-right: 15px !important;
  }
}

.modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* class="check_box_dashboard_id" id="check_box_dashboard_id" */

.CreateUpdateRoleform {
  padding-left: 7px !important;
}

@media(max-width: 425px){
#CreateNewRoleSpacingIssueResolved{
  padding: 0px 15px 30px;
}
}