@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap');



/* templat css */
/* #temp7 .main-temaplt{  font-family: 'lato', sans-serif !important;} */
#temp36 .wtemplate {
    background: #F0E4CE;
    font-family: "Roboto", sans-serif;
}

#temp36 ul.gallerylist li {
    margin: 4px 4px;
    width: 28%;
    border-radius: 18px;
}

#temp36 ul.gallerylist li img {
    border-radius: 16px;
}

#temp36 h3.sectitle {
    font-family: Oswald;
    font-size: 20px;
    font-weight: 700;
    line-height: 29.64px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #332518;    
    text-transform: uppercase;
    margin-bottom: 15px;
    /* margin-top: 30px; */
}
#temp36 .makeappitmneets  h3.sectitle {
   text-align: center;
}

#temp36 .servesbox {
    /* row-gap: 10px; */
    padding: 0;   
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:   15px;
    list-style-type: none;
}






#temp36 p.para {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    
    color: #815738;

    word-break: break-word;
    /* text-align: start; */
}

#temp36 .sec { 
    margin: 0 20px 35px 20px;
    padding: 0px 0px;
   margin-bottom: 24px;
    background: transparent;
    /* border-radius: 12px; */
    /* box-shadow: 0px 4px 24px 0px #0000001F; */
}



#temp36 .fixedfooter.sec{
    background: #1C85E7;
    box-shadow: none;
    padding: 15px 0;
}
 #temp36 .imgsechere.sec,
#temp36 .businessHours.sec, #temp36 .makeappitmneets.sec {
    /* box-shadow: none; */
    /* padding: 15px 13px 0 13px; */
    /* padding: 15px 0px 0 0px; */
}

#temp36 .testimoniyalbox.sec {
    /* background: #f8f8f8; */
    /* background: transparent;
    color: #000;
    box-shadow: none;
    margin: 0;
    padding: 0; */
}
#temp36  .testimonials-wrapper{
    position: relative;
    margin-bottom: 30px;
 
}
#temp36  .testimonials-wrapper::after{
    /* position: absolute;
    content:'';
    left:0;
    top:0;
    height: 100%;
    width:80px;
    background-color: #37686C;
    z-index: 0; */
}
/* #temp36 .servicesName.sec {
    padding: 30px 30px 0;
} */

#temp36 article.abouttemplate.sec {
    text-align: center;
}

#temp36 h3.username {  
    color: #F0E4CE;
    margin: 0 35px;
    /* background: #37686C; */
    font-family: Oswald;
    font-size: 20px;
    font-weight: 600;
    line-height: 29.64px;
    letter-spacing: 0.1em;
    text-align: center;
word-wrap: break-word;  
text-transform: uppercase;  
}

#temp36 .designation {  
    color:#fff;
    word-wrap: break-word;
    text-transform: uppercase;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: 0.05em;
    text-align: center;
    margin: 0;
    /* margin-top: -10px; */
    
}


#temp36 input.form-control {
    border-radius: 4px;
    background: #81573852;
     
    /* color: #8C8C8C; */
    padding: 15px 15px;
    color: #815738;
    box-shadow: none;
    font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 14.52px;
text-align: left;
outline: none;
box-shadow: none;
        border: 0.5px solid #AC885E;


}

#temp36 .contact-form {
    ::placeholder{font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: left;
        color: #AC885E;

    }
}
#temp36 .contact-form input:focus ,#temp36 .contact-form textarea:focus  {
    border-radius: 4px;
    background: #81573852;
     
    /* color: #8C8C8C; */
    padding: 15px 15px;
    color: #815738;
    box-shadow: none;
    font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 14.52px;
text-align: left;
outline: none;
box-shadow: none;
        border: 0.5px solid #AC885E;
}


#temp36  .makeappitmneets h5{
    display: none;

}

#temp36 input.form-control::placeholder {
    color: #AC885E ;
    
}

#temp36 textarea.form-control::placeholder {
    color: #AC885E ;
}

#temp36 textarea.form-control {
    border-radius: 4px;
    background: #81573852;
     
    /* color: #8C8C8C; */
    padding: 15px 15px;
    color: #815738;
    box-shadow: none;
    font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 14.52px;
text-align: left;
outline: none;
box-shadow: none;
        border: 0.5px solid #AC885E;
    

}



#temp36 select.form-select {  
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    border-radius: 4px;
    color: #815738;
    background: #81573852;
    padding: 15px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
    background-image: url(../../../../Images/theme-36-cheveron.png);
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 15px;
        border: 0.5px solid #AC885E;
    /* box-shadow: 0px 2px 5px 0px #0000004D; */
}

#temp36 button.btn.btn-submitform {
   
    background: #815738;
    width: 100%;
    color: #fff;
    border-radius: 4px;
    padding: 15px 0;
    font-family: Oswald;
font-size: 16px;
font-weight: 400;
line-height: 23.71px;
text-align: center;
/* letter-spacing: -0.1em; */
text-transform: uppercase;
    margin-top: 10px;
}

#temp36 ul.socil-link li {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: flex; 
    font-size: 22px;   
    background: #815738;     
    box-shadow: 0px 4px 10px 0px #0000000D;
    align-items: center;
    justify-content: center;  
    >a >i{
       color: #ffffff
    }  
}

#temp36 article.imgsechere.sec {
    position: relative;
    width: 100%;
    height: auto;  
    border-radius: 0;   
}
/* 
#temp36 article.imgsechere.sec::after{
    content: '';
    position: absolute;
    left:0;
    top:0;
    width:100%;
    background-color: #1C85E7;
    height:65%;
} */
#temp36 .imgsechere-top{
    padding: 12px 18px 0 18px;
    position: relative;
    z-index: 6;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
#temp36  .imgsechere-main-banner{
    height: 420px;
    width:100%;
    overflow: hidden;
    /* box-sizing: border-box;
    padding: 60px 28px 20px 28px; */

}
#temp36  .imgsechere-top-left{
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 700;
    color:#fff;
    line-height: 20px;
    align-items: center;
}


#temp36 article.qrcode-open {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp36 .logo-profile {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 177px;
    z-index: 2;
    /* border: 1px solid #1BBFA2; */
}
#temp36 .bannerb2{
    height: 630px;
}
#temp36 .mainsection{margin-top: -40px;
/* background-image: url('../../../../Images/theme-28-bg.png'); */
background-repeat: no-repeat;
background-size: cover;
border-radius: 40px 40px 0 0 ;
z-index: 1;
position: absolute;
max-width: 430px;
width: 100%;
padding-top: 56px;
padding-bottom: 80px;
margin-bottom: 20px;

}
#temp36 .widthsec {
    max-width: 400px;
}


#temp36 .logoext {
bottom: 88px;
}

#temp36 .personalinfo  {
  font-family: "Oswald", sans-serif;
  /* margin-top: -40px; */
  position: relative;  
  text-align: center;
  color:#F0E4CE;
  margin-bottom: 30px;
  background: #815738;
  border-radius: 8px;
  padding: 10px;
}

#temp36 .reviewrating{
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
}

#temp36 .ratingspan{
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400; 

}


#temp36 article.personalinfo {
    display: flex;
    min-height: 110px;
    font-family: "Oswald", sans-serif;
    margin-top: -63px;
    position: relative;
    text-align: center;
    color: #F0E4CE;
    margin-bottom: 30px;
    background: #815738;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#temp36 button.btn.btnmakeappitmnets {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background-color: #815738;
    width: 100%;
    color: #fff;  
    padding: 15px 0;
    font-family: Oswald;
    font-size: 16px;
    font-weight: 400;
    line-height: 23.71px;
    text-align: center;
text-transform: uppercase;    
}

#temp36 .timelist {
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
}

#temp36 ul.timelist li .day {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: center;
    padding: 0 10px;
    color: #332518;
}

#temp36 ul.servesbox li {
    position: relative;
}

#temp36 ul.conlink li i { 
    font-size: 16px;
    text-align: center;
    color: #332518;
    /* width: 30px; */
    /* height: 30px; */
    /* background: transparent; */
    /* border-radius: 50px; */
    /* box-shadow: 0px 4px 10px 0px #0000000D; */
    display: flex;
    align-items: center;
    justify-content: center;
    


}

#temp36 ul.conlink li span {
    word-break: break-word;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    
    color: #815738;
    /* text-align: left; */
    /* width:calc(100% - 45px); */
}

#temp36 ul.conlink li {
   margin-bottom: 16px;
}
#temp36 ul.conlink li:last-child {
    margin-bottom: 0;
 }
#temp36 ul.conlink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

#temp36 ul.conlink li a {
    display: flex;
    /* word-break: break-word; */
    align-items: center;
    justify-content: center;
    gap: 6px;
    flex-direction: column;
}

#temp36 ul.conlink .breakText {

word-break: break-word;
}




#temp36 p.titleuser { 
    margin: 0;
    word-break: break-word;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    font-family: "Libre Baskerville";
  margin-top: 15px;
  margin-bottom: 33px;
}

#temp36 article.businesshour.sec h3 {
    text-align: center;
}

#temp36 ul.servesbox li {
    position: relative;
}

/* #temp36 ul.servesbox li:before {
    content: "";
    width: 8px;
    height: 8px;
    background: #37686C;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: 4px;
    top: 7px;
} */

#temp36 ul.servesbox li {
   
    border-radius: 2px;
    padding: 12px 0;
    border: 1px solid #815738;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: center;
display: flex;
justify-content: center;    
    color: #1E1E1E;
    position: relative;
   
    align-items: center;

}



#temp36 ul.timelist li {
    position: relative;
}

#temp36 ul.timelist li:before {
    content: "\f017";
    font-family: 'FontAwesome';
 
    color:#815738;
    /* margin-right: 11px; */
    position: absolute;
    right: 20px;
    font-size: 22px;
}

#temp36 .time {
    font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
text-align: center;

  color:  #332518;
}

#temp36 .testimonialimg {
    border: 5px solid #ffc708;
}

#temp36 .testimonialcontent {
    display: flex;
    margin: 10px 0px;
    align-items: flex-start;
}

#temp36 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
}

#temp36 .testiprofile {
    width: 170px;
    height: 170px;
}

#temp36 .testimonialname {
    
    letter-spacing: 0.03em;
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.99px;
    text-align: left;
color: #332518;    
}

#temp36 .testimonialdescription {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
color: #815738;    
    /* width: 70%; */
}

#temp36 .testibox {
    text-align: center;
    width: 480px;
}

#temp36 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
    position: relative;
    z-index: 0;
}

#temp36 .testimonials {
    display: flex;
    width: max-content;
    /* width: 200% !important; */

}

#temp36 .testiimg {
    width: 90px;
    /* height: 90px;
    border-radius: 50px;
    border: 3px solid #1C85E7;
    flex: 0 0 auto; */
}

#temp36 .testimonialcontent img {
    border: 1px solid #815738;
    box-sizing: border-box;
    display: block;
    width: 90px;
    height: 99px;
    margin: 0 !important;
    border-radius: 4px;
}

/* #temp36 .sectitle.testimonial {
    color: #000;
    color: #1E1E1E;
    margin-left: 31px;
} */

#temp36 .testibox {
    text-align: justify;
    position: relative;
}

#temp36 label.form-label {
    color: #FF9635 !important;
    font-size: 14px;
}

#temp36 .clintname {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    width: 260px;
}


/* comman css */

#temp36 .testibox {
    text-align: center;
}



#temp36 h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
}

#temp36 .conatctform{
    /* padding-top: 24px;
    margin-top: 40px; */
}


#temp36 .main-temaplt ul {
    list-style-type: none;
    padding: 0px;
}

#temp36 ul {
    margin: 0px;
}

#temp36 .main-temaplt a {
    text-decoration: none;
    color: #fff;
}

#temp36 .logo-profile {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    margin: 0  auto !important;
}

#theme18 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}

ul.startaring {
    margin-top: 16px;
}

#temp36 img.mx-auto.d-block.img-fluid.coverimghere {
    object-fit: cover;
    height: 100%;
    width: 100%;

    /* position: relative;
    z-index: 5;
    border-radius: 117px;
    box-shadow: 0px -6px 44px 0px #00000040; */
}


.wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
    background: #F0E4CE;
}

#temp36 ul.socil-link {
    display: flex;  
    justify-content: center;
    flex-wrap: wrap;    
    column-gap: 25px;
    row-gap: 15px;
}
#temp36 ul.socil-link a {
    color:#07080A;
    margin-top: 3px;
}
#temp36 ul.socil-link-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;   
   column-gap: 24px;
   row-gap: 15px;
    color: white;
}

/* #temp36 ul.socil-link-inner li a {
    color: transparent;

} */

#temp36 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}

#temp36 ul.timelist li {

    display: flex;
    margin-bottom: 14px;
    align-items: center;
    border-radius: 4px;
    color: #332518;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    padding: 15px 20px;
    box-shadow: 0px 2px 5px 0px #0000004D;
}

#temp36 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}

#temp36 ul.gallerylist li {
    margin: 4px 4px;
}

#temp36 article.fixedfooter {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    /* padding: 0 20px;
    align-items: center;
    background: transparent; */
    margin: 0px;
    position: relative;
}

@media(max-width: 380px){
    #temp36 article.fixedfooter{
        justify-content: space-between;
    }
}

#temp36 .personalinfo.modal-info {
    text-align: center;
}

#temp36 .fixedd {
    position: fixed;
    bottom: 0px;
    width: inherit;
    background: #805835;
    transform: translate(0%, 0px);
    padding-bottom:  12px;
    /* box-shadow: 0px 0px 18px 5px #0000000d; */
    z-index: 98;
}

#temp36 .addtocinatct {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px; */
    background: transparent;
    color: #F0E4CE;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

#temp36 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp36 .footerleft {
    position: absolute;
    display: flex;
    /* top: 10px; */
      top: 0;
      bottom: 0;
    text-align: center;
   
   width: 50px;
    justify-content: center;
    align-items: center;
  
}

@media(max-width: 380px){
    #temp36 .footerleft {
        left: 141px;
    }
}
@media(max-width: 340px){
    #temp36 .footerleft {
        left: 115px;
    }
}
@media(max-width: 315px){
    #temp36 .footerleft {
        left: 100px;
    }
}

#temp36 .qrcodeshaere {
    cursor: pointer; 
    font-size: 40px;
    width: 100%;
    /* color:#37686C; */
    /* cursor: pointer; */
    /* width: 60px; */
    /* height: 60px; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
   
    /* border-radius: 100px; */
    /* padding: 40px;*/
    >img{
        width: 100%;
    }
}

#temp36 .addtocinatct {
    cursor: pointer;
}

#temp36 .addtocinatct .icon11 {
    /* margin-left: 10px;
    font-size: 11px; */
}



@media (max-width: 480px) {
    .fixedd {
        width: 100vw;
    }

    .coverimghere {
        width: 100% !IMPORTANT;
    }

    ul.gallerylist li {
        margin: 4px 4px;
        width: 46%;
    }

    .wtemplate {
        padding: 0px;
    }

    #temp36 .sec {
        /* background: #F8F8F8; */
        border-radius: 14px;
        /* padding: 20px; */
        margin-bottom: 18px;
        margin: 21px 9px;
    }



}

#temp36 .sec-common{
    margin: 0 18px 25px 18px;
}

#temp36 .modal-content {
    width: 80%;
    border-radius: 20px;
}

#temp36 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp36 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp36 .personalinfo.modal-info p {
    font-size: 16px;
}

#temp36 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp36 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

#temp36 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
}

#temp36 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
}

#temp36 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
}

#temp36 .carousel-btn {
    cursor: pointer;
    color: #815738;  
    font-size: 16px;
    margin: 0 10px 0 0px;
    width: 10px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    /* background: #fff; */
    border-radius: 0;
}

#temp36 .carousel-container {
    z-index: 1;
    position: absolute;
    right: 0;
    top: -25px;
    display: flex;
    justify-content: flex-end;
    /* padding-left: 110px; */
}

#temp36 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp36 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp36 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp36 .twoimages {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    gap: 10px;
    margin:0 0px 10px 0px;

    /* height: 110px !important; */
}

#temp36 .skeleton-blog {
    margin-bottom: 2em;
    animation: scale 4s infinite;
}

#temp36 .skeleton-footer {
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
}

@media (max-width:400px) {
    #temp5 .footerleft {
        width: 80px;
        height: 80px;
        border: 4px solid #24292ce0;
    }

    #temp5 .qrcodeshaere {
        font-size: 40px;
    }
}

@media (max-width:520px) {
    #temp36 .fixedd {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        /* background: #fcfbfb; */
        transform: translate(0%, 0px);
        padding: 0;
        box-shadow: 0px 0px 18px 5px #0000000d;
        z-index: 98;
    }
}

#temp36 .qr-logo-image {
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width: 80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
    margin: 0 auto;
}

#temp36 canvas {
    border-radius: 20px;
}

#temp36 .canvas-container {
    display: flex;
    justify-content: center;
}

#temp36  .logoTextContainer {
    padding: 20px 0;
    margin: 30px;

}

#temp36 .calanderIcon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

#temp36 .extmakeappointmentform{
    background-color: #fff !important;
    box-shadow: 0px 2px 5px 0px #0000004D;
    border-radius: 4 !important;
    color: #1c1a1a !important;
    border-color: #cbcbcb;
}

#temp36 .extmakeappointmentsubmitbtn{
    background: #815738 ;
    width: 100% !important;
    color: #fff;
    border-radius: 4 !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 9px;
  }

  #temp36 .extmakeappointmentformplaceholder input::placeholder {
    color: #a4a4a4 ;
}




#temp36 .gallery img{
    border-radius: 0 !important;
}
#temp36 .theme18-gallery-wrapper{
    padding-bottom: 30px;
    position: relative;
}
#temp36 .theme18-gallery-inner-wrapper{
    position: absolute;
  bottom: 0px;
    right: 0px;
    left: 0;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    justify-content: center;
}
#temp36 .theme18-gallery-inner-wrapper img{
    cursor: pointer;
}

#temp36  .contact-form .react-tel-input.DetailsInfoPhoneInput.colorctaborder  .form-control{
   padding: 24px 0px 24px 60px !important

}

#temp36 .contact-form, #temp36  .makeappitmneets{
    .form-control, .form-select{
  background:#00000030 !important;
  color:#fff !important;
  
  &::placeholder{
    color:#fff  !important;
  }
    }
  
  }

/* #temp36 .inputfield_defaulticon{
    
} */
/* input[type="date"] {
    -webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../../../Images/calendarIcondays.png');
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 20px;
    color: white !important;
    position: absolute;
    top: 20px;
  }

  input[type="date"]:focus {
    outline: none; 
    border-color: blue; 
  } */


 
