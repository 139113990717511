
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
/* templat css */
#temp2 .wtemplate{background: #E5F7FF}
#temp2 article.personalinfo.sec {
    text-align: center;
    width: 80%;
    margin: 0 auto;
}
#temp2 article.personalinfo.sec {
    position: relative;
}
#temp2 h3.username {
  font-size: 26px;
  color: #343c99;
  font-weight: 600;
  text-align: center;
  height: 34px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
}
#temp2 p.titleuser {
    margin: 9px 0px;
    word-break: break-word;
}
#temp2  p.titleuser {
    font-weight: 600;
    font-size: 18px;
    padding: 0;
    margin: 0;
}
#temp2  ul.socil-link li a {
    color: #343c99;
    font-size: 34px;
}


#temp2  ul.socil-link {
    justify-content: center;
}
#temp2  ul.socil-link li {
    font-size: 29px;
    margin: 0px 15px;
}

#temp2  article.social-links-sec.sec h3 {
    display: none;
}

#temp2 h3.sectitle {
    color: #343C99;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
    padding-bottom: 15px;
}
#temp2 .sec {
    margin: 32px 0px;
}
#temp2 .wtemplate {
    background: #E5F7FF;
    position: relative;
}

#temp2 .day {
    background: #343C99;
    color: #fff;
    width: 35%;
    text-align: center;
    border-radius: 30px;
    margin-right: 35px;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 0;
}

#temp2 .time {
    color: #343C99;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-transform: capitalize;
}
#temp2 ul.businessTime li {
    background: #bfc7dc40;
    color: #343c99;
    border-radius: 20px;
}

#temp2 ul.conlink {
    background: linear-gradient(0deg, rgba(191, 199, 220, 0.12), rgba(191, 199, 220, 0.12)), linear-gradient(88.78deg, rgba(218, 90, 250, 0.6) -34.1%, rgba(53, 112, 236, 0.6) 113.16%);
    padding: 31px 0px;
    text-align: center;
    border-radius: 19px;
    padding: 0px 1px !important;
}
#temp2 ul.conlink a {
    color: #fff;
    font-size: 16px;
    text-decoration: none;
}

#temp2 ul.conlink li i {
    margin-right: 9px;
}

#temp2 article.abouttemplate.sec p {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    padding: 0 30px;
    letter-spacing: 0em;
    word-break: break-word;
    text-align: center;
    font-family: 'Inter', sans-serif !important;
    color: #3D3D3D;
}

#temp2 button.btn.btn-submitform {
    display: block;
    width: 100%;
    background: #343c99;
    border-radius: 28px;
    padding: 15px 0px;
    font-size: 15px;
    color: #fff;
}

#temp2 ul.servesbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
#temp2 ul.servesbox li {
    background: linear-gradient(88.78deg, rgba(218, 90, 250, 0.6) -34.1%, rgba(53, 112, 236, 0.6) 113.16%);
    color: #fff;
    padding: 9px 18px;
    margin: 10px 8px;
    border-radius: 22px;
    font-size: 11px;
    font-weight: 400;
}

#temp2 article.fixedfooter.sec {
    /* margin: 0px; */
}

#temp2 .sec {
    margin: 30px 25px;
}
#temp2 ul.conlink li {
    margin-bottom: 18px;
}
#temp2 input.form-control {
    border: 1px solid #ccc;
    padding: 15px 11px;
    border-radius: 24px;
    font-weight: bold;
    color: #8e7e7e !important;
}

#temp2 textarea.form-control{
    border: 1px solid #ccc;
    padding: 15px 11px;
    border-radius: 24px;
    color: #ccc;
}
#temp2 select.form-select {
    border: 1px solid #ccc;
    padding: 11px 11px;
    border-radius: 24px;
    /* font-weight: bold; */
    color: #8e7e7e;
}



#temp2 button.btn.btnmakeappitmnets {
    display: block;
    width: 100%;
    background: #343c99;
    padding: 15px 0px;
    border-radius: 28px;
    color: #fff;
    font-size: 13px;
}
/* comman css */

#temp2 .testibox {
    text-align: center;
}

#temp2 .testimoniyalbox.sec {
    background: #f8f8f8;
    color: #000;
    padding-bottom: 20px;
  }
  #temp2 .sectitle.testimonial{
    padding: 20px;
  }
  #temp2 .testimonialimg {
    border: 5px solid #ffc708;
  }
  #temp2 .testimonialcontent {
    display: flex;
    margin: 10px 25px;
    align-items: flex-start;
  }
  
  #temp2 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
  }
  
  #temp2 .testiprofile {
    width: 170px;
    height: 170px;
  }
  
  #temp2 .testimonialname {
    font-size: 20px;
    text-align: left;
    text-transform: capitalize;
  }
  #temp2 .testimonialdescription {
    font-size: 15px;
    text-align: justify;
    line-height: 22px;
    padding-top: 5px;
  }
  #temp2 .testibox {
    text-align: center;
    width: 460px;
  }
  #temp2 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
  }
  #temp2 .testimonials {
    display: flex;
    width: max-content;
  }
  #temp2 .testiimg {
    min-width: 90px;
    max-width: 90px;
    max-height: 90px;
  }
  #temp2 .clintname {
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    align-items: flex-start;
}
  #temp2 .testimonialcontent img {
    border: 3px solid #343c99 ;
    margin: 0 !important;
    border-radius: 50px;
  }
  #temp2 .sectitle.testimonial {
    /* background-color: #fff; */
    color: #000;
  }

#temp2 h1, h2, h3, h4, h5, h6, p{ margin: 0px;}
#temp2 .main-temaplt ul{ list-style-type: none; padding: 0px;}
#temp2 ul {
    margin: 0px;
}
#temp2 .main-temaplt a{ text-decoration: none; color: #000;}
#temp2 .logo-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
#temp2 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}
#temp2 ul.startaring {
    margin-top: 16px;
}
#temp2 img.mx-auto.d-block.img-fluid.coverimghere {
    width: 500px;
    height: 300px;
}

#temp2 .wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
}
#temp2 ul.socil-link {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
#temp2 ul.timelist li {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    background: #BFC7DC1F;

}
#temp2 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}
#temp2 ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
}
#temp2 article.fixedfooter {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    background: transparent;
    margin: 10px;
}
#temp2 .personalinfo.modal-info {
    text-align: center;
}
#temp2 .fixedd {
    position: fixed;
    bottom: 0px;
    width: inherit;
    background: #e5f7ff;
    transform: translate(0%, 0px);
    padding: 0;
    box-shadow: 0px 0px 8px 1px #00000010;
    z-index: 98;
}

#temp2 .addtocinatct {
    background: #343c99;
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px;
}
#temp2 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp2 .footerleft {
    background: #343c99;
    text-align: center;
    line-height: 35px;
    color: #fff;
    border-radius: 50%;
}

#temp2 img.mx-auto.d-block.img-fluid.logo-profile {
    position: absolute;
    top: -82px;
    left: 0px;
    right: 0px;
}

#temp2 .innerinfo {
    margin-top: 91px;
    background: #fff;
    padding: 36px;
    padding-top: 81px;
    box-shadow: 0px 4px 10px 0px #0000001A;
    border-radius: 16px;
}

#temp2 .qrcodeshaere {
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
}
#temp2 .addtocinatct {
    cursor: pointer;
}

@media (max-width: 480px){
    #temp2 .fixedd{ width: 100vw;}
    #temp2 .coverimghere {
    width: 100% !IMPORTANT;
}
#temp2  ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
}
#temp2 .wtemplate {
    padding: 0px;
}

}

#temp2 .modal-content{
    width: 80%;
    border-radius: 20px;
  } 
  #temp2 .personalinfo.modal-info{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  #temp2 .personalinfo.modal-info h3{
    padding-top: 10px;
    font-size: 22px;
  }
  #temp2 .personalinfo.modal-info p {
    font-size: 16px;
  }
  #temp2 .personalinfo canvas{
    width: 260px !important;
    height: 260px !important;
  }
  #temp2 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }

  #temp2 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
  }
  #temp2 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
  }
  #temp2 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
  }
  #temp2 .carousel-btn {
    background-color: #343c99;
    border: 0;
    color: #ffffff;
    border-radius: 50%;
    font-size: 18px;
    margin: 0 10px 0 0px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #temp2 .carousel-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 110px;
  }

  #temp2 .twoimages{
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  #temp2 .skeleton-blog{
    margin-bottom: 2em;
    animation: scale 4s infinite;
   }
  
   #temp2 .skeleton-footer{
    height: 47px;
    width: 100%;
    border-radius: 24px;
    background: #E5E4E2;
    margin-top: 10px;
   }

   @media (max-width:520px){
    #temp2 .fixedd {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        background: #e5f7ff;
        transform: translate(0%, 0px);
        padding: 0;
        box-shadow: 0px 0px 8px 1px #00000010;
        z-index: 98;
    }
   }

#temp2 .qr-logo-image{
   
    width:80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
  margin: 0 auto;
  border: 4px solid #343C99;
  background: white;
  }
#temp2  canvas{
  border-radius: 20px;
  }
#temp2  .canvas-container{
    display: flex;
    justify-content: center;
  }

  #temp2 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}
#temp2 .calanderIcon{
    position: absolute;
    top: 15px;
    right: 12px;
    cursor: pointer;
  }



#temp2 .extmakeappointmentsubmitbtn{
  background: #343C99 ;
  width: 100% !important;
  color: #fff ;
  border-radius: 20px !important;
  padding: 12px 0 !important;
  font-size: 18px !important;
  border: none !important;
}

#temp2 .reviewusongoogle{
  padding: 0px !important;
}

@-moz-document url-prefix() {
  .firefox-hide {
      display: none !important;
  }
}


#temp2 .reviewratingcontainer{

}

#temp2 .reviewrating{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial;
  font-weight: 400;
  font-size: 13px;
  color: #2F3438;
 
}

#temp2 .ratingspan{
  font-family: Arial;
  font-weight: bold;
  font-size: 16px;
  color: #343434;
  padding-right: 10px;
}