@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel:wght@400..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel:wght@400..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap');
/* templat css */
/* #temp7 .main-temaplt{  font-family: 'lato', sans-serif !important;} */
#temp28 .wtemplate {
    background: #fff;
    font-family: "Roboto", sans-serif;
}

#temp28 ul.gallerylist li {
    margin: 4px 4px;
    width: 28%;
    border-radius: 18px;
}

#temp28 ul.gallerylist li img {
    border-radius: 16px;
}

#temp28 h3.sectitle {
    font-family: "Libre Baskerville", sans-serif;
    font-size: 20px;
    margin-bottom: 15px;
    color: #1E1E1E;
    font-weight: 400;
    text-align: center ;
    line-height: 24px;
    text-transform: capitalize;
}
#temp28 .makeappitmneets  h3.sectitle {
   text-align: center;
}

#temp28 .servesbox {
    row-gap: 10px;
    padding: 0;   
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-gap: 0px; */
    list-style-type: none;
}






#temp28 p.para {
    font-family: "saira condensed", sans-serif;
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
    color: #202020;

    word-break: break-word;
    text-align: start;
}

#temp28 .sec { 
    margin: 0 18px 25px 18px;
    padding: 20px 25px;
   margin-bottom: 24px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 4px 24px 0px #0000001F;
}



#temp28 .fixedfooter.sec{
    background: #1C85E7;
    box-shadow: none;
    padding: 15px 0;
}
 #temp28 .imgsechere.sec,
#temp28 .businessHours.sec, #temp28 .makeappitmneets.sec {
    /* box-shadow: none; */
    /* padding: 15px 13px 0 13px; */
    /* padding: 15px 0px 0 0px; */
}

#temp28 .testimoniyalbox.sec {
    /* background: #f8f8f8; */
    background: transparent;
    color: #000;
    box-shadow: none;
    margin: 0;
    padding: 0;
}
#temp28  .testimonials-wrapper{
    position: relative;
    /* box-shadow: 0px 2px 5px 0px #00000059; */
    background: #fff;
    /* margin-bottom: 30px; */
    /* padding: 15px 31px; */
}
#temp28  .testimonials-wrapper::after{
    position: absolute;
    content:'';
    left:0;
    top:0;
    height: 100%;
    width:80px;
    background-color: #37686C;
    z-index: 0;
}
/* #temp28 .servicesName.sec {
    padding: 30px 30px 0;
} */

#temp28 article.abouttemplate.sec {
    text-align: center;
}

#temp28 h3.username {  
    color: #fff;
    margin: 0 35px;
    background: #37686C;
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
    font-family: "Libre Baskerville";
    word-break: break-word;
        padding: 10px;
}

#temp28 input.form-control {
    border-radius: 4px;
    background-color: #F1F1F1;  
    /* color: #8C8C8C; */
    padding: 15px 15px;
    box-shadow: none;
    font-family: "Saira Condensed";
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #f1f1f1;


}

#temp28 .contact-form {
    ::placeholder{font-family: "Saira Condensed";
        font-weight: 400;
        font-size: 16px;

    }
}
#temp28 .contact-form input:focus ,#temp28 .contact-form textarea:focus  {
    border: 0.5px solid #BFBFBF
}


#temp28  .makeappitmneets h5{
    display: none;

}

#temp28 input.form-control::placeholder {
    color: #202020 !important;
}

#temp28 textarea.form-control::placeholder {
    color: #202020 !important;
}

#temp28 textarea.form-control {
    border-radius: 4px;
    background-color:#F1F1F1;
    box-shadow:none;
    font-family: "Saira Condensed";
    font-weight: 400;
    font-size: 16px;
    padding: 15px 15px;
    resize: none;
    border: 1px solid #f1f1f1;
    

}


#temp28 select.form-select {  

    font-family: 'Saira Condensed', sans-serif;
border-radius: 4px;
   
    background-color: #f1f1f1;
    color: #121212;
    padding: 15px 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
    background-image: url(../../../../Images/theme-18-selecticon.png);
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 15px;
    /* box-shadow: 0px 2px 5px 0px #0000004D; */
}

#temp28 button.btn.btn-submitform {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgb(0 0 0), rgb(0 0 0)); */
    font-family: "Libre Baskerville", sans-serif;
    background: #37686C;
    width: 100%;
    color: #fff;
    border-radius: 4px;
    padding: 15px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-top: 10px;
}

#temp28 ul.socil-link li {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: flex; 
    font-size: 22px;   
    background: #F1F1F1;     
    box-shadow: 0px 4px 10px 0px #0000000D;
    align-items: center;
    justify-content: center;  
    >a >i{
       color: #37686C
    }  
}

#temp28 article.imgsechere.sec {
    position: relative;
    width: 100%;
    height: auto;  
    border-radius: 0;   
}
/* 
#temp28 article.imgsechere.sec::after{
    content: '';
    position: absolute;
    left:0;
    top:0;
    width:100%;
    background-color: #1C85E7;
    height:65%;
} */
#temp28 .imgsechere-top{
    padding: 12px 18px 0 18px;
    position: relative;
    z-index: 6;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
#temp28  .imgsechere-main-banner{
    height: 420px;
    width:100%;
    overflow: hidden;
    /* box-sizing: border-box;
    padding: 60px 28px 20px 28px; */

}
#temp28  .imgsechere-top-left{
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 700;
    color:#fff;
    line-height: 20px;
    align-items: center;
}


#temp28 article.qrcode-open {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp28 .logo-profile {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 177px;
    z-index: 2;
    /* border: 1px solid #1BBFA2; */
}
#temp28 .bannerb2{
    height: 630px;
}
#temp28 .mainsection{margin-top: -40px;
    background-position-x: center;
background-image: url('../../../../Images/theme-28-bg.png');
background-repeat: no-repeat;
background-size: cover;
border-radius: 40px 40px 0 0 ;
z-index: 1;
position: absolute;
max-width: 430px;
width: 100%;
padding-top: 56px;
padding-bottom: 80px;
margin-bottom: 20px;

}
#temp28 .widthsec {
    max-width: 400px;
}


#temp28 .logoext {
bottom: 88px;
}



#temp28 article.personalinfo {
    font-family: "Poppins", sans-serif;
    /* margin-top: -40px; */
    position: relative;  
    text-align: center;
    color:#1E1E1E;
    margin-bottom: 30px;
}

#temp28 button.btn.btnmakeappitmnets {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background-color: #37686C;
    width: 100%;
    color: #fff;  
    padding: 15px 0;
    font-size: 16px;
    font-weight: 400;
    font-family: "Libre Baskerville";

}

#temp28 .timelist {
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
}

#temp28 ul.timelist li .day {
    /* margin-right: 28px; */
    font-size: 16px;
    font-weight: 400;
    font-family: 'Saira Condensed';
    /* width: 27%; */
    padding: 0 10px;
    color: #1E1E1E;
}

#temp28 ul.servesbox li {
    position: relative;
}

#temp28 ul.conlink li i { 
    text-align: center;
    color: #202020;
    /* width: 30px; */
    /* height: 30px; */
    /* background: transparent; */
    /* border-radius: 50px; */
    /* box-shadow: 0px 4px 10px 0px #0000000D; */
    display: flex;
    align-items: center;
    justify-content: center;
    


}

#temp28 ul.conlink li span {
    word-break: break-word;
    font-family: 'Saira Condensed';
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #1E1E1E;
}

#temp28 ul.conlink li {
   margin-bottom: 16px;
}
#temp28 ul.conlink li:last-child {
    margin-bottom: 0;
    text-align: center;
 }
#temp28 ul.conlink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

#temp28 ul.conlink li a {
    display: flex;
   /* word-break: break-word; */
    align-items: baseline;
    justify-content: center;
    column-gap: 10px;
}

#temp28 ul.conlink .breakText {

word-break: break-word;
}




#temp28 p.titleuser { 
    margin: 0;
    word-break: break-word;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    font-family: "Libre Baskerville";
  margin-top: 15px;
  margin-bottom: 33px;
}

#temp28 article.businesshour.sec h3 {
    text-align: center;
}

#temp28 ul.servesbox li {
    position: relative;
}

#temp28 ul.servesbox li:before {
    content: "";
    width: 8px;
    height: 8px;
    background: #37686C;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: 4px;
    top: 7px;
}

#temp28 ul.servesbox li {
    font-family: "saira condensed", sans-serif;
    display: flex;
    justify-content: space-between;     
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1E1E1E;   
    position: relative;   
    padding-left: 21px;
    /* padding: 8px 15px;
    background: #f1f1f1; */
}
/* #temp28 ul.servesbox li:after{
    content:'';
    position: absolute;
    left:0;
    width:13px;
    height:11px;
    background: url('../../../../Images/theme18-list-icon.png');
    background-repeat: no-repeat;
    top:14px;
   
} */

#temp28 ul.timelist li {
    position: relative;
}

#temp28 ul.timelist li:before {
    content: "\f017";
    font-family: 'FontAwesome';
 
    color: rgba(106, 106, 106, 0.5);
    /* margin-right: 11px; */
    position: absolute;
    right: 20px;
    font-size: 24px;
}

#temp28 .time {
    font-weight: 400;
    font-size: 16px;  
    font-family: 'Saira Condensed';
}

#temp28 .testimonialimg {
    border: 5px solid #ffc708;
}

#temp28 .testimonialcontent {
    display: flex;
    margin: 10px 0px;
    align-items: flex-start;
}

#temp28 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
}

#temp28 .testiprofile {
    width: 170px;
    height: 170px;
}

#temp28 .testimonialname {
    font-family: "Poppins", sans-serif;
    color: #1E1E1E;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

#temp28 .testimonialdescription {
    font-family: "saira condensed";
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    /* line-height: 22px; */
  
    font-size: 16px;
    color: #1E1E1E;
    /* width: 70%; */
}

#temp28 .testibox {
    text-align: center;
    width: 480px;
    @media (max-width:500px){
        max-width: 380px;
    }
    @media (max-width:400px){
        max-width: 330px;
    }
}

#temp28 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
    position: relative;
    z-index: 99;
}

#temp28 .testimonials {
    display: flex;
    width: max-content;
    /* width: 200% !important; */

}

#temp28 .testiimg {
    width: 90px;
    /* height: 90px;
    border-radius: 50px;
    border: 3px solid #1C85E7;
    flex: 0 0 auto; */
}

#temp28 .testimonialcontent img {
    box-sizing: border-box;
    display: block;
    width: 90px;
    height: 90px;
    margin: 0 !important;
    border-radius: 50px;
    border: 3px solid #37686C;
}

#temp28 .sectitle.testimonial {
    /* color: #000; */
    color: #1E1E1E;
    /* margin-left: 31px; */
}

#temp28 .testibox {
    text-align: justify;
    position: relative;
}

#temp28 label.form-label {
    color: #FF9635 !important;
    font-size: 14px;
}

#temp28 .clintname {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    width: 260px;
}


/* comman css */

#temp28 .testibox {
    text-align: center;
}



#temp28 h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
}

#temp28 .conatctform{
    padding-top: 24px;
    margin-top: 40px;
}


#temp28 .main-temaplt ul {
    list-style-type: none;
    padding: 0px;
}

#temp28 ul {
    margin: 0px;
}

#temp28 .main-temaplt a {
    text-decoration: none;
    color: #000;
}

#temp28 .logo-profile {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    margin: 0  auto !important;
}

#theme18 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}

ul.startaring {
    margin-top: 16px;
}

#temp28 img.mx-auto.d-block.img-fluid.coverimghere {
    object-fit: cover;
    height: 100%;
    width: 100%;

    /* position: relative;
    z-index: 5;
    border-radius: 117px;
    box-shadow: 0px -6px 44px 0px #00000040; */
}


.wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
}

#temp28 ul.socil-link {
    display: flex;  
    justify-content: center;
    flex-wrap: wrap;    
    column-gap: 24px;
    row-gap: 10px;
}
#temp28 ul.socil-link a {
    color:#07080A;
    margin-top: 3px;
}
#temp28 ul.socil-link-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;   
   column-gap: 24px;
   row-gap: 15px;
    color: white;
}

/* #temp28 ul.socil-link-inner li a {
    color: transparent;

} */

#temp28 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}

#temp28 ul.timelist li {

    display: flex;
    margin-bottom: 14px;
    align-items: center;
    border-radius: 32px;
    /* border: 0.5px solid rgba(255, 255, 255, 0.33); */
    background: #fff;
    color: #1E1E1E;
    backdrop-filter: blur(40px);
    padding: 15px 20px;
    box-shadow: 0px 2px 5px 0px #0000004D;
    /* border-radius: 0;    */
}

#temp28 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}

#temp28 ul.gallerylist li {
    margin: 4px 4px;
}

#temp28 article.fixedfooter {
    display: flex;
    justify-content: space-around;
    /* padding: 0 20px;
    align-items: center;
    background: transparent; */
    margin: 0px;
    position: relative;
}

@media(max-width: 380px){
    #temp28 article.fixedfooter{
        justify-content: space-between;
    }
}

#temp28 .personalinfo.modal-info {
    text-align: center;
}

#temp28 .fixedd {
    position: fixed;
    bottom: 0px;
    width: inherit;
    background: #37686C;
    transform: translate(0%, 0px);
    padding: 12px 0;
    /* box-shadow: 0px 0px 18px 5px #0000000d; */
    z-index: 98;
}

#temp28 .addtocinatct {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px; */
    background: transparent;
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: column-reverse;
    /* flex-direction: column; */
    align-items: center;
}

#temp28 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp28 .footerleft {
    position: absolute;
    display: flex;
    top: -45px;  
    width: 85px;
    height: 85px;
    background:#ffffff ;   
    text-align: center;
    line-height: 35px;
    color: #000000;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    /* border: 5px solid #fff;    */
    /* box-shadow: 0px 0px 9px 2px #000; */
}

@media(max-width: 380px){
    #temp28 .footerleft {
        left: 141px;
    }
}
@media(max-width: 340px){
    #temp28 .footerleft {
        left: 115px;
    }
}
@media(max-width: 315px){
    #temp28 .footerleft {
        left: 100px;
    }
}

#temp28 .qrcodeshaere {
    cursor: pointer; 
    font-size: 40px;
    color:#37686C;
    /* cursor: pointer; */
    /* width: 60px; */
    /* height: 60px; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
   
    /* border-radius: 100px; */
    /* padding: 40px;*/
}

#temp28 .addtocinatct {
    cursor: pointer;

}

#temp28 .addtocinatct .icon11 {
    margin-bottom: 5px;
    font-size: 20px;
}



@media (max-width: 480px) {
    .fixedd {
        width: 100vw;
    }

    .coverimghere {
        width: 100% !IMPORTANT;
    }

    ul.gallerylist li {
        margin: 4px 4px;
        width: 46%;
    }

    .wtemplate {
        padding: 0px;
    }

    #temp28 .sec {
        /* background: #F8F8F8; */
        border-radius: 14px;
        /* padding: 20px; */
        margin-bottom: 18px;
        margin: 21px 9px;
    }



}

#temp28 .sec-common{
    margin: 0 18px 25px 18px;
}

#temp28 .modal-content {
    width: 80%;
    border-radius: 20px;
}

#temp28 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp28 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp28 .personalinfo.modal-info p {
    font-size: 16px;
}

#temp28 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp28 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

#temp28 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
}

#temp28 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
}

#temp28 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
}

#temp28 .carousel-btn {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
        color: #1E1E1E;  
    font-size: 16px;
    margin: 0 10px 0 0px;
    width: 10px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background: #fff;
    border-radius: 0;
}

#temp28 .carousel-container {
    display: flex;
    justify-content: center;
    /* padding-left: 110px; */
}

#temp28 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp28 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp28 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp28 .twoimages {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    gap: 10px;
    margin:0 10px 10px 10px;

    /* height: 110px !important; */
}

#temp28 .skeleton-blog {
    margin-bottom: 2em;
    animation: scale 4s infinite;
}

#temp28 .skeleton-footer {
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
}

@media (max-width:400px) {
    #temp5 .footerleft {
        width: 80px;
        height: 80px;
        border: 4px solid #24292ce0;
    }

    #temp5 .qrcodeshaere {
        font-size: 40px;
    }
}

@media (max-width:520px) {
    #temp28 .fixedd {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        /* background: #fcfbfb; */
        transform: translate(0%, 0px);
        padding: 0;
        box-shadow: 0px 0px 18px 5px #0000000d;
        z-index: 98;
    }
}

#temp28 .qr-logo-image {
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width: 80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
    margin: 0 auto;
}

#temp28 canvas {
    border-radius: 20px;
}

#temp28 .canvas-container {
    display: flex;
    justify-content: center;
}

#temp28  .logoTextContainer {
    padding: 20px 0;
    margin: 30px;

}

#temp28 .calanderIcon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

#temp28 .extmakeappointmentform{
    background-color: #fff !important;
    box-shadow: 0px 2px 5px 0px #0000004D;
    border-radius: 4 !important;
    color: #1c1a1a !important;
    border-color: #cbcbcb;
}

#temp28 .extmakeappointmentsubmitbtn{
    background: #000 ;
    width: 100% !important;
    color: #fff ;
    border-radius: 4 !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 9px;
  }

  #temp28 .extmakeappointmentformplaceholder input::placeholder {
    color: #a4a4a4 !important;
}




#temp28 .gallery img{
    border-radius: 0 !important;
}
#temp28 .theme18-gallery-wrapper{
    padding-bottom: 30px;
    position: relative;
}
#temp28 .theme18-gallery-inner-wrapper{
    position: absolute;
  bottom: 0px;
    right: 0px;
    left: 0;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    justify-content: center;
}
#temp28 .theme18-gallery-inner-wrapper img{
    cursor: pointer;
}

/* #temp28 .inputfield_defaulticon{
    
} */
/* input[type="date"] {
    -webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../../../Images/calendarIcondays.png');
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 20px;
    color: white !important;
    position: absolute;
    top: 20px;
  }

  input[type="date"]:focus {
    outline: none; 
    border-color: blue; 
  } */


 