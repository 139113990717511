.Influencerproductname.basePrice span{
  font-weight: 600;
}
.Influencerproductname.listingPrice span{
  font-weight: 600;
  font-size: 22px;
}
.Influencerproductname.listingPrice ,.Influencerproductname.basePrice{
  height: auto !important;
}
.percentageSelectBox{
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    min-height: 38px;
    align-content: center;
    padding: 0 6px;
    text-align: center;
}
.percentageSelectBox Select{
    width: 50px;
    margin-left: 6px;
}
.selectedPercentageText{
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
    text-align: center;
    font-size: 16px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.basePrice{
    display: flex;
    margin: 0;
}
.listingPrice{
    display: flex;
}
.tooltip-wrapperBp {
  position: relative;
  display: inline-block;
  height: 40px;
  align-content: center;
  border: none;
}
.tooltip-wrapperLp {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  height: 40px;
  align-content: center;
}

.tooltip-target-detail {
  position: relative;
  cursor: pointer;
}
.tooltip-target-detail-product {
  position: relative;
  cursor: pointer;
  display: flex;
  font-size: 12.25px;
  font-family: Arial, sans-serif;
  color: #666666;
  font-weight: 600;
  align-items: center;
}

.tooltip-detail {
  visibility: hidden;
  width: 190px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 8px;
  position: absolute;
  top: -70%; /* Position above the text */
  left: 10%;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}
.tooltip-detail-table {
  visibility: hidden;
  width: 190px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 8px;
  position: absolute;
  top: -70%; /* Position above the text */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}
.tooltip-detailLp {
  visibility: hidden;
  width: 190px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 8px;
  position: absolute;
  top: -70%; /* Position above the text */
  left: 8%;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip-target-detail:hover + .tooltip-detail {
  visibility: visible;
  opacity: 1;
}
.tooltip-target-detail-product:hover + .tooltip-detail-table {
  visibility: visible;
  opacity: 1;
}
.tooltip-target-detail:hover + .tooltip-detailLp{
  visibility: visible;
  opacity: 1;
}

.tooltip-detail::after {
  content: "";
  position: absolute;
  top: 100%; /* Arrow pointing down */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}
.tooltip-detail-table::after {
  content: "";
  position: absolute;
  top: 100%; /* Arrow pointing down */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}
.tooltip-detailLp::after {
  content: "";
  position: absolute;
  top: 100%; /* Arrow pointing down */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltipimgDetail{
  width: 6px;
  margin: 0 8px;
}

.tooltipcontainer_detail{
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-family: OpenSans,sans-serif;
  font-weight: 400;
  text-align: center;
}

/* influencerDash */
.influencersdetails-topdetails :first-child{
  flex-wrap: wrap;
}