.progress-bar-container {
    margin: 20px 0;
    position: sticky;
    top: 119px;
    border-radius: 10px;
    background-color: #fff;
    z-index: 999;
    padding: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  
  .progress-bar {
    width: 100%;
    background-color: #e0e0df;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
  }
  
  .progress-bar-fill {
    height: 15px;
    background-color: #535dde;
    border-radius: 25px;
    transition: width 0.5s ease-in-out;
  }
  
  .progress-bar-text {
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
    
  }
  