#error_msg{
    color: red;
    font-size: 12px;
    /* position: absolute; */
}
#input_container{
    position: relative;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.login-slides{
  background-image: url(../Images/login-slides.png);
}

.login-23 .bg-color-23 {
  /* background: url("../Images/bgblue.png") top left repeat; */
  background-size: cover !important;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  z-index: 999;
  opacity: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
}

.login-23 h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Jost', sans-serif;
}

.login-23 .form-section{
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
  background: #fff;
  padding: 50px;
  border-radius: 30px;
}

.login-23 .form-box{
  width: 100%;
}

.login-23 .login-inner-form .form-group {
  margin-bottom: 25px;
}

.login-23 .login-inner-form .form-box {
  float: left;
  width: 100%;
  position: relative;
}

.login-23 .login-inner-form .form-control {
  font-size: 15px;
  outline: none;
  color: #535353;
  border-radius: 3px;
  border: 1px solid #dbdbdb;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.login-23 .login-inner-form img {
  margin-bottom: 5px;
  height: 40px;
}

.login-23 .login-inner-form .form-box .form-control {
  float: left;
  width: 100%;
  padding: 13px 15px 13px 65px;
  border: 0;
}

.login-23 .login-inner-form .form-box i {
  position: absolute;
  left: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #053f72;
  border-radius: 3px 0 0 3px;
  font-size: 20px;
  color: #fff;
}

.login-23 .info {
  color: #fff;
  margin: 0 117px 0 auto;
  text-align: right;
  max-width: 700px;
}

.login-23 .bg-img {
  top: 0;
  bottom: 0;
  /* z-index: 999; */
  min-height: 100vh;
  position: relative;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.login-23 .waviy {
  position: relative;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #055ba5;
  text-transform: capitalize;
  letter-spacing: 8px;
}

.login-23 .waviy span {
  position: relative;
  display: inline-block;
  color: #055ba6;
  text-transform: uppercase;
  /* animation: waviy 1s infinite; */
  animation-delay: calc(.1s * var(--i));
  font-family: 'Jost', sans-serif;
}

.login-23 .waviy span.color-yellow{
  color: #053f72;
}

@keyframes waviy {
  0%,40%,100% {
      transform: translateY(0)
  }
  20% {
      transform: translateY(-20px)
  }
}

.login-23 .login-inner-form .form-label{
  margin-bottom: 0px;
  color: #535353;
  float: left!important;
  font-size: 14px;
  padding-bottom: 2px;
}

.login-23 .info P{
  color: #535353;
  font-size: 17px;
  line-height: 30px;
  margin-bottom: 0;
}

.login-23 .login-inner-form .forgot{
  margin: 0;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
  float: right;
}

.login-23 .btn-theme {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  border: none;
  outline: none !important;
  color: #ffffff;
  text-transform: capitalize;
  transition: all 0.3s linear;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  font-size: 17px;
  font-weight: 400;
  width: 100%;
  font-family: 'Jost', sans-serif;
  border-radius: 3px;
}

.login-23 .login-inner-form p{
  margin: 0;
  color: #e7e7e7;
}

.login-23 .login-inner-form p a{
  color: #e7e7e7;
}

.login-46 .login-inner-form .btn-theme.focus, .btn-theme:focus {
  box-shadow: none;
}

.login-23 .logo{
  text-align: center;
  margin-bottom: 15px;
}

.login-23 .logo img{
  height: 25px;
}

.login-23 h3{
  text-align: center;
  margin: 0 0 24px;
  font-size: 22px;
  color: #053f72;
  font-weight: 500;
  
  font-weight: bold;
}

.login-23 .form-check-input:checked {
  background-color: #053f72!important;
  border-color: #053f72!important;
}

.login-23 .login-inner-form .social-list{
  padding: 0;
  text-align: center;
}

.login-23 .login-inner-form .social-list li {
  display: inline-block;
}

.login-23 .login-inner-form .social-list li a {
  margin: 1px;
  font-size: 15px;
  font-weight: 400;
  width: 120px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  color: #fff;
}

.login-23 .login-inner-form .social-list li a:hover{
  text-decoration: none;
}

.login-23 .login-inner-form .extra-login {
  float: left;
  width: 100%;
  margin: 25px 0;
  text-align: center;
  position: relative;
}

.login-23 .login-inner-form .extra-login::before {
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
  height: 1px;
  background: #23396726;
  content: "";
}

.login-23 .login-inner-form .extra-login > span {
  width: auto;
  float: none;
  display: inline-block;
  background: #fff;
  padding: 1px 20px;
  z-index: 1;
  position: relative;
  font-size: 15px;
  color: #535353;
  text-transform: capitalize;
}

.login-23 .login-inner-form .terms{
  margin-left: 3px;
}

.login-23 .login-inner-form .checkbox {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 15px;
}

.login-23 .login-inner-form .form-check{
  float: left;
  margin-bottom: 0;
}

.login-23 .login-inner-form .form-check a {
  color: #535353;
  float: right;
}

.login-23 .login-inner-form .form-check-label {
  padding-left: 5px;
  margin-bottom: 0;
  font-size: 16px;
  color: #535353;
}

.login-23 .btn{
  box-shadow: none!important;
}

.login-23 .btn-lg{
  padding: 5px 50px;
  /* line-height: 50px; */
}

.login-23 .btn-md{
  padding: 0 50px;
  line-height: 45px;
}
input:-webkit-autofill {
  background-color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-background-color: #fff !important;
}


.login-23 .btn-primary{
  background: #053f72;
  margin-top: 10px;
}

.login-23 .btn-primary:hover {
  background: #053f72;
}

.login-23 .form-section a {
  text-decoration: none;
}

.login-23 .form-section .form-check-input {
  width: 18px !important;
  height: 18px !important;
  margin-top: 1px;
  position: absolute;
  border: 1px solid #dbdbdb;
  border-radius: 0;
  background-color: #fff;
  margin-left: -22px;
}

.login-23 .login-inner-form .checkbox a {
  font-size: 14px;
  font-weight: 600;
  color: #0861c1 !important;
}

.login-23 .form-section p {
  margin: 0;
  font-size: 14px;
  color: #535353;
  padding-top: 5px;
  font-weight: 500;
  margin-top: 5px;
}
.registerhere{
  font-size: 14px;
}

.login-23 .form-section p a {
  color: #0861c1 !important;
  font-weight: 600;
}

.login-23 .facebook-bg {
  background: #4867aa;
}

.login-23 .facebook-bg:hover {
  background: #3b589e;
  color: #fff;
}

.login-23 .twitter-bg {
  background: #33CCFF;
}

.login-23 .twitter-bg:hover {
  background: #56d7fe;
}

.login-23 .google-bg {
  background: #db4437;
}

.login-23 .google-bg:hover {
  background: #dc4e41;
}


@media (max-width: 1200px) {
  .login-23 .info {
      margin: 0;
  }

  .login-23 .waviy {
      font-size: 33px;
  }
}

@media (max-width: 992px) {
  .login-23 .bg-img{
      display: none;
  }

  .login-23 .login-inner-form .social-list li a {
      width: 100px;
      height: 35px;
      line-height: 35px;
      font-size: 14px;
  }

  .login-23 .bg-color-23 {
      padding: 30px 15px;
  }

  .login-23 .form-section {
      padding: 50px 30px;
  }
}


/* nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  display: none !important;
}
footer {
  display: none;
} */


.slick-prev:before, .slick-next:before{

  color: #303030 !important;
  font-size: 30px !important;
}

.slick-next:before{
  margin-left: -25px !important;
}

.slick-prev:before{
  margin-left: -4px !important;
}

.login-23 .Eeye {
  position: static !important;
  left: auto !important;
  width: auto !important;
  height: auto !important;
  line-height: normal !important;
  text-align: left !important;
  background: none !important;
  border-radius: 0 !important;
  font-size: initial !important;
  color: #6a6565 !important;
}

/* //212 19:30 */



.registerCountarycodestyle{
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}


.register_countryinput .flag-dropdown {
  border: none !important;
    background: white !important;
    border-right: 1px solid #d0d0d0ba !important;
}
.recaptch_styled{
  width: 200px !important;
}


/* @media (max-width: 360px) {
  .register .recaptch_styled .rc-anchor-light.rc-anchor-normal,
  .register .recaptch_styled .rc-anchor-light.rc-anchor-compact {
    width: 250px !important;
  }
} */
