@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

#template11 .coverimghere {
    display: none !IMPORTANT;
}


#template11 article.personalinfo.sec {
    box-shadow: 0px 4px 10px 0px #0000001A;
    background: #fff;
    border-radius: 17px;
    margin: 28px 18px;
    padding-top: 61px;
    margin-top: 100px;
    text-align: center;
    padding-bottom: 10px;
}
#template11 h3.username {
    font-size: 30px;
    color: #343c99;
}
#template11 p.titleuser {
    font-weight: 600;
}
#template11 ul.socil-link li a {
    color: #343c99;
    font-size: 24px;
}



#template11 h3.sectitle {
    color: #343C99;
    text-align: center;
    font-size: 15px;
}
#template11 .sec {
    margin: 32px 0px;
}
#template11 .wtemplate {
    background: #E5F7FF;
    position: relative;
}

#template11 ul.socil-link {
    justify-content: center;
}
#template11 ul.socil-link li {
    font-size: 29px;
    margin: 7px 9px;
}

#template11 article.social-links-sec.sec h3 {
    display: none;
}
#template11 .day {
    background: #343C99;
    color: #fff;
    width: 24%;
    text-align: center;
    padding: 12px;
    border-radius: 30px;
    margin-right: 54px;
    font-weight: bold;
    font-size: 12px;
    font-weight: 500;
}

#template11 .time {
    margin-top: 11px;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter', sans-serif !important;
}
#template11 ul.businessTime li {
    background: #bfc7dc40;
    color: #343c99;
    border-radius: 20px;
}

#template11 ul.conlink {
    background: linear-gradient(0deg, rgba(191, 199, 220, 0.12), rgba(191, 199, 220, 0.12)), linear-gradient(88.78deg, rgba(218, 90, 250, 0.6) -34.1%, rgba(53, 112, 236, 0.6) 113.16%);
    padding: 31px 0px;
    text-align: center;
    border-radius: 19px;
}
#template11 ul.conlink a {
    color: #fff;
    font-size: 16px;
    text-decoration: none;
}

#template11 ul.conlink li i {
    margin-right: 9px;
}

#template11 article.abouttemplate.sec p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    font-family: 'Inter', sans-serif !important;
    color: #3D3D3D;
}

#template11 button.btn.btn-submitform {
    display: block;
    width: 100%;
    background: #343c99;
    border-radius: 28px;
    padding: 15px 0px;
    font-size: 13px;
}

#template11 ul.servesbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
}
#template11 ul.servesbox li {

    background: linear-gradient(88.78deg, rgba(218, 90, 250, 0.6) -34.1%, rgba(53, 112, 236, 0.6) 113.16%);
    color: #fff;
    padding: 4px 18px;
    margin: 10px 0px;
    border-radius: 22px;
    font-size: 11px;
    font-weight: 400;
}

#template11 .fixedd {
    background: #e5f7ff;
}

#template11 article.fixedfooter.sec {
    margin: 0px;
}

#template11 .sec {
    margin: 31px 14px !IMPORTANT;
}

#template11 input.form-control {
    border: 1px solid #ccc;
    padding: 15px 11px;
    border-radius: 24px;
    font-weight: bold;
    color: #ccc;
}

#template11 textarea.form-control{
    border: 1px solid #ccc;
    padding: 15px 11px;
    border-radius: 24px;
    font-weight: bold;
    color: #ccc;
}
#template11 select.form-select{
    border: 1px solid #ccc;
    padding: 15px 11px;
    border-radius: 24px;
    font-weight: bold;
    color: #ccc;
}

#template11 button.btn.btnmakeappitmnets {
    display: block;
    width: 100%;
    background: #343c99;
    padding: 15px 0px;
    border-radius: 28px;
    color: #fff;
    font-size: 13px;
}
.movileview .wtemplate{    padding: 0px;}














@media (max-width: 480px){
    .fixedd {
        width: 100%;
        transform: translate(0px, 0px);
    
    }
}

article.imgsechere.sec {
    margin: 0px !IMPORTANT;
    padding: 0px !IMPORTANT;
}