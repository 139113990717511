.customsearchbyword{
  padding: 20px 15px !important;
  background: transparent !important;
  width: 300px;
  border: 1px solid #aa9b9b7d !important;
  border-radius: 10px;
  position: relative;
  z-index: 99;
  outline: none;
}
.data-table-extensions-filter{
  visibility: hidden !important;
}

#specific-table .data-table-extensions-filter {
  display: none !important;
}
#specific-table .data-table-extensions {
  display: none !important;
}


.requestcardfilterbuttonWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.eventTotalcardui{
  
  margin-bottom:30px;

}

.eventTotalcardui .tlcounts{
text-align:left;
}