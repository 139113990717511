.popupproductimg{
    width: 70px;
    height: 70px;
    border-radius: 10px;
}

.productinfodiv{
    display: flex;
}
.Infproductdetails{
    /* top: 20% !important; */
    /* bottom: 40px !important; */
    overflow: scroll !important;
    height: 500px;
    outline: none !important;
}
.influcencer_admin_reset{
    margin: 0px !important;
}
