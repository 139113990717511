@tailwind base;
@tailwind components;
@tailwind utilities;

.topfooter {
    background: #053f72 !IMPORTANT;
}

/* pardeep */

form.row.g-3.contacthomepage {
    background: #f7f7f7;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 21px;
    padding-top: 10px;
    border-radius: 10px;
    border: 3px dotted #0059a7;
}

.btn.theme-bg.try_theme_btn {
    background: #0157a2;
    padding: 12px 30px;
    border-radius: 5px;
}

.eventstyling {
    display: flex;
}
