.black{
    background-color: black;
}
.subscribe-main{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 100px;
}
.subscribe-main h2{
    color: #fff;
    font-family: Jost;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;

}
.subscribe-main h5{
    color: #D3D3D3;
    font-family: Jost;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 48px;
    padding: 10px 0 20px;
}
.subscribe-main h4{
    color: #8b8b8b;
    font-family: Jost;
    font-size: 12px;
    font-style: normal;
}
.subscribe {
    border-radius: 15px;
    border: 1px solid rgb(255 255 255 / 27%);
    color: #aeaeae;
    position: relative;
    width: 45%;
    padding: 20px 20px;
    text-align: left;
    margin-bottom: 10px;
}
.subscribe input{
    background: transparent;
    border: 0;
    font-size: 14px;
    padding-left: 10px;
    color: #979797; 
    width: 95% !important;
}
.subscribe input{
    background: transparent;
    border: 0;
    font-size: 14px;
    padding-left: 10px;
}

.subscribe input:focus-visible {
    outline: none;
}

.subscribe input::before{
    content: 'dfghjkl';
    position: absolute;
    left: 0;
}
.blue-button-subscribe{
    padding: 14px 32px;
    border-radius: 50px;
    background: #0E7ADE;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    color: #fff;
    border: 0;
    outline: none;
    margin-bottom: 5px;
}
.blue-text{
    color: #0E7ADE;
    text-decoration: underline;
}

@media screen and (max-width: 767px) {
    .subscribe-main h2 {
        font-size: 26px;
    }
    .subscribe-main h5 {
        font-size: 15px;
        line-height: 18px;
        padding: 10px 0;
    }
    .subscribe-main{
        padding: 30px;
    }
    .subscribe {
        border-radius: 15px;
        border: 1px solid rgb(255 255 255 / 27%);
        color: #aeaeae;
        position: relative;
        width: 95%;
        padding: 18px;
        text-align: left;
        margin-bottom: 10px;
    }
}

.mailerrormsg{
    width: 45%;
    text-align: start;
}

@media(max-width: 768px){
    .mailerrormsg{
        width: 95%;
        text-align: start;
    } 
}