@media screen and (max-height: 860px) {
    .MobileSaveandpreviewHeight {
    height: 550px !important;
    overflow-y: scroll !important;
    }
  }

  @media screen and (max-height: 810px) {
    .MobileSaveandpreviewHeight {
    height: 500px !important;
    }
  }

  @media screen and (max-height: 762px) {
    .MobileSaveandpreviewHeight {
    height: 450px !important;
    }
  }
  @media screen and (max-height: 710px) {
    .MobileSaveandpreviewHeight {
    height: 400px !important;
    }
  }
  @media screen and (max-height: 662px) {
    .MobileSaveandpreviewHeight {
    height: 370px !important;
    }
  }
  @media screen and (max-height: 630px) {
    .MobileSaveandpreviewHeight {
    height: 300px !important;
    }
  }






  