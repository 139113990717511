
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,500;1,600&display=swap');
/* templat css */
#temp47 .main-temaplt{  font-family: 'Arimo', sans-serif !important;}
#temp47 .wtemplate {
    background: #f7fdff;
}




#temp47 .theme4-gallery-wrapper{
  position: relative;
}
#temp47 .theme4-gallery-inner-wrapper{
  position: absolute;
  top: -30px;
  right: 6px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

#temp47 .theme4-gallery-inner-wrapper img {
  cursor: pointer;
}


#temp47 article.personalinfo.sec {
    background: linear-gradient(89.08deg, #f45b1f -3.38%, #6a1e00 175.37%);
    height: 170px;
}
#temp47 h3.sectitle {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #303030;
    text-align: left;
}
#temp47 p.titleuser {
    font-size: 14px;
    text-align: justify;
    padding-right: 20px;
    word-break: break-word;
    margin-top: 0;
}

h1
#temp47 img.mx-auto.d-block.img-fluid.logo-profile {
    position: absolute;
    top: 30px;
    left: 9px;
    border: -26px;
}

#temp47 article.personalinfo.sec {
    display: flex;
    justify-content: center;
}
#temp47 article ul.timelist {
    margin-top: 20px;
    list-style-type: none;
    font-size: 16px;
    font-weight: 500;
}

#temp47 .innerinfo {
    margin-left: 10px;
    margin-top: 15px;
}

#temp47 h3.username {
  font-size: 22px;
  color: #fff;
  margin-bottom: 3px;
  font-weight: normal;
  /* height: 28px !important; */
  overflow: hidden;
  /* text-overflow: ellipsis; */
  /* display: -webkit-box; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  word-wrap: break-word;
}

#temp47 .topprofiletext {
    position: absolute;
    top: 30px;
    left: 30px;
    display: flex;
}
#temp47  button.btn.btnmakeappitmnets {
    background: #f45b1f;
    width: 100%;
    color: #fff;
    padding: 12px;
    border-radius: 10px;
    font-size: 18px;
}
#temp47 button.btn.btn-submitform{  background: #f45b1f;
    background: #f45b1f;
    width: 100%;
    color: #fff ;
    padding: 12px;
    border-radius: 10px;
    font-size: 18px;
}

#temp47 p.titleuser {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    color: #fff;
    display: -webkit-box;
    font-size: 14px;
    font-weight: 400;
    height: 20px !important;
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
#temp47 ul.socil-link li a {
    color: #eb571d;
    font-size: 24px;
}
#temp47  img.mx-auto.d-block.img-fluid.logo-profile {

}
#temp47 ul.conlink{
  padding: 0;
}
#temp47 ul.conlink li {
    margin-bottom: 17px;
}

#temp47 ul.conlink li a{
    display: flex;
    align-items: flex-start;
}
#temp47 ul.conlink li a span{
    font-size: 17px;
    font-weight: 500;
}
#temp47 ul.conlink li a i{
    min-width: 30px;
    height: 30px;
    background: #1f2024;
    color: #eb571d;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

#temp47 article.businesshour.sec {
    background: #f45b1f;
    color: #fff;
}
#temp47 ul.socil-link li {
    width: 60px;
    height: 60px;
    background: #1f2024;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    margin-right: 0;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    align-items: center;
}

#temp47 article.contact.sec {
    background: #F8F8F8;
    padding: 30px;
}
#temp47 article.businessHours.sec {
    padding: 30px;
    background-color: #f45b1f;
    color: white;
}

#temp47 ul.servesbox li {
    margin: 10px 5px;
    box-shadow: 0px 1px 15px 2px #f45b1f4D;
    background: #f45b1f;
    color: #fff;
    padding: 10px 25px;
    border-radius: 23px;
    font-size: 14px;
}

#temp47 ul.servesbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px 0;
    /* border-top: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf; */
}

#temp47 article.abouttemplate.sec {
    margin-top: 25px;
}
#temp47 article.abouttemplate p {
    font-size: 15px;
    text-align: justify;
    word-break: break-word;
}

#temp47 .sec {
    padding: 15px 30px;
}
#temp47 .testimoniyalbox.sec {
    background: #f45b1f;
    color: #fff;
}

  #temp47 .sectitle.testimonial{
    padding: 20px;
    color: #fff;
  }
  #temp47 .testimonialimg {
    border: 5px solid #ffc708;
  }
  #temp47 .testimonialcontent {
    display: flex;
    margin: 10px 0px;
    align-items: flex-start;
  }
  
  #temp47 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
  }
  
  #temp47 .testiprofile {
    width: 170px;
    height: 170px;
  }
  
  #temp47 .testimonialname {
    font-size: 20px;
    text-transform: capitalize;
  }
  #temp47 .testimonialdescription {
    font-size: 15px;
    text-align: justify;
    line-height: 22px;
    padding-top: 5px;
    color: #fff;
  }
  #temp47 .testibox {
    text-align: left;
    width: 460px;
    @media (max-width:500px){
      width: 380px;
    }
    @media (max-width:400px){
      width: 330px;
    }
  }
  #temp47 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
  }
  #temp47 .testimonials {
    display: flex;
    width: max-content;
  }
  #temp47 .testiimg {
    min-width: 90px;
    max-width: 90px;
    max-height: 90px;
  }
  #temp47 .clintname {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
  }
  #temp47 .testimonialcontent img {
    border: 3px solid #ffffffb8 !important;
    margin: 0 !important;
    border-radius: 50px;
    height: 90px;
  }
#temp47 ul.timelist li .day {
    margin-right: 19px;
    width: 30%;
}

#temp47 label.form-label {
    display: none;
}
/* comman css */

 h1, h2, h3, h4, h5, h6, p{ margin: 0px;}
 #temp47 .main-temaplt ul{ list-style-type: none; padding: 0px;}
 ul {
    margin: 0px;
}
#temp47 .main-temaplt a{ text-decoration: none; color: #000;}
#temp47 .logo-profile {
    width: 125px;
    height: 125px;
    border-radius: 50%;
}
#temp47 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}
#temp47 ul.startaring {
    margin-top: 16px;
}
#temp47 img.mx-auto.d-block.img-fluid.coverimghere {
    width: 500px;
    height: 300px;
}

#temp47 .wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
}
#temp47 ul.socil-link {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 10px;
    justify-content: center;
}
#temp47 ul.timelist li {
    display: flex;
    margin-bottom: 8px;
}
#temp47 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}
#temp47 ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
}
#temp47 article.fixedfooter {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
    background: transparent;
}
#temp47 .personalinfo.modal-info {
    text-align: center;
}
#temp47 .fixedd {
    position: fixed;
    bottom: 0px;
    width: inherit;
    background: #f7fdff;
    transform: translate(0%, 0px);
    padding: 10px;
    z-index: 98;
    box-shadow: 0px 0px 8px 1px #00000010;
}

#temp47 .addtocinatct {
    background: #f45b1f;
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px;
}
#temp47 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp47 .footerleft {
    background: #f45b1f;
    text-align: center;
    line-height: 35px;
    color: #fff;
    border-radius: 50%;
}




#temp47 .qrcodeshaere {
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
}
#temp47 .addtocinatct {
    cursor: pointer;
}


#temp47 .modal-content{
    width: 80%;
    border-radius: 20px;
  } 
  #temp47 .personalinfo.modal-info{
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  #temp47 .personalinfo.modal-info h3{
    padding-top: 10px;
    font-size: 22px;
  }
  #temp47 .personalinfo.modal-info p {
    font-size: 16px;
  }
  #temp47 .personalinfo canvas{
    width: 260px !important;
    height: 260px !important;
  }

  #temp47 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }

  #temp47 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
  }
  #temp47 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
  }
  #temp47 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
  }
  #temp47 .carousel-btn {
    background-color: #1f2024;
    border: 1px solid #1f2024;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    margin: 0 10px 0 0px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #temp47 .carousel-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 110px;
  }

  #temp47 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
  }
  
  #temp47 .personalinfo.modal-info h3 {
    padding-top: 10px;
      font-size: 22px;
      font-weight: 600;
      color: #0b3781;
      text-transform: uppercase;
  }
  
  #temp47 .personalinfo.modal-info p {
    font-size: 16px;
    color: #181817;
  }
  
  
  #temp47 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  #temp47 .twoimages{
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  #temp47 .skeleton-blog{
    margin-bottom: 2em;
    animation: scale 4s infinite;
   }
  
   #temp47 .skeleton-footer{
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
   }

   #temp47 .businessHours h3.sectitle{
    color: #fff;
   }

   @media (max-width:520px){
    #temp47 .fixedd {
      position: fixed;
      bottom: 0px;
      width: 100vw;
      background: #f7fdff;
      transform: translate(0%, 0px);
      padding: 10px;
      z-index: 98;
      box-shadow: 0px 0px 8px 1px #00000010;
    }
   }
   /* @media (max-width:480px){
    #temp47 .testibox {
      width: 355px;
    }
   } */
   

#temp47   .qr-logo-image{
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width:80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
    margin: 0 auto;
    background: white;
  }
#temp47  canvas{
  border-radius: 20px;
  }
#temp47  .canvas-container{
    display: flex;
    justify-content: center;
  }


  #temp47 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}
#temp47 .calanderIcon{
    position: absolute;
    top: 15px;
    right: 12px;
    cursor: pointer;
  }


  #temp47 .extmakeappointmentsubmitbtn{
    background: #f45b1f;
    width: 100% !important;
    color: #fff;
    border-radius: 20px !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
  }

  @-moz-document url-prefix() {
    .firefox-hide {
        display: none !important;
    }
}

.mailurllong{
  -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    display: -webkit-box;
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}



#temp47 .reviewratingcontainer{

}

#temp47 .reviewrating{
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-family: Arial;
  font-weight: 400;
  font-size: 13px;
  color: #fff;
 
}

#temp47 .ratingspan{
  font-family: Arial;
  font-weight: bold !important;
  font-size: 16px;
  color: #fff;
  padding-right: 10px;
}