.template-sec{
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    height: 800px;
    background-image: url(../../../Images/template-back.png);
    background-repeat: no-repeat;
}


@media screen and (max-width: 767px) {
    .template-sec img{
        width: 100%;
    }
    .template-sec {
        height: auto;
        padding: 30px;
    }
  }
