#faqUi{
    list-style-type: none !important;
    padding: 0 20px !important;

}

#faqUi li {
  margin: 30px 0 !important;
}

#faqUi li strong {
  margin: 5px 0 !important;
  font-size: 22px !important;
  /* font-weight: 500 !important; */
}

#faqUi li p {
  font-size: 16px !important;
}

#faq_container {
  padding: 20px 0 40px 0 !important;
}


.shipping_first_Paragraph {
  margin-bottom: 40px !important;
  padding: 0 20px !important;
}

.shipping_first_Paragraph p {
  font-size: 16px !important;
  margin-bottom: 20px !important;
}

/* .shipping_first_Paragraph h2 {
  margin: 35px 0 10px 0 !important;
  font-size: 22px !important;
} */

.shipping_first_Paragraph strong {
  margin: 35px 0 10px 0 !important;
  font-size: 22px !important;
  font-weight: 500 !important;
}


#privacy_container {
  margin: 20px 0 40px 0 !important;
}

#privacy_container strong {
  font-size: 22px !important;
}

#privacy_container p {
  font-size: 16px !important;
}

.heading_paragraph_wrapper {
  margin: 30px 0 !important;
}

.heading_paragraph_wrapper strong{
  font-weight: 500 !important;
}

#privacy_wrapper {
  margin-bottom: 20px !important;
}


#refund_container{
    margin: 30px auto !important;
}


.refund_container_paragraph{
    font-size: 16px !important;
}

.refund_container_division{
    margin: 25px 0 !important;
}

.refund_container_division_paragraph{
    font-size: 16px !important;
}

.Term_condition_heading{
    font-size: 22px !important;
    /* font-weight: bold !important; */
    font-weight: 500 !important;
}

.Term_condition_paragraph{
    font-size: 16px !important;
}

.Term_condition_heading_paragraph{
    margin: 30px 0 !important;
}

#term_condition_Wrapper{
    margin: 20px 0px 40px 0 !important;
}

.refund_heading{
  font-size: 22px !important;
  font-weight: 500 !important;
}



