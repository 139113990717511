  .dlink {
    text-decoration: none !important;
    color: #03a9f3 !important;
  }
  
  .data-table-extensions-filter {
    border: 1px solid lightgray;
    border-radius: 50px;
    padding: 3px 5px;
    /* width: 70%; */
  }
  
  .first {
    padding-right: 10px !important;
  }
  
  .filter-text {
    border: none !important;
    width: 80%;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  