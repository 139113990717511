#theme20-cart.product-popup {
    height: 100vh;
    left: 0;
    max-height: 100vh;
    position: fixed;
    transition: .5s ease-in-out;
    width: 100%;
    z-index: 2;
    /* position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 1px solid;
    height: 100vh; */
    background-color: #EFF3F8;
    z-index: 2;
    color: #000;
}

#theme20-cart .product-popup-inner{
    align-items: center;
    display: flex
    ;
    justify-content: center;
    padding-top: 0;
    width: 100%;
}
#theme20-cart .product-popup-inner article{
    /* margin-top: 0;
    max-width: 520px;
            padding-top: 35px;
            width: 100%;
            margin: 0;
            padding-inline: 10px; */
            /* background-color: #fff; */
            height: 100vh;
            background: #FFFBFA;
            margin: 0;
            max-width: 520px;
            padding-top: 35px;
            width: 100%;
            position: relative;
}
#theme20-cart .products.sec h3.sectitle{
    /* color: #000; */
    /* text-align: center; */
    /* color: #fff; */
    font-family: Afacad;
    /* font-size: 18px; */
    font-weight: 600;
    line-height: 18.67px;
    margin-bottom: 15px;
    margin-top: 34px;
    text-align: center;
    text-transform: capitalize;
    color: #101010;
    font-size: 24px;
}
#theme20-cart .products.sec .cross{
    position: absolute;
    /* top: 6px; */
    top: 48px;
    right: 24px;
    cursor: pointer;
}
#theme20-cart .product-inner{
    /* display: grid !important; */
    gap: 40px 16px;
    grid-template-columns: 1fr;
                    /* height: 100vh; */
                    height: calc(100vh - 320px);
                    overflow: scroll;
                    /* padding: 10px 10px 250px; */
                    padding: 10px 10px 0px;
                    /* @media screen and (max-width: 460px){
                        height: 35vh;
                        
                    } */
}
#theme20-cart .products.sec .product-inner .item {
    position: relative;
    /* min-width: 45%;  */
    text-align: center;
    background-color: #fff;
    /* background-color: #eff3f8; */
    border: 1px solid #EFF3F8;
    border-radius: 0;
    display: flex
;
    border-radius: 4px;
    gap: 30px;
    justify-content: flex-start;
    margin: 20px auto;
    padding: 10px;
    position: relative;
    width: 90%;
    /* padding: 0px 10px; */
    align-items: flex-start;
    max-height: 110px;
    /* overflow-y: scroll;*/

    @media screen and (max-width: 460px){
        gap: 20px;
        width: 95%;
        
    }
    > div{
        >p{
        color: #8d92a3;
        font-size: 19px;
        font-weight: 400;
        margin: 0;
        padding: 5px 0;
        text-align: left;
    }
    }
    
}
#theme20-cart .item .image{
    /* height: 140px;
    width: 100%; */

    /* margin: 10px 0; */
    /* padding: 10px; */
    background: #EFF3F8;
    /* margin-block: auto; */
    align-items: center;
    border-radius: 50%;
    display: flex
;
    height: 80px;
    width: 80px;
    justify-content: center;
    min-width: 80px;
    overflow: hidden;
    /* width: 100px; */
}
#theme20-cart .item .image img{
    height: 100%;
    object-fit: contain;
    width: 100%;
}
#theme20-cart .item h6{
    /* margin-top: 10px;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize; */
    font-family: Instrument Sans;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
display: -webkit-box;
-webkit-line-clamp: 2; 
-webkit-box-orient: vertical;
text-overflow: ellipsis;
width:80%;
overflow: hidden;
word-wrap: break-word;

}


#theme20-cart .empty_cartcontainer {
    margin: 0 auto;
    width: 80%;
    display: flex;
    height: 53%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

#theme20-cart .checkout53Box{
    /* bottom: 100px; */
    bottom: 120px;
    left: 0;
    margin: 0 auto;
    max-width: 520px;
    padding: 0 30px;
    position: fixed;
    right: 0;
    width: 100%;
}
#theme20-cart .checkout53{
    cursor: pointer;
    align-items: center;
    /* background: #ed1b24; */
    background: #936f5e;
    /* border: 1px solid #ffffff4f; */
    border-radius: 12px;
    /* border-radius: 0; */
    /* box-shadow: 0 8px 24px #0c0c0cf2; */
    color: #fff;
    cursor: pointer;
    display: flex
;
font-family: Instrument Sans;
font-size: 16px;
font-weight: 500;
line-height: 16px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    justify-content: space-around;
    margin:  0;
    /* padding: 5px 0; */
    padding: 20px 0;
    width: 100%;
}

#theme20-cart .priceAndQuantity20{
  margin-top: 15px;
    display: flex;
    
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    >p{
        margin-top: 0;
    }

    .itemprice{
        font-family: Instrument Sans;
font-size: 14px;
font-weight: 700;
line-height: 12px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    }
}

#theme20-cart .itemData{

}

#theme20-cart .theme20quantity {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    right: 20px;
    /* border: 1px solid #e1e1e1;  */
    /* border-radius: 24px; */
    display: flex

;
gap:5px;
    flex-direction: column;
    font-family: Instrument Sans;
    font-size: 12px;
    font-weight: 700;
    margin: 0;
    /* padding: 5px 10px; */
    width: FIT-CONTENT;
    justify-content: center;
    @media screen and (max-width: 460px){
        
        /* width: 83px; */
        
    }
}
#theme20-cart .plusminus {
    cursor: pointer;
    font-family: Public Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    background: #EFF3F8;
    border-radius: 5PX;
    padding: 0 5px;
   
}


#theme20-cart  .closered {
    background-color: #ed1b24;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    height: 25px;
    position: absolute;
    right: -10px;
    top: -10px;
    transform: rotate(180deg);
    width: 25px;
}

#theme20-cart .subtotal_cart.subtotal_cartcontainer{
   
    width: 100%;
    border-top: 1px solid #EFF3F8;
    display: flex
;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 40px 30px;
    /* padding-inline: 10px; */
    background-color: #fff;
    border-radius: 10px;
    @media screen and (max-width: 768px){
        padding: 20px 15px;
        
    }
    >p{
        /* margin-inline: 25px; */
        display: flex;
        margin-block:10px;
        justify-content: space-between;
        font-family: Instrument Sans;


    }

}


#theme20-cart  .subTotalPrice{
    font-family: Instrument Sans;
font-size: 16px;
font-weight: 400;
line-height: 16.8px;
color:#959799;

}

#theme20-cart .productCheckout{
    border-top: 1px solid #EFF3F8;
    margin-top: 5px;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;

    .checkoutBtn  .checkout53{
        padding: 18px 35px;
        border-radius: 12px;
        font-size: 16px;
    }
}

