.ContainerNotification{
    /* background: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    padding: 15px;
    border-radius: 10px; */
   
}

.FormContainer{
    display: flex;
    flex-direction: column;
 
}

.LableWrapper{
display: flex;
flex-direction: column;
/* justify-content: center;
align-items: center; */

}
.LableName{
    font-family: "inter", sans-serif;
    color: gray;
    font-size: 18px;
    font-weight: 300;
   
}
.InputContainer{
    width: 60%;
}

.TextAreaa{
    padding: 15px; 
}