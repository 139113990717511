@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,500;1,600&display=swap");

/* templat css */
#temp8 .main-temaplt {
  font-family: "Arimo", sans-serif !important;
}

#temp8 .wtemplate {
  /* background: #f7fdff; */
  background: #010101;
}

#temp8 .circle {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background: radial-gradient(circle at top, #da5afa 0%, #3570ec 60%);
  /* background: radial-gradient(100% 0 at 100% 0, #DA5AFA 0%, #3570EC 60%); */
  opacity: 60%;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  right: -145px;
  top: -175px;
  transform: rotate(250deg);
}

#temp8 .circle2 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle at top, #da5afa 0%, #3570ec 60%);
  /* background: radial-gradient(100% 0 at 100% 0, #DA5AFA 0%, #3570EC 60%); */
  opacity: 60%;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  left: -130px;
  top: 208px;
  transform: rotate(250deg);
}

#temp8 article.personalinfo.sec {
  /* background: linear-gradient(89.08deg, #0165FC -3.38%, #122B65 175.37%); */
  /* background-color: #262B2E; */
  padding-top: 75px;
  padding-bottom: 0px;
}

#temp8 h3.sectitle {
  font-size: 20px;
  font-weight: 500;
  margin: 10px 25px;
  color: #fff;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

#temp8 .contactinfotext {
  line-height: 20px;
}

#temp8 p.aboutus_paragraph {
  text-align: center;
  width: 100%;
  padding: 5px 0;
  color: #676d75;
  font-size: 13px;
  font-family: "Open Sans";
  word-break: break-word;
}

h1 #temp8 img.mx-auto.d-block.img-fluid.logo-profile {
  position: absolute;
  top: 30px;
  left: 9px;
  border: -26px;
}

#temp8 article.personalinfo.sec {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

#temp8 .innerinfo {
  margin: 15px 0;
  /* margin-bottom: 20px; */
  /* margin-left: 44px; */
}

#temp8 .social_media_li {
  margin: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  /* background: linear-gradient(99.5deg, #00A7D6 -20.5%, #00D2C9 112.82%); */
}

#temp8 h3.resume-username {
  font-size: 36px;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 23px;
  color: #fff;
  font-family: 'Italianno';
  /* text-transform: uppercase; */
  margin-bottom: 9px;
  word-break: break-word;
}

#temp8 button.btn.btnmakeappitmnets {
  padding: 13px 15px;
  border-radius: 8px;
  background: #1fdece;
  background-color: #1fdece;
  width: 100%;
  color: #020e0d;
  font-size: 14px;
  border: none;
}

.businessHours_sec {
  margin: 0 auto;
  padding: 10px 15px;
  width: 90%;
  border-radius: 10px;
  background: transparent;
  box-shadow: none;
}

#temp8 button.btn.btn-submitform {
  padding: 15px;
  border-radius: 10px;
  background-color: #1fdece;
  width: 100%;
  font-size: 14px;
  border: none;
  color: #000 !important;
}

#temp8 p.titleuser {
  color: #676d75;
  font-size: 15px;
  word-break: break-word;
  font-weight: 400;
  text-transform: capitalize;
}

#temp8 ul.socil-link li a {
  color: #fff;
  font-size: 24px;
  padding: 0;

}

#temp8 .gradian-icon {
  font-weight: 800;
  background: -webkit-linear-gradient(#9365ff, #529bff);
  /* background: -webkit-linear-gradient(#1D94F0, #67D8F9); */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.infomain {
  color: #fff !important;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  padding-bottom: 15px;
  justify-content: center;
}

.info_icons {
  margin-right: 0.5rem;
  background: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



#temp8 ul.conlink li {
  text-align: center;
}

#temp8 .infomain {
  color: #B5B5B5 !important;
}

#temp8 .info_icons {
  color: #B5B5B5 !important;
  background: #b5b5b5;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#temp8 article.businesshour.sec {
  /* background: #0165fc; */
  color: #fff;
}

#temp8 ul.socil-link li {
  /* background-color: #42464A; */
  border-radius: 50%;
  border: 1px solid;
  min-width: 60px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

#temp8 article.contact.sec {
  /* background-color: #2f3438; */
  margin: 30px 15px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
}

#temp8 ul.servesbox li {
  text-transform: uppercase;
  margin: 5px;
  background: transparent;
  color: #1fdece;
  padding: 5px 15px;
  font-size: 12px;
  border: 1px solid transparent;
  border: 0.8px solid;
  border-image-source: linear-gradient(246.18deg, rgba(255, 255, 255, 0.3) 68.96%, rgba(45, 45, 45, 0) 118.89%);
  border-image-slice: 1;
  border-radius: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 6px;
  padding: 12px 15px;
  font-size: 15px;
  margin: 8px 5px;
  text-align: center;
  font-weight: 600;
  height: 53px;
}

#temp8 ul.servesbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

#temp8 article.abouttemplate.sec {
  /* margin-top: 70px; */
  /* background-color: #2f3438; */
  margin: 10px 10px;
  position: relative;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
}

#temp8 .social-links-sec {
  /* background-color: #2f3438; */
  margin: 30px 10px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
}

#temp8 .social-name {
  color: #fff;
}

#temp8 .sec {
  padding: 15px 25px;
  z-index: 1;
  position: relative;
}

#temp8 .rating {
  color: #ffa438;
}

#temp8 .testimoniyalbox.sec {
  /* background-color: #2f3438; */
  margin: 30px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
}

#temp8 .testimonialimg {
  border: 5px solid #ffc708;
}

#temp8 .testimonialcontent {
  display: flex;
  margin: 10px 0;
  align-items: flex-start;
}

#temp8 .testimonialcontent .testiprofile {
  display: flex;
  margin: 25px;
  align-items: center;
}

#temp8 .testiprofile {
  width: 170px;
  height: 170px;
}

#temp8 .testimonialname {
  font-size: 20px;
}

#temp8 .testimonialdescription {
  font-size: 15px;
  text-align: left;
  line-height: 22px;
  padding-top: 5px;
}

#temp8 .testibox {
  text-align: center;
  width: 460px;
}

#temp8 .testimonials-container {
  overflow: hidden;
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out 0s;
  border: 0.8px solid;
  border-image-source: linear-gradient(246.18deg, rgba(255, 255, 255, 0.3) 68.96%, rgba(45, 45, 45, 0) 118.89%);
  border-image-slice: 1;
  margin: 0 -10px;
  padding: 0 10px;
}

#temp8 .testimonials {
  display: flex;
  width: max-content;
}

#temp8 .testiimg {
  max-width: 90px;
  max-height: 90px;
  border: 1px solid #ccc;
  border-radius: 50%;
  overflow: hidden;
}

#temp8 .clintname {
  padding-left: 20px;
  min-width: 90px;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
}

#temp8 .space-bottom {
  margin-top: 75px;
}

#temp8 .testimonialcontent img {
  /* border: 3px solid #ffc708 !important; */
  margin: 0 !important;
  object-fit: cover;
    width: 90px;
    height: 90px;
}

#temp8 .sectitle.testimonial {
  color: #ffffff;
}

#temp8 ul.timelist li .day {
  /* margin-right: 19px; */
  font-size: 15px;
}

#temp8 ul.timelist li .time {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #1fdece;
}

#temp8 label.form-label {
  display: none;
}

/* comman css */
#temp8 .clear_string {
  color: #fff;
}

.clor-blk {
  color: black;
}

#temp8 .form-control.clear_string.popup::placeholder {
  color: #fff;
}

#temp8 .form-control.clear_string.popup {
  color: #fff !important;
}

#temp8 input.form-control.clear_string::-webkit-calendar-picker-indicator {
  background-image: url(../../../../Images/calendar.png);
}

#temp8 .form-select {
  background-color: transparent !important;
  color: #fff;

  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: transparent;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 7.5px;
  background-color: #383f46;
  border-radius: 5px;
  /* opacity: 100%; */
  /* --bs-form-select-bg-img: url(../../../../Images/d-arrow-w.png); */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add custom arrow */
  background-image: url(../../../../Images/d-arrow-w.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
}


/* Styling the select dropdown */
/* #temp8 .form-select {
  background-color: transparent !important;
  color: #fff;
  border: 1px solid #ccc;
  padding: 7.5px;
  background-color: #383f46;
  border-radius: 5px;
}

#temp8 select.form-select::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
} */

#temp8 .form-select option {
  color: #000;
}

#temp8 .testibox {
  text-align: center;
}

#temp8 h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

#temp8 .main-temaplt ul {
  list-style-type: none;
  padding: 0px;
}

#temp8 .gallery .slick-slide {
  padding: 0 3px;
}

#temp8 .gallery .slick-slide div {
  height: 220px;
}

#temp8 .gallery .slick-slide div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

#temp8 .businesshour ul {
  list-style-type: none;
  /* padding: 0px; */
  width: 100%;
  margin: 0px;
  padding: 10px 15px !important;
  border: 1px solid #ccc;
  border-image-source: linear-gradient(246.18deg, rgba(255, 255, 255, 0.3) 68.96%, rgba(45, 45, 45, 0) 118.89%);
  border-image-slice: 1;
}

#temp8 ul {
  margin: 0px;
  padding: 0 10px;
}

#temp8 .businessHours_sec ul {
  margin: 0px;
  padding: 10px 15px !important;
  border: 1px solid #ccc;
  border-image-source: linear-gradient(246.18deg, rgba(255, 255, 255, 0.3) 68.96%, rgba(45, 45, 45, 0) 118.89%);
  border-image-slice: 1;
}

#temp8 .main-temaplt a {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: white;
}

#temp8 .logo-profile {
  /* background: linear-gradient(#d68b64, #e6497e); */
  padding: 2px;
  max-width: 100px;
  /* height: 150px; */
  /* border-radius: 50%; */
}

.logo-parent {
  background: #000;
  padding: 1rem;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
}

.logo-parent:before {
  content: '';
  width: 155px;
  height: 155px;
  position: absolute;
  /* background: #fff; */
  background: linear-gradient(56.01deg, #000000 5.92%, #FFFFFF 51.77%, #000000 100.21%), linear-gradient(0deg, #000000, #000000);
  border-radius: 50%;
  z-index: -1;
}

#temp8 .clint-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 12px 00px;
}

#temp8 ul.startaring {
  margin-top: 16px;
}

#temp8 img.mx-auto.d-block.img-fluid.coverimghere {
  width: 500px;
  height: 300px;
  position: absolute;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

#temp8 .wtemplate {
  width: 520px;
  /* position: relative; */
  padding: 0px;
  /* overflow: hidden; */
}

#temp8 .about-desc {
  font-size: 15px;
  color: #676d75;
  text-align: center;
}

#temp8 ul.socil-link {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  padding: 15px 0;
  gap: 10px;
}

#temp8 ul.timelist li {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  color: white;
  margin-bottom: 8px;
}

#temp8 ul.gallerylist {
  display: flex;
  flex-wrap: wrap;
}

#temp8 ul.gallerylist li {
  margin: 4px 4px;
  width: 46%;
}

#temp8 article.fixedfooter {
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
}

#temp8 .personalinfo.modal-info {
  text-align: center;
}

#temp8 .fixedd {
  position: fixed;
  bottom: 0px;
  width: inherit;
  background: #181818;
  transform: translate(0%, 0px);
  padding: 0px;
  box-shadow: 0px 0px 6px;
  z-index: 98;
}

#temp8 .addtocinatct {
  background: transparent;
  color: #fff;
  padding: 10px 15px;
  border-radius: 23px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#temp8 ul.startaring {
  display: flex;
  justify-content: center;
  color: #ffc107;
  font-size: 10px;
}

#temp8 .footerleft {
  position: absolute;
  display: flex;
  top: -20px;
  left: 0;
  width: 75px;
  height: 75px;
  background: #25292c;
  /* border: 4px solid #cccccccc; */
  text-align: center;
  line-height: 35px;
  color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  right: 0;
  margin: 0 auto;
  /* box-shadow: 0px 0px 9px 2px #000; */
}

#temp8 .footerleft:before {
  content: '';
  width: 110%;
  height: 110%;
  position: absolute;
  background: linear-gradient(56.01deg, #000000 5.92%, #FFFFFF 51.77%, #000000 100.21%),
    linear-gradient(0deg, #000000, #000000);
  border-radius: 50%;
  z-index: -1;
}

#temp8 .qrcodeshaere {
  cursor: pointer;
  font-size: 42px;
}

#temp8 .addtocinatct {
  cursor: pointer;

}

@media (max-width: 380px) {
  #temp8 .main-temaplt a {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  #temp8 .logo-parent {
    width: 130px;
    height: 130px;
  }

  #temp8 .logo-parent:before {
    width: 135px;
    height: 135px;
  }

  .fixedd {
    width: 100vw;
  }

  #temp8 .coverimghere {
    width: 100% !important;
  }

  #temp8 ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
  }


  #temp8 .wtemplate {
    padding: 0px;
  }

  #temp8 .fixedd {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
}

#temp8 .servicesName.sec {
  /* background-color: #2f3438; */
  margin: 10px 15px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
}

#temp8 .Service_name {
  padding: 15px 0px;
  /* background-color: #2f3438; */
  margin: 30px 25px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
}

#temp8 .businesshour.sec {
  /* background: linear-gradient(to bottom, #9365ff, #529bff); */
  background-color: #2f3438;
  margin: 30px 15px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: transparent;
  box-shadow: none;
}

#temp8 .Conatctform_sec {
  padding: 15px 0px;
  /* background-color: #2f3438; */
  margin: 30px 25px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
}

#temp8 .contact-form {
  padding: 0 5px;
  margin: 15px 0;
}

#temp8 .contact-us-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#temp8 .contact-info-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

#temp8 .contact-info-icon {
  color: #fff;
  font-size: 24px;
  margin-right: 0.5rem;
}

#temp8 .form-control {
  /* border-image: linear-gradient(to bottom, #D68B64, #E6497E); */
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: transparent;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 7.5px;
  /* background-color: #383f46; */
  /* opacity: 60%; */
  border-radius: 4px;
}

#temp8 .form-control::placeholder {
  color: #555d65;
  font-size: 12px;
}

#temp8 .Contact_Form_sectitle {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 25px 20px 25px;
  color: #fff;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

#temp8 .Contact_Form_sectitle input::placeholder {
  color: #555d65;
}

#temp8 .Contact_circle {
  width: 200px;
  height: 200px;
  background-color: gray;
}


#temp8 .modal-content {
  width: 80%;
  border-radius: 20px;
}

#temp8 .personalinfo.modal-info {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

#temp8 .personalinfo.modal-info h3 {
  padding-top: 10px;
  font-size: 22px;
}

#temp8 .personalinfo.modal-info p {
  font-size: 16px;
}

#temp8 .personalinfo canvas {
  width: 260px !important;
  height: 260px !important;
}

#temp8 .image-carousel {
  position: relative;
  width: 60%;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

#temp8 .owl-carousel .owl-nav button.owl-prev {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-left: 85%;
}

#temp8 .owl-carousel .owl-nav button.owl-next {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-right: 10%;
}

#temp8 .owl-carousel button.owl-dot {
  background-color: #ffc708 !important;
}

#temp8 .carousel-btn {
  background-color: #1fdece;
  border: 1px solid #1fdece;
  color: #ffffff;
  border-radius: 50%;
  font-size: 18px;
  margin: 0 10px 0 0px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#temp8 .carousel-container {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
  padding-left: 130px;
}

#temp8 .personalinfo canvas {
  width: 260px !important;
  height: 260px !important;
}

#temp8 .personalinfo.modal-info h3 {
  padding-top: 10px;
  font-size: 22px;
  font-weight: 600;
  color: #181817;
  text-transform: uppercase;
}

#temp8 .personalinfo.modal-info p {
  font-size: 16px;
  color: #181817;
}


#temp8 .personalinfo.modal-info {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

#temp8 .twoimages {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

#temp8 .skeleton-blog {
  margin-bottom: 2em;
  animation: scale 4s infinite;
}

#temp8 .skeleton-footer {
  height: 40px;
  width: 100%;
  border-radius: 6px;
  background: #E5E4E2;
  margin-top: 10px;
}

@media (max-width:520px) {
  #temp8 .fixedd {
    position: fixed;
    bottom: 0px;
    width: 100vw;
    background: #181818;
    transform: translate(0%, 0px);
    padding: 0px;
    box-shadow: 0px 0px 6px;
    z-index: 98;
  }

  #temp8 .sec {
    padding: 15px 10px;
  }
}

@media (max-width:400px) {
  #temp8 .footerleft {
    width: 60px;
    height: 60px;
    /* border: 4px solid #24292ce0; */
  }

  #temp8 .qrcodeshaere {
    font-size: 36px;
  }

  #temp8 ul.socil-link {
    gap: 10px;
  }
}

@media (max-width:480px) {
  #temp8 .testibox {
    max-width: 385px;
    width: 100%;
  }
}

#temp8 .qr-logo-image {
  /* style={{
    width: "80px",
    height: "80px",
    position: "absolute",
    top: "150px",
    left: "150px",
    borderRadius: "50%",
  }} */
  width: 80px;
  height: 80px;
  position: absolute;
  top: 150px;
  left: 0px;
  right: 0px;
  border-radius: 50%;
  margin: 0 auto;
  background: white;
}

#temp8 canvas {
  border-radius: 20px;
}

#temp8 .canvas-container {
  display: flex;
  justify-content: center;
}