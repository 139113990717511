/* @import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet'); */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200..700&display=swap" rel="stylesheet'); */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


#temp27 .wtemplate {
  background: #1D1D1D;
  /* font-family: "Oswald", "Lato"; */
  padding-bottom: 100px;
}

#temp27 ul.gallerylist li {
  margin: 4px 4px;
  width: 28%;
  border-radius: 18px;
}

#temp27 ul.gallerylist li img {
  border-radius: 16px;
}

:root{
  --colborder: linear-gradient(to right, #EABD61 50%, #fff 50%) ;
}

#temp27 h3.sectitle {
  position: relative;
  width: fit-content;
  font-family: Prompt, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 17.99px;
  text-align: left;
  /* margin: 0 auto; */
  margin-bottom: 15px;
  color: #fff;
  text-transform: uppercase;
  padding: 0px 10px 10px 0px;
}

#temp27 h3.sectitle::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px; /* Adjust the height to your desired border thickness */
  background: var(--colborder);
}
#temp27 .makeappitmneets h3.sectitle {
  text-align: center;
}

#temp27 .appoint {
/* border-radius: 8px !important; */
background-color: #F4F4F4 !important;
border: none !important;
color: #0E0E0E  !important;
font-family: Lato !important;
font-size: 13px !important;
font-weight: 500 !important;
line-height: 15.6px !important;
text-align: left;

}



#temp27 option {
  /* border-radius: 0; */
  color: #000;
}

#temp27 .servesbox {
  row-gap: 11px;
  column-gap: 12px;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-gap: 0px; */
  list-style-type: none;
  word-break: break-word;
}

#temp27 p.para {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  
    
  color: #fff;
  word-break: break-word;
  /* text-align: start; */
}

#temp27 .sec {
  margin: 0 18px 0px 18px;
  padding: 32px 0px 0px 0px;
  /* margin-bottom: 24px; */

  /* background: #fff; */
  /* border-radius: 12px; */
  /* box-shadow: 0px 4px 24px 0px #0000001F;*/
}

#temp27 .fixedfooter.sec {
  background: #1c85e7;
  box-shadow: none;
  padding: 15px 0;
}
#temp27 .imgsechere.sec,
#temp27 .businessHours.sec,
#temp27 .makeappitmneets.sec {
  box-shadow: none;
  /* padding: 15px 13px 0 13px; */
  padding: 30px 0px 0 0px;
}

#temp27 .testimoniyalbox.sec {
  /* background: #f8f8f8; */
  background: transparent;
  color: #fff;
  box-shadow: none;
  /* margin: 0;
    padding: 0; */
}
#temp27 .testimonials-wrapper {
  position: relative;
  /* box-shadow: 0px 2px 5px 0px #00000059;
   */
   background: linear-gradient(to right , transparent 75% , #2b2b2b 25%);

}
/* #temp27  .testimonials-wrapper::after{
    position: absolute;
    content:'';
    left:0;
    top:0;
    height: 100%;
    width:80px;
    background-color: #E4F2FF;
    z-index: 0;
} */
/* #temp27 .servicesName.sec {
    padding: 30px 30px 0;
} */

#temp27 article.abouttemplate.sec {
  text-align: center;
  /* margin-top: 10px; */
  padding: 30px 0 0 0;
}
#temp27 .slick-list{
  padding: 0 0 10px 0!important;

}

#temp27 h3.username {
  font-family: "Oswald";
  font-size: 35px;
  font-weight: 400;
  line-height: 49.28px;
  letter-spacing: 0em;
  text-align: center;
  color: #EABD61;
  text-transform: uppercase;
  word-break: break-word;
  /* word-wrap:break-word */
  /* padding-left: 55px; */
}

#temp27 input.form-control {
  /* border-bottom: 0.5px solid #A1C657; */
  border-radius: 0px;
  background-color: transparent;
  color: #fff;

  padding:15px;
  box-shadow: none;
  font-family: Lato;
font-size: 14px;
font-weight: 400;
line-height: 17.99px;
text-align: left;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}

#temp27 .appointmentdiv{
  display: flex;
  gap: 5px;
  >div{
    width: 50%;
    flex-wrap: nowrap;
  }
}

#temp27 .contact-form {

  ::placeholder {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.99px;
    text-align: left;
    


    color: #7A7A7A;
  }
}

#temp27 .labelTxt{
    font-family: Lato;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: left;

    color: #f6f6f6;
}




#temp27 .contact-form input:focus,
#temp27 .contact-form textarea:focus {
 background: #383838;
  
  /* border: 0px solid #bfbfbf; */
  /* border-bottom: 0.5px solid #A1C657; */
}

#temp27 .makeappitmneets h5 {
  display: none;
}

#temp27 input.form-control::placeholder {
  color: #595959 !important;
}

#temp27 textarea.form-control::placeholder {
  color: #595959 !important;
}

#temp27 textarea.form-control {
  outline: none !important;
  border-radius: 0px;
  background-color: transparent;
  color: #fff;

  padding:15px;
  box-shadow: none;
  font-family: Lato;
font-size: 14px;
font-weight: 400;
line-height: 17.99px;
text-align: left;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}

#temp27 select.form-select {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;

  border-radius: 0;
  background-color: #2b2b2b;
  color: #818181;
  padding: 15px 10px;

  /* box-shadow: 0px 2px 5px 0px #0000004D; */
}

#temp27 button.btn.btn-submitform {
  border: none;
  width: 100%;
  color: #0e0e0e ;
  border-radius: 0px;
  padding: 15px 0;
  font-family: Prompt;
font-size: 16px;
font-weight: 500;
line-height: 17.99px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 10px;
  background-color: #EABD61;
}

#temp27 ul.socil-link li {
  color: #fff;
  /* width: 45px; */
  /* height: 45px; */
  /* border-radius: 100%; */
  display: flex;
  font-size: 27px;
  /* background: #F1F1F1; */
  /* box-shadow: 0px 4px 10px 0px #0000000D; */
  align-items: center;
  justify-content: center;
}

#temp27 article.imgsechere.sec {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 0;
 
}
/* 
#temp27 article.imgsechere.sec::after{
    content: '';
    position: absolute;
    left:0;
    top:0;
    width:100%;
    background-color: #1C85E7;
    height:65%;
} */
#temp27 .imgsechere-top {
  padding: 12px 18px 0 18px;
  position: relative;
  z-index: 6;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#temp27 .imgsechere-main-banner {
 
  position: relative;
  z-index: 1;
  height: 417px;
  width: 100%;
  overflow: hidden;
  /* box-sizing: border-box;
    padding: 60px 28px 20px 28px; */
}

#temp27 .imgsechere-top-left {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  line-height: 20px;
  align-items: center;
}

#temp27 article.qrcode-open {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#temp27 .bannerb2 {
  height: 630px;
}

#temp27 .mainStrip {
  height: 10px !important;
  background: linear-gradient(to right, #1b5a8b 50%, #66ac4c 50%);
}

#temp27 .mainsection {
  margin-top: -40px;
  /* background-image: url("../../../../Images/theme-19-bg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40px 40px 0 0;
  z-index: 1;
  position: absolute;
  max-width: 430px;
  width: 100%;
  padding-top: 56px;
  padding-bottom: 70px;
  margin-bottom: 20px;
}
#temp27 .widthsec {
  max-width: 400px;
}

#temp27 .logoext {
  bottom: 88px;
}

#temp27 article.personalinfo {
  z-index: 2;
  position: relative;
  margin: -65px 15px 0px 15px;
  background: #000;
  text-align: center;
  color: #fff;
  padding: 25px 0px;


}

#temp27 button.btn.btnmakeappitmnets {
  border: none;
  background: #EABD61;
text-transform: uppercase;
  width: 100%;
  color: #0e0e0e;
  padding: 15px 0;
  font-family: Prompt;
font-size: 16px;
font-weight: 500;
line-height: 17.99px;
text-align: center;

  border-radius: 0;
}

#temp27 .timelist {
  margin: 10px 0 0;
  padding: 0;
  color: #fff;
}

#temp27 ul.timelist li .day {
  /* margin-right: 28px; */
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;

  /* width: 27%; */
  padding: 0 10px;
  color: #ffffff;
}

#temp27 ul.servesbox li {
  position: relative;
}

#temp27 ul.conlink li i {
  text-align: center;
  color: #EABD61;
  width: 20px;
  /* height: 30px; */
  background: transparent;
  /* border-radius: 50px;
  box-shadow: 0px 4px 10px 0px #0000000d; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

#temp27 ul.conlink li span {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  
    

  color: #f6f6f6;

  /* width:calc(100% - 45px); */
}

#temp27 ul.conlink li {
  margin-bottom: 16px;
}
#temp27 ul.conlink li:last-child {
  margin-bottom: 0;
}
#temp27 ul.conlink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 0 0 0px;
}

#temp27 ul.conlink li a {
  display: flex;
  /* word-break: break-word; */
  align-items: center;
  justify-content:left;
 row-gap: 6px;
 column-gap: 10px;
  /* flex-direction: column; */
}

#temp27 ul.conlink .breakText {
  word-break: break-word;
}

#temp27 p.titleuser {
  margin: 0;
  word-break: break-word;
  font-family: Lato;
font-size: 15px;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.25em;
text-align: center;

  text-transform: uppercase;

  color: #fff;
  /* margin-top: 6px; */
  /* margin-bottom: 5px; */
}

#temp27 p.companyName {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #fff;
}

#temp27 article.businesshour.sec h3 {
  text-align: center;
}

#temp27 ul.servesbox li {
  position: relative;
}

#temp27 ul.servesbox li:before {
  content: "";
  width: 15px;
  height: 15px;
  /* background: #ff752a; */
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: -9px;
  top: 0px;
}

#temp27 ul.servesbox li {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.74px;
  text-align: center;
  padding: 15px 10px;
  background-color: #292929;

  box-shadow: 1px 1px 0px 0px #EABD61;

  /* border-radius: 8px; */
  /* display: flex;
    justify-content: space-between;     
    font-weight: 400;
    font-size: 13px;
    line-height: 36px; */
  color: #fff;
  position: relative;
  /* padding-left: 21px; */
}
/* #temp27 ul.servesbox li:after{
    content:'';
    position: absolute;
    left:0;
    width:13px;
    height:11px;
    background: url('../../../../Images/theme18-list-icon.png');
    background-repeat: no-repeat;
    top:14px;
   
} */

#temp27 ul.timelist li {
  position: relative;
}

#temp27 ul.timelist li:before {
  content: "\f017";
  font-family: "FontAwesome";

  color: #fff;
  /* margin-right: 11px; */
  /* position: absolute;
    right: 20px; */
  font-size: 22px;
}

#temp27 .time {
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
}

#temp27 .testimonialimg {
  border: 5px solid #ffc708;
}

#temp27 .testimonialcontent {
  display: flex;
  margin: 13px 0px 0px 0px;
  align-items: flex-start;
}

#temp27 .testimonialcontent .testiprofile {
  display: flex;
  margin: 25px;
  align-items: center;
}

#temp27 .testiprofile {
  width: 170px;
  height: 170px;
}

#temp27 .testimonialname {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.99px;
  text-align: left;
  text-transform: uppercase;
  color: #e3e3e3;
}
#temp27 .hrline{
  height: 3px;
  width:134%;
  margin-bottom: 10px;
  background-color:#EABD61;
}

#temp27 .slick-slide img {
  /* filter: grayscale(1) !important; */
}

#temp27 .testimonialdescription {
  word-break: break-word;
  font-family: Lato;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: left;
min-height: 115px;
  color: #fff;
  padding-top: 20px;
  width: 94%;
}

#temp27 .testibox { 
 
  text-align: center;
  width: 420px;
}

#temp27 .testimonials-container {
  /* overflow: hidden; */
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out 0s;
  position: relative;
  z-index: 99;
}

#temp27 .testimonials {
  display: flex;
  width: max-content;
  /* width: 200% !important; */
}

#temp27 .testiimg {
  width: 90px;
  height: 90px;
  border-radius: 50px;
  /* border: 3px solid #1C85E7;
    flex: 0 0 auto; */
}
#temp27 .quote{
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  z-index: 4;
}
#temp27 .testimonialcontent img {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0 !important;
  /* border-radius: 50px; */
  /* border: 3px solid #1C85E7; */
}

/* #temp27 .sectitle.testimonial {
    color: #000;
    color: #1E1E1E;
    margin-left: 31px;
} */

#temp27 .headingline {
  width: 52px !important;
  border: 2px solid #66ac4c;
  margin: 0 auto;
  margin-bottom: 12px;
}

#temp27 .testibox {
  text-align: justify;
  position: relative;
}

#temp27 label.form-label {
  color: #ff9635 !important;
  font-size: 14px;
}

#temp27 .clintname {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding-left: 20px; */
  width: 260px;
}

/* comman css */

#temp27 .testibox {
  text-align: center;
}

#temp27 h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

#temp27 .conatctform {
  padding-top: 24px;
  /* margin-top: 40px; */
}

#temp27 .main-temaplt ul {
  list-style-type: none;
  /* padding: 0px; */
}

#temp27 ul {
  margin: 0px;
}

#temp27 .main-temaplt a {
  text-decoration: none;
  color: #fff;
}

#temp27 .imageOverlay{position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(184.37deg, rgba(33, 2, 2, 0) 34.97%, #210202 94.05%);

}

#temp27 .logo-profile {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* border: 2px solid #3d3d3d; */
  margin: 0 auto !important;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 70px;
  object-fit: cover;
  z-index: 2;
  background-color: #fff;
  /* border: 1px solid #1BBFA2; */
}

#theme18 .clint-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 12px 0px;
}

ul.startaring {
  margin-top: 16px;
}

#temp27 img.mx-auto.d-block.img-fluid.coverimghere {
  object-fit: cover;
  height: 100%;
  width: 100%;

  /* position: relative;
    z-index: 5;
    border-radius: 117px;
    box-shadow: 0px -6px 44px 0px #00000040; */
}

#temp27 .wtemplate {
  background: #1D1D1D;
  width: 420px;
  position: relative;
  padding: 0px;
  /* background: linear-gradient(179.26deg, #023c6c -3.12%, #07273e 122.21%); */
}

#temp27 ul.socil-link {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 24px;
}
#temp27 ul.socil-link a {
  /* color:#07080A; */
  margin-top: 0px;
}
#temp27 ul.socil-link-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  column-gap: 47px;
  row-gap: 10px;
  color: white;
}

/* #temp27 ul.socil-link-inner li a {
    color: transparent;

} */

#temp27 input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 18px;
  color: white !important;
  display: none;
  /* background-color: #fff; */
}

#temp27 ul.timelist li {
  display: flex;
  margin-bottom: 14px;
  align-items: center;
  border-radius: 55px;
  /* border: 0.5px solid rgba(255, 255, 255, 0.33); */
  /* background: #fff; */
  color: #fff;
  backdrop-filter: blur(40px);
  padding: 16px 0px;
  /* box-shadow: 0px 2px 5px 0px #0000004D; */
  border-radius: 0;
  background-color: #2b2b2b;
  /* border: 0.2px solid #a1c6576b; */
  justify-content: flex-start;
  padding-left: 20px;
}

#temp27 ul.gallerylist {
  display: flex;
  flex-wrap: wrap;
}

#temp27 ul.gallerylist li {
  margin: 4px 4px;
}

#temp27 article.fixedfooter {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  /* padding: 0 20px;
    align-items: center; */
    background:#2b2b2b;
  margin: 0px;
  position: relative;
}

/* @media(max-width: 380px){
    #temp27 article.fixedfooter{
        justify-content: space-between;
    }
} */

#temp27 .personalinfo.modal-info {
  text-align: center;
}

#temp27 .fixedd {
  position: fixed;
  bottom: 0px;
  width: inherit;
  background: #2b2b2b;
  transform: translate(0%, 0px);
  padding:5px  0;
  /* box-shadow: 0px 0px 18px 5px #0000000d; */
  z-index: 999;
}

#temp27 .addtocinatct {
  /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px; */
  background: transparent;
  color: #fff;
  padding: 10px 15px;
  border-radius: 23px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  row-gap: 4px;
  /* text-align: left; */

  display: flex;
  flex-direction: column-reverse;
  /* flex-direction: column; */
  align-items: center;
}

#temp27 ul.startaring {
  display: flex;
  justify-content: center;
  color: #ffc107;
  font-size: 10px;
}

#temp27 .footerleft {
    /* border: 4px solid #ffffff; */
    position: absolute;
    display: flex;
    top: -25px;
    width: 64px;
    height: 64px;
    background:#fff;
    text-align: center;
    line-height: 35px;
    color: #ffffff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
  /* border: 5px solid #fff;    */
  /* box-shadow: 0px 0px 9px 2px #000; */
}

@media (max-width: 380px) {
  #temp27 .footerleft {
    left: 141px;
  }
}
@media (max-width: 340px) {
  #temp27 .footerleft {
    left: 115px;
  }
}
@media (max-width: 315px) {
  #temp27 .footerleft {
    left: 100px;
  }
}

#temp27 .qrcodeshaere {
  cursor: pointer;
  font-size: 40px;
  /* cursor: pointer; */
  /* width: 60px; */
  /* height: 60px; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
color: #000;
  /* border-radius: 100px; */
  /* padding: 40px;*/
}

#temp27 .addtocinatct {
  cursor: pointer;
}

#temp27 .addtocinatct .icon11 {
  /* margin-left: 10px; */
  font-size: 14px;
}

@media (max-width: 480px) {
  .fixedd {
    width: 100vw;
  }

  .coverimghere {
    width: 100% !important;
  }

  ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
  }

  .wtemplate {
    padding: 0px;
  }

  #temp27 .sec {
    /* background: #F8F8F8; */
    border-radius: 14px;
    /* padding: 20px; */
    margin-bottom: 18px;
    margin: 21px 9px;
  }
}

#temp27 .modal-content {
  width: 80%;
  border-radius: 20px;
}

#temp27 .personalinfo.modal-info {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

#temp27 .personalinfo.modal-info h3 {
  padding-top: 10px;
  font-size: 22px;
}

#temp27 .personalinfo.modal-info p {
  font-size: 16px;
}

#temp27 .personalinfo canvas {
  width: 260px !important;
  height: 260px !important;
}

#temp27 .image-carousel {
  position: relative;
  width: 60%;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

#temp27 .owl-carousel .owl-nav button.owl-prev {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-left: 85%;
}

#temp27 .owl-carousel .owl-nav button.owl-next {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-right: 10%;
}

#temp27 .owl-carousel button.owl-dot {
  background-color: #ffc708 !important;
}

#temp27 .carousel-btn {
  /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
  color: #ffffff;
  font-size: 16px;
  margin: 0 10px 0 0px;
  width: 10px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
  border-radius: 0;
}

#temp27 .carousel-container {
  display: flex;
  z-index: 99999999999999;
  position: relative;
  margin-bottom: -33px;
  justify-content: flex-end;
  /* padding-left: 110px; */
}

#temp27 .personalinfo canvas {
  width: 260px !important;
  height: 260px !important;
}

#temp27 .personalinfo.modal-info h3 {
  padding-top: 10px;
  font-size: 22px;
}

#temp27 .personalinfo.modal-info {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

#temp27 .twoimages {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 0 0px 10px 0px;
  height: 240px !important;
}

#temp27 .skeleton-blog {
  margin-bottom: 2em;
  animation: scale 4s infinite;
}

#temp27 .skeleton-footer {
  height: 60px;
  width: 100%;
  border-radius: 6px;
  background: #e5e4e2;
  margin-top: 10px;
}

@media (max-width: 400px) {
  #temp5 .footerleft {
    width: 80px;
    height: 80px;
    border: 4px solid #24292ce0;
  }

  #temp5 .qrcodeshaere {
    font-size: 40px;
  }
}

@media (max-width: 520px) {
  #temp27 .fixedd {
    position: fixed;
    bottom: 0px;
    width: 100vw;
    /* background: #fcfbfb; */
    transform: translate(0%, 0px);
    padding: 0;
    box-shadow: 0px 0px 18px 5px #0000000d;
    z-index: 98;
  }
}

#temp27 .qr-logo-image {
  /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
  width: 80px;
  height: 80px;
  position: absolute;
  top: 150px;
  left: 0px;
  right: 0px;
  border-radius: 50%;
  margin: 0 auto;
  background: white;
}

#temp27 canvas {
  border-radius: 20px;
}

#temp27 .canvas-container {
  display: flex;
  justify-content: center;
}

#temp27 .logoTextContainer {
  padding: 20px 0;
  margin: 30px;
}

#temp27 .calanderIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

#temp27 .extmakeappointmentform {
  background-color: #fff !important;
  box-shadow:rgb(100 100 111 / 12%) 0px 0px 29px 0px !important;
  border-radius: 10px !important;
  color: #1c1a1a !important;
}

#temp27 .extmakeappointmentsubmitbtn {
  border-radius: 10px !important;
  background: #EABD61 ;
  width: 100% !important;
  color: #000;
  
  padding: 12px 0 !important;
  font-size: 18px !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 9px;
}

#temp27 .extmakeappointmentformplaceholder input::placeholder {
  color: #595959 !important;
}

#temp27 .gallery img {
  border-radius: 0 !important;
}
#temp27 .theme18-gallery-wrapper {
    color:#fff !important;
  padding-bottom: 30px;
  position: relative;
}
#temp27 .theme18-gallery-inner-wrapper {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  justify-content: center;
  color:#fff
}
#temp27 .theme18-gallery-inner-wrapper img {
  cursor: pointer;
}

/* #temp27 .inputfield_defaulticon{
    
} */
/* input[type="date"] {
    -webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../../../Images/calendarIcondays.png');
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 20px;
    color: white !important;
    position: absolute;
    top: 20px;
  } */

input:focus {
  outline: none !important;
  /* border-color: blue;  */
}


#temp27 .slick-prev, .slick-next{
  top: -30px !important;
}

#temp27 .slick-prev {
  left: 80% !important;
}

#temp27 .slick-next {
  right: 0% !important;
}