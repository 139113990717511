.print-container {
    position: relative;
  }
  
  @media print {
    .btn {
      display: none;
    }
  }
  
/* //612 */