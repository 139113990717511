@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel:wght@400..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel:wght@400..900&family=Inter:wght@100..900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oxanium:wght@200..800&family=Play:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yesteryear&display=swap');
/* templat css */
/* #temp16 .main-temaplt{  font-family: 'lato', sans-serif !important;} */
#temp16 .wtemplate {
    background: #254091;
}

#temp16 ul.gallerylist li {
    margin: 4px 4px;
    width: 28%;
    border-radius: 18px;
}

#temp16 ul.gallerylist li img {
    border-radius: 16px;
}

#temp16 h3.sectitle {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    margin-bottom: 10px;
    color: #fff;
    font-weight: 700;
    text-align: start;
    text-transform: uppercase;
    padding-top: 16px;
}

#temp16 .servesbox {
    padding: 0;
    margin: 30px 6px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    list-style-type: none;
}

#temp16 p.para {
    font-family: "Lato", sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    text-align: start;
    word-break: break-word;
}

#temp16 .sec {
    /* background: #f1f1ee; */
    border-radius: 14px;
    /* padding: 30px 0; 
    padding: 10px 0;
    margin-bottom: 18px;*/
    margin: 16px 23px;
}

#temp16 .servicesName.sec {
    /* padding: 30px 30px 0; */
}

#temp16 article.abouttemplate.sec {
    text-align: center;
}

#temp16 h3.username {
    color: #fff;
    font-size: 26px;
    font-weight: 700;
    height: 36px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
    padding: 0 10px;
}
#temp16 p{
    color:#fff;
}
#temp16 input.form-control {
    border-radius: 55px;
    background-color: #00000034;
    border: 0.5px solid rgba(255, 255, 255, 0.33);
    color: #ffffff !important;
    padding: 15px 25px;

}

#temp16 input.form-control::placeholder {
    color: #fff !important;
}

#temp16 textarea.form-control::placeholder {
    color: #fff !important;
}

#temp16 textarea.form-control {
    border-radius: 50px;
    background-color: #00000034;
    color:#fff !important;
    border: 0.5px solid rgba(255, 255, 255, 0.33);

}

#temp16 select.form-select {
    border-radius: 11px;
    background-color: #00000034;
    border: 0.5px solid rgba(255, 255, 255, 0.33);
    border-radius: 50px;
    color: #fff;
    padding: 15px 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add custom arrow */
    background-image: url(../../../../Images/d-arrow-w.png);
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 17px;

    option{
        background-color: #000000b0;

    }

}

#temp16 button.btn.btn-submitform {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgb(0 0 0), rgb(0 0 0)); */
    background: #d1001a;
    width: 100%;
    color: #fff;
    border-radius: 50px;
    padding: 15px 0;
    font-size: 18px;
}

#temp16 ul.socil-link li {
    width: 36px;
    height: 36px;
    display: flex;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    border-radius: 2px;
    background: #FFFCFC; 
    color:#2966F3;
    /* box-shadow: 0px 4px 10px 0px rgba(179, 156, 156, 0.25); */
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}
#temp16 ul.socil-link li a{
    color:#2966F3;
}
#temp16 ul.socil-link {
    display: flex; 
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px;
    margin-top: 0;
    gap: 10px;
}

#temp16 ul.socil-link-inner {
    display: flex;
    justify-content: center;
    padding: 0px;     
    color: white;
}

#temp16 article.imgsechere.sec {
    position: relative;
    width: 60%;
    left: 50%;
    height: auto;
    overflow: hidden;
    border-radius: 0;
    transform: translateX(-50%) skew(-8deg);
    height: 350px;
}
#temp16 article.imgsechere.sec .coverimghere{
    width:100%;
    height: -webkit-fill-available;
}
#temp16 .logoTextContainer {
   position: absolute; 
   margin: 0;
   padding: 0;
    top:18px;
    left:18px;
    z-index: 999;
    width:90px;
}
#temp16 .logoTextContainer .logo-profile{
width:90px;
border-radius: 0;
height:auto;
}

#temp16 article.personalinfo {
   background: url('../../../../Images/theme_16_bg.png');
   background-repeat: no-repeat;
   background-size: cover;
   height:115px;
   width:100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

#temp16 article.qrcode-open {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp16 button.btn.btnmakeappitmnets {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background-color: #D1001A;
    width: 100%;
    color: #fff;
    border-radius: 50px;
    padding: 15px 0;
    font-size: 18px;

}

#temp16 .timelist {
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
}



#temp16 ul.timelist li .day {
    /* margin-right: 28px; */
    font-size: 15px;
    font-weight: 500;
    /* width: 27%; */
    padding: 0 10px;
    color: #000000d7;
}

#temp16 ul.servesbox li {
    position: relative;
}

#temp16 ul.conlink li i {
    min-width: 40px;
    height: 40px;
    /* background: antiquewhite; */
    text-align: center;
    line-height: 40px;
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%); */
    color: #fff;
    border-radius: 5px;
}

#temp16 ul.conlink li span {   
    font-size: 12px;   
    font-weight: 300;
    color: #fff;  
}

#temp16 ul.conlink li {
    /* margin-bottom: 19px; */
    /* display: flex; */
}

#temp16 ul.conlink {
    padding: 0px;
}

#temp16 ul.conlink li a {
    color: #fff;
    display: flex;
    align-items: flex-start;
    column-gap: 15px;
    margin-bottom: 13px; 
    font-size: 14px;
}

#temp16 ul.conlink li a span{
    display: inline-block;
    width: 80px;
    font-size: 14px;
    font-weight: 600;
}

#temp16 p.titleuser {
    color: #fff;
    margin: 0;
    word-break: break-word;
    font-size: 16px;  
    text-align: center; 
}

#temp16 article.businesshour.sec h3 {
    text-align: center;
}

#temp16 ul.servesbox li {
    position: relative;
}

#temp16 ul.servesbox li:before {
    content: "";
    width: 15px;
    height: 15px;
    /* background: #ff752a; */
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: -9px;
    top: 0px;
}

#temp16 ul.servesbox li {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    color: #000000a8;
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border-left: 2px solid #ff5c23; */
    /* line-height: 15px; */
    background-color: #ffffff;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
    padding: 13px 10px;
}

#temp16 ul.timelist li {
    position: relative;
}

#temp16 ul.timelist li:before {
    content: "\f017";
    font-family: 'FontAwesome';
    color: #6A6A6A;
    /* margin-right: 11px; */
    position: absolute;
    right: 20px;
    font-size: 24px;
}

#temp16 .time {
    font-weight: 500;
    font-size: 15px;
    font-weight: 500;
    color: #000000d7;
}


#temp16 .testimoniyalbox.sec {
    /* background: #f8f8f8; */
    background: transparent;
    color: #000;
}

#temp16 .testimonialimg {
    border: 5px solid #ffc708;
}

#temp16 .testimonialcontent {
    display: flex;
    margin: 10px 0px;
    align-items: flex-start;
}

#temp16 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
}

#temp16 .testiprofile {
    width: 170px;
    height: 170px;
}

#temp16 .testimonialname {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
}

#temp16 .testimonialdescription {
    font-size: 15px;
    text-align: justify;
    /* line-height: 22px; */
    padding-top: 5px;
    font-size: 12px;
    color: white;
    /* width: 70%; */
}

#temp16 .testibox {
    text-align: center;
    width: 480px;
}

#temp16 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
}

#temp16 .testimonials {
    display: flex;
    width: max-content;
    /* width: 200% !important; */

}

#temp16 .testiimg {
    max-width: 90px;
    max-height: 90px;
}

#temp16 .testimonialcontent img {
    /* border: 3px solid #ffc708 !important; */
    margin: 0 !important;
    border-radius: 50px;
}

#temp16 .sectitle.testimonial {
    /* color: #000; */
    color: #fff;
}

#temp16 .testibox {
    text-align: justify;
    position: relative;
}

#temp16 label.form-label {
    color: #FF9635 !important;
    font-size: 14px;
}

#temp16 .clintname {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
}


/* comman css */

#temp16 .testibox {
    text-align: center;
}



#temp16 h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
    color:#fff;
}

#temp16 .main-temaplt ul {
    list-style-type: none;
    padding: 0px;
}

#temp16 ul {
    margin: 0px;
}

#temp16 .main-temaplt a {
    text-decoration: none;
    color: #fff;
}

#theme16 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}

ul.startaring {
    margin-top: 16px;
}



.wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
}


#temp16 ul.socil-link-inner li a {
    /* color: transparent; */

}

#temp16 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}

#temp16 ul.timelist li {
    display: flex;
    margin-bottom: 14px;
    align-items: center;
    border-radius: 55px;
    border: 0.5px solid rgba(255, 255, 255, 0.33);
    background: #ffffff;
    backdrop-filter: blur(40px);
    padding: 20px 10px;
}

#temp16 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}

#temp16 ul.gallerylist li {
    margin: 4px 4px;
}

#temp16 article.fixedfooter {
    display: flex;
    justify-content: space-between;
    /* padding: 0 20px;
    align-items: center;
    background: transparent; */
    margin: 0px;
    bottom: 6px;
    position: relative;
}

.space-bottom{
    height: 100px;
}

@media(max-width: 380px){
    #temp16 article.fixedfooter{        
        box-sizing: border-box;           
        
        padding: 15px 8px;
    }
}

#temp16 .personalinfo.modal-info {
    text-align: center;
}

#temp16 .fixedd {
    box-sizing: border-box;
    position: fixed;    
    width: inherit;
    background: #D1001A;
    transform: translate(0%, 0px);   
    box-shadow: 0px 0px 18px 5px #0000000d;
    z-index: 98;
    bottom: 0;
    padding: 15px 25px;
}

#temp16 .addtocinatct {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px; */
    background: transparent;
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp16 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp16 .footerleft {
    position: absolute;
    display: flex;
    top: -30px;
    left: calc(50% - 50px);
    width: 100px;
    height: 100px;
    background: #D1001A;       
    text-align: center;   
    color: #fff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;  
    background: linear-gradient(180deg, #D0011B -4.73%, #254091 106.08%);
    /* box-shadow: 0px 0px 9px 2px #000; */
}



#temp16 .qrcodeshaere {
    cursor: pointer;
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;   
    border-radius: 100px;
    padding: 45px;
    background: #D1001A;     
}

#temp16 .addtocinatct {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    row-gap: 6px
}

@media (max-width: 480px) {
    .fixedd {
        width: 100vw;
    }

    .coverimghere {
        width: 100% !IMPORTANT;
    }

    ul.gallerylist li {
        margin: 4px 4px;
        width: 46%;
    }

    .wtemplate {
        padding: 0px;
    }

    #temp16 .sec {
        /* background: #F8F8F8; */
        border-radius: 14px;
        /* padding: 20px; */
        margin-bottom: 18px;
        margin: 21px 9px;
    }



}

#temp16 .modal-content {
    width: 80%;
    border-radius: 20px;
}

#temp16 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp16 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp16 .personalinfo.modal-info p {
    font-size: 16px;
}

#temp16 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp16 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

#temp16 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
}

#temp16 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
}

#temp16 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
}

#temp16 .carousel-btn {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background: #1BBFA2;
    color: #ffffff;
    border-radius: 50%;
    font-size: 18px;
    margin: 0 10px 0 0px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
}

#temp16 .carousel-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 110px;
}

#temp16 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp16 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp16 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp16 .twoimages {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

#temp16 .skeleton-blog {
    margin-bottom: 2em;
    animation: scale 4s infinite;
}

#temp16 .skeleton-footer {
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
}

@media (max-width:400px) {
    #temp16 .footerleft {
        /*width: 80px;
        height: 80px;
        border: 4px solid #24292ce0;*/
    }

    #temp16 .qrcodeshaere {
        font-size: 40px;
    }
}

@media (max-width:520px) {
    #temp16 .fixedd {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        /* background: #fcfbfb; */
        transform: translate(0%, 0px);
        padding: 10px 0;
        box-shadow: 0px 0px 18px 5px #0000000d;
        z-index: 98;
    }
}

#temp16 .qr-logo-image {
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width: 80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
    margin: 0 auto;
    background: white;
}

#temp16 canvas {
    border-radius: 20px;
}

#temp16 .canvas-container {
    display: flex;
    justify-content: center;
}


#temp16 .calanderIcon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

#temp16 .extmakeappointmentform{
    background-color: #fff !important;
    box-shadow:rgba(0, 0, 0, 0.15) 0px 4px 10px;
    border-radius: 20px !important;
    color: #1c1a1a !important;
}

#temp16 .extmakeappointmentsubmitbtn{
    background: #d1001a ;
    width: 100% !important;
    color: #fff;
    border-radius: 20px !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
  }

  #temp16 .extmakeappointmentformplaceholder input::placeholder {
    color: #a4a4a4 !important;
}

/* input[type="date"] {
    -webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../../../Images/calendarIcondays.png');
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 20px;
    color: white !important;
    position: absolute;
    top: 20px;
  }

  input[type="date"]:focus {
    outline: none; 
    border-color: blue; 
  } */

  #temp16 .newgallery ul,
  .modal-content.theme16modal ul{
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    justify-content: space-between;    
    overflow-y: scroll;
    height: 280px;
} 
::-webkit-scrollbar {
    display: none;
  }   

  #temp16 .newgallery ul li,
  .modal-content.theme16modal ul li{
    cursor: pointer;
    list-style: none;
    width: auto;
    height: 135px;
    overflow: hidden;
    /* border: 1px solid #9d192a; */
  }
  #temp16 .newgallery ul li img,
  .modal-content.theme16modal ul li img{
    width: 100%;
  }

  #temp16 .gallery .sectitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #temp16 .gallery .sectitle span{
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }
  .modal-content.theme16modal{
    background: #254091;
    padding: 0;
    height: 100vh;
    width:100%;
    margin:0;
  }
  .modal-content.theme16modal .modal-title {  
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 2px;
}
.modal-content.theme16modal .modal-header {
    padding: 35px 0 10px 0;   
}
.modal-content.theme16modal .btn-close {
    font-size: 24px;
    --bs-btn-close-color: #fff;
    --bs-btn-close-bg: url('../../././../../Images/theme16-cross-icon.png'); 
}
.theme16modal2 .modalgallery img{
    width:100%;
}
.modal-content.theme16modal2{
    background: #000;
    border-radius: 0;
  }
.modal-content.theme16modal2 .modal-header {
    padding: 0 20px 10px;   
}

@-moz-document url-prefix() {
    .firefox-hide {
        display: none !important;
    }
}


#temp16 .DatePickerColor{
    color: black !important;
}



#temp16 .reviewratingcontainer{

}

#temp16 .reviewrating{
  display: flex;
  justify-content: baseline;
  align-items: center;
  text-align: center;
  font-family: Arial;
  font-weight: 400;
  font-size: 14px;
  color: #95A0AD;
 
}

#temp16 .ratingspan{
  font-family: Arial;
  font-weight: 400;
  font-size: 20px;
  color: #95A0AD;
  padding-right: 5px;
}