.OrderNow_Designation {
  font-size: 20px;
  font-weight: 500;
}

@media (max-width: 1130px) {
  .OrderNow_Designation {
    font-size: 16px;
    font-weight: 600;
  }
}

.Ordernow_title {
  font-size: 14px;
  font-weight: 500;
  /* color: #fff; */
}

@media (min-width: 1100px) and (max-width: 1120px) {
  .Ordernow_title {
    font-size: 20px;
    font-weight: 400;
    /* color: #fff; */
  }
}
.name_image_container {
  /* background-color: #515FDE !important;  */
  /* padding: 8px 20px; */
  /* border-radius:8px; */
  margin: 0 0px 25px 20px !important;
}
.name_image_wrapper {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  align-items: center;
  text-align: start;
}

.dialog_wrapper_plan_superadmin {
  height: fit-content !important;
}
/* .strip_input_field_styling{
    width: 17px !important;
    height: 17px !important;
}

#strip_input_field_.field_styling{
width: 17px !important;
height: 17px !important;
} */

#Remove_Apply_physicalcard_Button {
  border-radius: 0px 10px 10px 0 !important;
  height: 46px !important;
}

.remobe_couponbutton_style {
  /* font-size: 10px; */
  width: fit-content !important;
  margin-right: 0 !important;
  font-size: 16px !important;
}

.Admin_editplan_button {
  color: #fff;
  border: 0;
  /* background:linear-gradient(to right, #01c3cc , #2a76e8); */
  background: linear-gradient(to right, #557a7b, #3c8af8d6);
  padding: 10px 20px;
  border-radius: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 200px; */
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 10px;
}

.Admin_deleteplan_button {
  color: #fff;
  border: 0;
  background: linear-gradient(to right, #ff1a1aad, #951616c4) !important;
  padding: 10px 30px;
  border-radius: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 200px; */
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 10px;
}

#Adminplan_container {
  /* padding: 50px 0 !important; */
  /* margin: auto; */
  width: 100%;
  height: 750px !important;
}

#AdminEditPlan_physicalcard_box {
  margin: 50px 0 0 0 !important;
}

#input_admineditplan_form {
  margin: 0 20px 0 0 !important;
}

.Admin_editplan_form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}
.admin_editplan form {
  margin-top: 5px !important;
}
.Admin_editplan_inputcard_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.adminplan_closebutton {
  color: #fff;
  border: 0;
  background: linear-gradient(to right, #2580e4, #7c7171c4);
  padding: 16px 30px;
  border-radius: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 10px;
}

.adminplan_deletbutton {
  color: #fff;
  border: 0;
  background: linear-gradient(to right, #2580e4, #4dc07cc4);
  padding: 16px 30px;
  border-radius: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 10px;
}

.adminplan_buttonwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 15px 0;
}

.coupenName_liststyle {
  font-size: 14px !important;
}

.coupenlistcontainer_physicacard {
  margin: 20px 0 !important;
}

@media (max-width: 1850px) {
  .BenifitEnterprices_wrapper {
    font-size: 22px !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: center;
  }
}

@media (max-width: 1250px) {
  .BenifitEnterprices_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
  }
}

@media (max-width: 992px) {
  .BenifitEnterprices_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    justify-content: flex-start;
  }
}

.CrownImage_division {
  /* padding: 0 !important; */
  /* font-size: 10px !important; */
}

@media (max-width: 768px) {
  .innernamehere {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5px !important;
  }
}

@media (max-width: 425px) {
  .innernamehere {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column-reverse;
    line-height: 5;
  }
}

@media (max-width: 992px) {
  .btn-sec.addresssss {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }
}

@media (max-width: 1230px) {
  .iinerboxxx {
    padding: 15px 0px !important;
  }
}

@media (max-width: 768px) {
  .iinerboxxx {
    padding: 0 !important;
  }
}

.Address_edit_points {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 1440px) {
  .OrderSummery_rightContainer {
    padding: 15px 20px !important;
  }
}

@media (min-width: 992px) and (max-width: 1150px) {
  .OrdernowAddress_container {
    width: 48% !important;
  }
}

@media (min-width: 992px) and (max-width: 1150px) {
  .OrdernowSummery_container {
    width: 52% !important;
  }
}

@media (max-width: 768px) {
  .AddAddress_form {
    height: fit-content !important;
  }
}

@media (max-width: 575px) {
  .AddAddress_form {
    padding-right: 15px !important;
  }
}

.addedit_button {
  color: #fff;
  background: linear-gradient(to right, #01c3cc, #2a76e8);
  border: 0;
  padding: 16px 30px;
  border-radius: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 200px !important;
  margin-top: 20px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.ViewCard_container {
  /* width: fit-content; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

@media (max-width: 500px) {
  #OrderNowPhysicalcardwrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 891px) {
  #OrderNowPhysicalcardwrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 500px) {
  .selectfield_wrapper {
    width: 100% !important;
  }
}

@media (max-width: 891px) {
  .selectfield_wrapper {
    width: 100% !important;
  }
}

@media (min-width: 260px) and (max-width: 500px) {
  .cardviewiiner {
    width: 100% !important;
    /* height: auto !important; */
  }
}

/* @media(min-width:260px) and (max-width:500px){
    .ViewCardMobile{
        width: 100% !important;
        height: auto !important;
    }
} */

@media (min-width: 430px) and (max-width: 590px) {
  .cardviewiiner {
    width: 384px !important;
    height: 252px !important;
  }
}

@media (min-width: 350px) and (max-width: 430px) {
  .cardviewiiner {
    width: 342px !important;
    height: 225px !important;
  }
}

@media (min-width: 768px) and (max-width: 888px) {
  .cardviewiiner {
    width: 383px !important;
    height: 252px !important;
  }
}

@media (min-width: 260px) and (max-width: 500px) {
  .ViewMobilefInnerCard {
    width: 100% !important;
    /* height: auto !important; */
  }
}

.Physicalcardchooselogo {
  width: 30%;
  margin: 0 10px;
}

@media (max-width: 1440px) {
  .Physicalcardchooselogo {
    width: 50%;
    margin: 0 10px;
  }
}
@media (max-width: 1260px) {
  .Physicalcardchooselogo {
    width: 70%;
    margin: 0 10px;
  }
}

@media (max-width: 891px) {
  .Physicalcardchooselogo {
    width: 100%;
    margin: 0px !important;
  }
}

.PhysicalcardOrdernowWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 768px) and (max-width: 891px) {
  .PhysicalcardOrdernowWrapper {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin: 10px 0; */
    bottom: 45px;
    left: 140px;
    z-index: 9;
  }
}

@media (min-width: 590px) and (max-width: 768px) {
  #OrderNowPhysicalcardwrapper {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 590px) and (max-width: 768px) {
  .ThumbViewMarginIssu {
    margin-top: 2rem !important;
  }
}

@media (min-width: 430px) and (max-width: 590px) {
  #OrderNowPhysicalcardwrapper {
    margin-bottom: 10px !important;
  }
}

@media (min-width: 430px) and (max-width: 590px) {
  .ThumbViewMarginIssu {
    margin-top: 1.5rem !important;
  }
}

@media (min-width: 300px) and (max-width: 430px) {
  #OrderNowPhysicalcardwrapper {
    margin-bottom: 8px !important;
  }
}

@media (min-width: 300px) and (max-width: 430px) {
  .ThumbViewMarginIssu {
    margin-top: 1rem !important;
  }
}

@media (min-width: 590px) and (max-width: 768px) {
  .PhysicalcardOrdernowWrapper {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    bottom: 5px;
    z-index: 9;
    right: 10px;
  }
}

@media (min-width: 430px) and (max-width: 590px) {
  .PhysicalcardOrdernowWrapper {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    bottom: 5px;
    z-index: 9;
    right: 10px;
  }
}
@media (min-width: 300px) and (max-width: 430px) {
  .PhysicalcardOrdernowWrapper {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin: 10px 0; */
    right: 5px;
    bottom: 5px;
    z-index: 9;
  }
}

/* @media(min-width: 768px) and (max-width: 888px){
    .PhysicalcardOrdernowWrapper{
        position: fixed !important;
        bottom: 30px !important;
        
        }
} */

.PhysicalcardOrdernow {
  display: block;
  /* width: 100%; */
  text-align: center;
  color: rgb(255, 255, 255);
  margin-bottom: 13px;
  padding: 15px 40px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 50px;
  background: linear-gradient(to right, rgb(1, 195, 204), rgb(125, 42, 232));
  border: 0px;
  box-shadow: rgba(191, 173, 223, 0.61) 0px 8px 24px;
}

/* //////////////////////////  ChooseAddress  //////////////// */

@media (max-width: 768px) {
  .ChooseAddress {
    padding: 15px 30px !important;
  }
}

@media (min-width: 300px) and (max-width: 768px) {
  .AddnewAddressModal {
    margin: 0px !important;
  }
}

@media (min-width: 300px) and (max-width: 768px) {
  .AddAddressformPadissue {
    padding: 14px 20px !important;
    margin: 0 !important;
  }
}

@media (min-width: 300px) and (max-width: 768px) {
  .AddEditForm_inputfield {
    padding: 10px 0 !important;
  }
}

/* @media(min-width:300px) and (max-width: 768px){
    .addedit_button{
       
        margin-top: 10px !important;
        
    
    }
} */

@media (min-width: 300px) and (max-width: 768px) {
  .AddAddressformmarissue {
    margin: 5px 0 !important;
  }
}

@media (min-width: 300px) and (max-width: 1260px) {
  .CrownImage_division {
    width: 40px !important;
    padding-left: 0px !important;
  }
}

.input-error {
  border: 1px solid red; 
}

.error-message {
  color: red; 
  
}
.success-message {
  color: green; 
}