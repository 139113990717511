
.wrapper{
    background: url('../../Backend/UrlQrcode/Images/texture2.png');
    background-size: cover; 
    background-repeat:repeat-y;
    background-position: 0 0;
    width:100%;
    min-height: 100vh;   
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
}
.wrapper-inner{
    box-sizing: border-box;
    width: 456px;
    padding: 30px 30px;   
}

.heading-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.logo{
    width:80px;
    height:80px;    
    border-radius: 100%;  
}
.logo img{
    width:100%;   
    border-radius: 100%;
}
ul, li{
    margin: 0;
    padding: 0;
    list-style: none;
}

.crosstext{
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}

.crosstext li{
    list-style: circle;
    margin: 0 10px 0 20px;
}
.wrapper-inner ul li a{
    box-sizing: border-box;
    text-align: center;
    width:100%;
    display: block;
    padding: 18px 20px;
    border: 1px solid #ffffffb3;
    margin-bottom: 12px;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}
.wrapper-inner p{
    text-align: center;
    color:#30a7c9;
    font-size: 16px;   
    padding: 30px 0 10px 0;
}
.wrapper-inner ul li:last-child a{
    margin-bottom: 0;
}

@media (max-width: 480px){
    .wrapper-inner ul li a{
        font-size: 16px;
        margin-bottom: 10px;
    } 
    .wrapper-inner{
        padding: 20px;   
    }
    .wrapper-inner p{        
        padding: 15px 0 10px 0;
    }
}