/* Reset Styles */
ul li {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
  
  /* Modal Overlay */
  .modal-overlay {
    /* position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3333; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 520px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3333;
  }
  
  /* iOS Popup */
  

/* @media (max-width: 414px){
    .pwapopupios {
        width: 368px;
        max-height: 268px;        
    }
} */

  
  /* @media (max-width: 767px) {
    .pwapopupios {
        width: 387px;
        max-height: 275px;
        padding: 15px;
        padding-bottom: 30px;
        background: #fff;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
        position: fixed;
        top: 68%;
        bottom: 0%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99;
    }
  } */
  
  /* Popup Header */
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .popup-header h3 {
    margin: 0;
  }
  .close-btn {
    cursor: pointer;
    font-size: 24px;
  }
  
  /* App Info Section */
  .app-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px;
  }
  .app-icon img {
    max-width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .app-details h4 {
    margin: 0 5px;
  }
  
  /* Instructions List */
  .instructions {
    list-style: none;
    padding: 0;
  }
  .instructions li {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
  .instructions img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    border-radius: 5px;
  }


  /* Modal Content */
.modal-content {
    padding: 10px 20px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Popup Styles */
.popup {
    /* width: 350px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative; */
    width: 100%;
    max-width: 520px;
    left: 0;
    right: 0;
}

/* Popup Header */
.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-header h3 {
    font-size: 18px;
    font-weight: 600;
}

.close-btn {
    cursor: pointer;
    font-size: 24px;
}

/* App Information Section */
.app-info {
    align-items: center;
    background: #8080801c;
    border-radius: 8px;
    display: flex;
    margin-top: 0;
    padding: 5px;
}

.app-icon img {
    width: 50px;
    height: 50px;
    border-radius: 8px;
}

.app-details {
    margin-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



.app-details h4 {
    margin-bottom: 5px;
    font-size: 16px;
    margin-left: 0;
    
}

.app-details p {
    color: #555;
    font-size: 14px;
    margin-top: 0px;
    line-height: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
}



@media screen and (max-width:414px) {
    .app-details p {
        color: #555;
        font-size: 14px;
        margin-top: 0px;
        line-height: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }
}

/* Instructions Section */
.instructions {
    margin-top: 20px;
    padding-left: 5px;
    list-style: decimal;
}

.instructions li {
    margin-bottom: 15px;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 3px;
}

.instructions .icon {
    font-size: 18px;
    vertical-align: middle;
    margin-right: 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.instructions .icon2{    
    -webkit-border-radius: 4px;
    border-radius: 4px !important;
}

.instructions strong {
    font-weight: 600;
}

/* Button Div */
.btndiv {
    display: flex;
    justify-content: end;
    align-items: center;
}

.btndiv .installbtn {
    background-color: #0076ffcf;
    color: #fff;
    padding: 5px 15px;
    font-size: 16px;
    border-radius: 25px;
}

/* Download Description */
.downloaddesc {
    color: #333;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 10px;
    margin-top: 0px;
}
.pwapopup{
    max-width: 400px;
}

/* Responsive Styles */

/* Small Screens (up to 576px) */

@media screen and (max-width: 500px) {
    .instructions li {
        font-size: 14px;
    }
    .modal-content {
        width: 96% !important;
        padding: 10px 20px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
}

@media screen and (max-width: 576px) {
    .instructions li {
        font-size: 14px;
    }

    .modal-content {
        width: 96%;
        padding: 10px 20px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
}

@media screen and (max-width: 767px) {
    .modal-content {
        width: 80%;
        margin: 0 auto;
        padding: 10px 20px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
}

/* Medium Screens (up to 992px) */
@media screen and (max-width: 992px) {
    .instructions li {
        font-size: 14px;
    }
}

@media screen and (min-width: 1024px) {
    .modal-content {
        /* width: 28%;
        left: 37.5%; */
        width: 75%;
        margin: 0 auto;
    }
    .modal-overlay {      
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        min-width: 520px;
        margin: 0 auto;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3333;
      }
}



/* Default Styles for Desktop */
.pwapopupios {
    width: 100%;
    max-width: 520px;
    max-height: 300px;
    padding: 15px;
    background: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
    position: fixed;
    bottom: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 99;
}

/* For Tablets (Landscape & Portrait) */
/* @media (max-width: 1024px) {
    .pwapopupios {
        width: 450px;
        max-height: 285px;
    }
}


@media (max-width: 767px) {
    .pwapopupios {
        width: 387px;
        max-height: 275px;
        padding-bottom: 30px;
        top: 68%;
        z-index: 99;
    }
}



@media (max-width: 480px) {
    .pwapopupios {
        width: 98%;
        max-height: 275px;
        padding: 10px;
    }
}

@media (max-width: 414px) {
    .pwapopupios {
        width: 98%;
        max-height: 275px;
        padding: 10px;
        top: 62%;
    }
}

@media (max-width: 391px) {
    .pwapopupios {
        width: 98%;
        max-height: 275px;
        padding: 10px;
        top: 68%;
    }
}

@media (max-width: 375px) {
    .pwapopupios {
        width: 98%;
        max-height: 275px;
        padding: 10px;
        top: 60%;
    }
} */


@media screen and (max-width:1024px) {
    .pwapopup{
        width: 94%;
    }
}
  