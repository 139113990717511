
.testibox {
    text-align: center;
}

h1, h2, h3, h4, h5, h6, p{ margin: 0px;}
.main-temaplt ul{ list-style-type: none; padding: 0px;}
ul {
    margin: 0px;
}
.main-temaplt a{ text-decoration: none; color: #000;}
.logo-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
.clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
img.mx-auto.d-block.img-fluid.coverimghere {
    width: 500px;
    height: 300px;
}

.wtemplate {
    width: 430px;
    position: relative;
    padding: 0px;
}
ul.socil-link {
    display: flex;
}
ul.timelist li {
    display: flex;
}
ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}
ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
}
article.fixedfooter {
    display: flex;
    justify-content: space-between;
}
.personalinfo.modal-info {
    text-align: center;
}
.fixedd {
    position: fixed;
    bottom: 0px;
    width: 28%;
    background: #fff;
    transform: translate(0%, 0px);
    padding: 10px;
}
ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}


@media (max-width: 480px){
    .fixedd{ width: 90%;}
.coverimghere {
    width: 100% !IMPORTANT;
}
    ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
}

.wtemplate { padding: 0px 15px;}

}
