.modal-content {
    padding: 20px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .modal-input {
    margin-bottom: 15px;
  }
  
  .modal-input label {
    font-weight: bold;
  }
  
  .modal-input input {
    width: 100%;
    padding: 8px;
    border: 0;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: rgb(100 100 111 / 12%) 0px 0px 29px 
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .modal-footer button {
    padding: 10px 20px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    background: linear-gradient(to right, #01c3cc, #7d2ae8);
    color: #fff;
    cursor: pointer;
    border: 0;
    box-shadow: rgb(191 173 223 / 61%) 0px 8px 24px;
    border-radius: 50px;
    text-transform: uppercase;
  }
  
  .modal-footer button:hover {
    background-color: #0056b3;
  }
  
  .chatgpt-icon{
    width: 35px;
    position: absolute;
    right: 0;
    cursor: pointer;
    top: 1px;
    border-radius: 55px;
  }

  
  .chatgpt-icon:hover::after {
    content: "Click to chat";
    color: #ed1b1b;
    border-radius: 3px;
    font-size: 12px;
    z-index: 1; /* Ensure the tooltip appears above other elements */
  }
  

/*   
  .PopupGeneratingmodal{
    position: "absolute";
    top: "50%";
    left: "50%";
    transform: "translate(-50%, -50%)";
    width: 400px;
    p: 4;
  }

  .modalpopuppp{
    display: flex;

  } */