@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');

/* templat css */
/* #temp10 .main-temaplt{  font-family: 'lato', sans-serif !important;} */
#temp10 .wtemplate {
    background: linear-gradient(177.5deg, #529BFF 13.99%, #408CFF 109.33%);
}

#temp10 ul.gallerylist li {
    margin: 4px 4px;
    width: 28%;
    border-radius: 18px;
}

#temp10 ul.gallerylist li img {
    border-radius: 16px;
}

#temp10 h3.sectitle {
    font-size: 22px;
    margin-bottom: 15px;
    color: #1e1e1e;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'DM Sans';
}

#temp10 .servesbox {
    list-style: none;
    padding: 0;
    margin: 30px 6px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

#temp10 p.para {
    font-size: 15px;
    font-weight: 500;
    word-break: break-word;
}

#temp10 article.contact.sec .conlink {
    padding: 0;
}

#temp10 .conatctform .sectitle {
    padding-left: 15px;
    color: #fff;
}

#temp10 .businessHours .sectitle {
    padding-left: 15px;
    color: #fff;
}

#temp10 .businessHours .timelist {
    background: #fff;
    padding: 15px;
}

#temp10 .conatctform {
    background: transparent !important;
    box-shadow: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#temp10 .businessHours {
    background: transparent !important;
    box-shadow: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

#temp10 .sec {
    background: #fff;
    box-shadow: 0px 4px 24px 0px #0000004D;
    color: #676D75;
    border-radius: 0;
    padding: 15px;
    margin-bottom: 18px;
    margin: 23px 23px;
    font-family: 'DM Sans';
}

#temp10 .servicesName.sec {
    padding: 15px;
}

#temp10 article.abouttemplate.sec {
    text-align: left;
}

#temp10 h3.resume-username {
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    word-break: break-word;
}

#temp10 input.form-control {
    border-radius: 0px;
}

#temp10 textarea.form-control {
    border-radius: 0px;
}

#temp10 select.form-select {
    border-radius: 11px;
}

#temp10 button.btn.btn-submitform {
    text-transform: uppercase;
    background: #000;
    width: 100%;
    color: #fff;
    border-radius: 7px;
    padding: 20px 0;
    font-size: 18px;
}

#temp10 ul.socil-link li {
    width: 60px;
    height: 60px;
    display: flex;
    text-align: center;
    line-height: 40px;
    font-size: 24px;
    border-radius: 50%;
    background: #FFFCFC;
    box-shadow: 0px 4px 10px 0px rgba(179, 156, 156, 0.25);
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

#temp10 img.mx-auto.d-block.img-fluid.coverimghere {
    width: 100%;
    height: auto;
}

#temp10 article.imgsechere.sec {
    padding: 20px 20px 0 20px !important;
    box-shadow: none;
    background: transparent;
    position: relative;
    width: 100%;
    /* height: 200px; */
    overflow: hidden;
    border-radius: 0;
}

#temp10 article.personalinfo {
    text-align: center;
}

#temp10 article.qrcode-open {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp10 .logo-profile {
    position: absolute;
    top: 90px;
    left: 0px;
    right: 0px;
    z-index: 2;
}

#temp10 article.personalinfo {
    font-family: 'DM Sans';
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    position: absolute;
    bottom: 15px;
    width: 100%;
    left: 0;
    margin: 0;
    background: transparent;
    box-shadow: none;
    padding: 30px;
}

#temp10 button.btn.btnmakeappitmnets {
    text-transform: uppercase;
    background: #000;
    width: 100%;
    color: #fff;
    border-radius: 7px;
    padding: 20px 0;
    font-size: 18px;
}

#temp10 .timelist {
    margin: 10px 0 0;
}

#temp10 ul.timelist li .day {
    margin-right: 28px;
    width: 30%;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
}

#temp10 ul.servesbox li {
    position: relative;
}

#temp10 ul.conlink li i {
    min-width: 40px;
    height: 40px;
    background: antiquewhite;
    text-align: center;
    line-height: 40px;
    background: transparent;
    color: #000;
    border-radius: 5px;
    font-size: 18px;
}

#temp10 ul.conlink li span {
    /* padding-left: 10px; */
    font-size: 18px;
    line-height: 22px;
    font-weight: 800;
    color: #505050;
    text-align: left;
}

#temp10 ul.conlink li {
    margin-bottom: 19px;
    /* display: flex; */
}

#temp10 ul.conlink li a {
    display: flex;
    align-items: center;
}

#temp10 p.titleuser {
    color: #fff;
    margin: 0;
    font-size: 18px;
    font-family: 'DM Sans';
    word-break: break-word;
}

#temp10 article.businesshour.sec h3 {
    text-align: center;
}

#temp10 ul.servesbox li {
    position: relative;
}


#temp10 ul.servesbox li {
    font-weight: 600;
    font-size: 18px;
    color: #202020;
    padding: 15px;
    border: 1px solid #529BFF;
    margin: 5px;
    border-radius: 25px;
    line-height: 15px;
}

#temp10 ul.timelist li {
    position: relative;
}

/* #temp10 ul.timelist li:before {
    content: "\f017";
    font-family: 'FontAwesome';
    color: #ff5520;
    margin-right: 11px;
} */

#temp10 .time {
    font-size: 15px;
    font-weight: 500;
    color: #000;
}


#temp10 .testimoniyalbox.sec {
    background: transparent;
    color: #000;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
}

#temp10 .testimoniyalbox .slick-list {
    overflow-x: clip !important;
    overflow: inherit;
}
#temp10 .testimoniyalbox .bg-white {
    width: 100%;
    padding: 60px 15px 15px 15px;
    margin-top: 70px;
}

#temp10 .testimonialimg {
    border: 5px solid #ffc708;
}

#temp10 .testimonialcontent {
    display: flex;
    margin: 10px 0px;
    align-items: flex-start;
}

#temp10 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
}

#temp10 .testiprofile {
    width: 170px;
    height: 170px;
}

#temp10 .testimonialname {
    font-size: 20px;
}

#temp10 .testimonialdescription {
    font-size: 15px;
    text-align: justify;
    line-height: 22px;
    padding-top: 5px;
}

#temp10 .testibox {
    text-align: center;
    width: 460px;
}

/* #temp10 .testimoniyalbox {
    overflow-x: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
} */

#temp10 .testimonials {
    display: flex;
    width: max-content;
}

#temp10 .testiimg {
    max-width: 120px;
    max-height: 120px;
    position: absolute;
    overflow: hidden;
    padding: 8px;
    top: -125px;
    border-radius: 50%;
    background: linear-gradient(0deg, #529BFF, #529BFF), linear-gradient(0deg, #D9D9D9, #D9D9D9);
}

#temp10 .testimonialcontent img {
    /* border: 8px solid #448fff !important; */
    background: #fff;
    margin: 0 !important;
    border-radius: 50%;
    object-fit: cover;
    width: 90px;
    height: 90px;
}

#temp10 .sectitle.testimonial {
    color: #fff;
    padding-left: 15px;
}

#temp10 .rating {
    color: #ffa438;
}

#temp10 .testibox {
    text-align: justify;
    position: relative;
}

#temp10 label.form-label {
    color: #FF9635 !important;
    font-size: 14px;
}

#temp10 .clintname {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
}


/* comman css */

#temp10 .testibox {
    text-align: center;
}



#temp10 h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
}

#temp10 .main-temaplt ul {
    list-style-type: none;
    padding: 0px;
}

#temp10 ul {
    margin: 0px;
}

#temp10 .main-temaplt a {
    text-decoration: none;
    color: #000;
}

#temp10 .logo-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

#theme3 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}

ul.startaring {
    margin-top: 16px;
}

.wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
}

#temp10 ul.socil-link {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px;
    margin-top: 30px;
    gap: 10px;
}

#temp10 ul.socil-link-inner {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin-top: 30px;
    gap: 0px;
}

#temp10 ul.timelist {
    padding: 0;
}

#temp10 ul.timelist li {
    display: flex;
    margin-bottom: 14px;
    align-items: center;
}

#temp10 .gallery .slick-slide {
    padding: 0 3px;
}

#temp10 .gallery .slick-slide div {
    height: 180px;
}

#temp10 .gallery .slick-prev {
    position: absolute;
    top: -30px;
    right: 40px;
    left: inherit;
}
#temp10 .testimoniyalbox .slick-prev {
    display: none !important;
    position: absolute;
    top: -30px;
    right: 40px;
    left: inherit;
}
#temp10 .testimoniyalbox .slick-next {
    position: absolute;
    top: -35px;
    right: 0;
    left: inherit;
}

#temp10 .gallery .slick-next {
    position: absolute;
    top: -30px;
    right: -10px;
}

#temp10 .gallery .slick-prev:before,
#temp10 .gallery .slick-next:before {
    font-family: var(--fa-style-family, "Font Awesome 6 Free");
    font-weight: var(--fa-style, 900);
}
#temp10 .testimoniyalbox .slick-prev:before,
#temp10 .testimoniyalbox .slick-next:before {
    font-family: var(--fa-style-family, "Font Awesome 6 Free");
    font-weight: var(--fa-style, 900);
}
#temp10 .gallery .slick-prev:before {
    content: "\f104";
}
#temp10 .gallery .slick-next:before {
    content: "\f105";
}
#temp10 .testimoniyalbox .slick-prev:before {
    content: "\f104";
}
#temp10 .testimoniyalbox .slick-next:before {
    content: "\f105";
}
#temp10 .gallery .slick-slide div img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

#temp10 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}

#temp10 ul.gallerylist li {
    margin: 4px 4px;
}

#temp10 article.fixedfooter {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    align-items: center;
    margin: 0;
    background: #000;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

#temp10 .personalinfo.modal-info {
    text-align: center;
}

#temp10 .fixedd {
    position: fixed;
    bottom: 0px;
    width: inherit;
    background: #4189f4;
    transform: translate(0%, 0px);
    padding: 0;
    box-shadow: 0px 0px 18px 5px #0000000d;
    z-index: 98;
}

#temp10 .addtocinatct {
    background: transparent;
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp10 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp10 .footerleft {
    position: absolute;
    display: flex;
    top: -20px;
    left: 0;
    width: 90px;
    height: 90px;
    background: #000;
    border: 6px solid #4189f4;
    text-align: center;
    line-height: 35px;
    color: #fff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    right: 0;
    margin: 0 auto;
    /* box-shadow: 0px 0px 9px 2px #000; */
}



#temp10 .qrcodeshaere {
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
}

#temp10 .addtocinatct {
    cursor: pointer;
}

@media (max-width: 480px) {
    .fixedd {
        width: 100vw;
    }

    .coverimghere {
        width: 100% !IMPORTANT;
    }

    ul.gallerylist li {
        margin: 4px 4px;
        width: 46%;
    }

    .wtemplate {
        padding: 0px;
    }

    #temp10 .sec {
        background: #F8F8F8;
        border-radius: 14px;
        padding: 20px;
        margin-bottom: 18px;
        margin: 21px 9px;
    }



}

#temp10 .modal-content {
    width: 80%;
    border-radius: 20px;
}

#temp10 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp10 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp10 .personalinfo.modal-info p {
    font-size: 16px;
}

#temp10 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp10 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

#temp10 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
}

#temp10 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
}

#temp10 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
}

#temp10 .carousel-btn {
    background: transparent;
    color: #000;
    border-radius: 50%;
    font-size: 18px;
    margin: 0 10px 0 0px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
}

#temp10 .carousel-container {
    display: flex;
    justify-content: space-between;
}

#temp10 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp10 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp10 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp10 .twoimages {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

#temp10 .skeleton-blog {
    margin-bottom: 2em;
    animation: scale 4s infinite;
}

#temp10 .skeleton-footer {
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
}

@media (max-width:520px) {
    #temp10 .fixedd {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        background: #fcfbfb;
        transform: translate(0%, 0px);
        padding: 0;
        box-shadow: 0px 0px 18px 5px #0000000d;
        z-index: 98;
    }
}

#temp10 .qr-logo-image {
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width: 80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
    margin: 0 auto;
    background: white;
}

#temp10 canvas {
    border-radius: 20px;
}

#temp10 .canvas-container {
    display: flex;
    justify-content: center;
}
