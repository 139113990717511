.social ul{
    display: flex;
    list-style: none;
    padding: 0;
    margin-top: 20px;
}
.social ul li{
    margin: 0;
    padding-right: 20px;

}
.black{
    background-color: black;
    padding: 100px 0 0;
    font-family: 'Jost';
}
.first-col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.first-col h2{
    color: #fff;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    padding: 20px 0;
}
.first-col h2 span{
    color: #0E7ADE;
}

.links h2{
    color: #fff;
    font-size: 16px;
   
}

@media(max-width: 1000px){
    .links h2{
      
        text-align: center;
    }
}
.links ul{
    padding: 0;
}
.links ul li{
    color: #D3D3D3;
    font-size: 15px;
    padding-top: 30px;
}


@media(max-width: 1000px){
    .links ul li{
        text-align: center;
    }
}


.copyright {
    border-top: 1px solid #3f3f3f;
    padding: 20px 0;
    text-align: center;
    background: transparent;
    margin-top: 30px;
    display: flex;
    justify-content: center;

   
}

@media(max-width: 768px){
        .copyright{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
}
.copyright h5{
    color: #FFF;
    font-family: 'Jost';
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    padding: 0 20px;
}
@media(max-width: 768px){
    .copyright h5{
        padding: 5px 10px;
    }
}
.links{
    text-align: left;
    /* padding-top: 30px; */
}

@media screen and (max-width: 767px) {
    .first-col {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .links{
        text-align: center;
        padding-top: 30px;
    }
    .links ul li {
        padding-top: 10px;
    }
    .black {
        padding: 40px 0 0;
    }
}