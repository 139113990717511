/* Reset and Global Styles */
/* :root{
    --color-mainbackground:"#ffffff";
    --color-background:"#ffffff";
    --color-heading:"#000000";
    --color-description:"#000000";
    --color-cta:"#ffffff";
} */
/* .colorheading{
    color: var(--color-heading) !important;
}
.colorback{
    background-color: var(--color-background) !important;
}
.colormainback{
    background-color: var(--color-mainbackground) !important;
}
.colordescription{
    color: var(--color-description) !important;
}
.colorctaborder{
    border: 1px solid var(--color-cta) !important;
}
.colorcta{
    background:var(--color-cta) !important;
} */
ul li {
    list-style: none;
}
a {
    text-decoration: none;
}
nav.navbar.navbar-expand-lg.navbar-light.bg-light {
    display: block !important;
    background: white !important;
}

/* Page Cover Section */
section.pagecover {
    background: #053f72;
    text-align: center;
    padding: 49px;
    margin-bottom: 37px;
    color: #fff;
}

/* Login Buttons */
.btn-login a {
    border: 1px solid #ccc;
    text-align: center;
    font-weight: bold;
}
li.nav-item {
    margin-right: 10px;
}

/* Registration Button */
li.nav-item.reg.btn-login a {
    background: #ffffff;
    border-radius: 6px;
    padding: 14px 0;
    color: #000;
    width: 120px;
}
li.nav-item.reg.btn-login a:hover {
    background-color: transparent;
    color: #000;
}

/* Login Button */
li.nav-item.login.btn-login a {
    background: #0059a7;
    color: #fff;
    border-radius: 5px;
    padding: 14px 40px;
}
a.nav-link {
    font-weight: bold;
}

/* Search Box (SBC) */
.sbc {
    border: 2px solid #dbd2d2;
    padding: 2px 15px;
    border-radius: 20px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    background-color: #f1f1f1;
}

/* Client Image */
.clint-img {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
    margin: 12px 0;
}

/* Datepicker Popper */
.react-datepicker-popper {
    /* Uncomment if needed */
    /* transform: translate(-10px, -60px) !important; */
}

/* PWA Popup Styles */
/* .pwapopup {
    width: 97%;
    margin: 0 auto;
    position: absolute;
    top: 20%;
    left: 0;
    z-index: 3333;
    border-radius: 8px;
} */

/* PWA Popup Modal */
.pwapopupmodal {
    background-color: #fff !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.pwapopupmodal h4 {
    color: #050505c7 !important;
    text-align: center;
}
.pwapopupmodal p {
    color: #000000db !important;
    font-family: 'Inter var';
    font-size: 14px;
    border-radius: 8px;
}

/* Responsive Styles */
/* @media (min-width: 344px) and (max-width: 767px) {
    .pwapopup {
        width: 80% !important;
        left: 10%;
    }
}
@media (min-width: 768px) and (max-width: 800px) {
    .pwapopup {
        width: 53% !important;
        left: 23%;
    }
}
@media (min-width: 800px) and (max-width: 900px) {
    .pwapopup {
        width: 50% !important;
        left: 25%;
    }
}
@media (min-width: 900px) and (max-width: 1px) {
    .pwapopup {
        width: 65% !important;
        margin: 0 auto;
    }
} */


/* .pwapopupios {
    width: 517px;
    margin: 0 auto;
    position: fixed;
    bottom: 76px;
    left: 33.1%;
    transform: translate(1px, 10px);
    z-index: 3333;
    background: #fff;
    padding: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
} */

/* PWA Popup Modal */
.pwapopupmodalios {
    background-color: #fff !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.pwapopupmodal h4 {
    color: #050505c7 !important;
    text-align: center;
}
.pwapopupmodal p {
    color: #000000db !important;
    font-family: 'Inter var';
    font-size: 14px;
    border-radius: 8px;
}

/* Responsive Styles */
/* @media (min-width: 344px) and (max-width: 767px) {
    .pwapopup {
        width: 80% !important;
        left: 10%;
    }
    .pwapopupios{
        width: calc(100% - 5px);
        margin: 0 auto;
        position: fixed;
        bottom: 76px;
        left: 2px;
        transform: translate(1px, 10px);
        z-index: 3333;
        background: #fff;
        padding: 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
}
@media (min-width: 768px) and (max-width: 800px) {
    .pwapopup {
        width: 53% !important;
        left: 23%;
    }
    .pwapopupios{
        width: calc(100% - 5px);
        margin: 0 auto;
        position: fixed;
        bottom: 76px;
        left: 2px;
        transform: translate(1px, 10px);
        z-index: 3333;
        background: #fff;
        padding: 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
}
@media (min-width: 800px) and (max-width: 900px) {
    .pwapopup {
        width: 50% !important;
        left: 25%;
    }
}
@media (min-width: 900px) and (max-width: 1042px) {
    .pwapopup {
        width: 40% !important;
        left: 30%;
    }
    .pwapopupios{
        width: 64%;
        left: 18%;
    }
} */

