.heading{
    margin-bottom: 8px;
    margin-top: 20px;
    font-size: 15px;
}
.inputbuttoncontainer{
    display: flex;
    margin: 0 0 20px 0;
   
}

.refreshicon{
    width: 18px;
    height: 18px;
    margin-right: 5px;
}
.captchainput{
    width: 75%;
    border: none;
    padding: 10px;
    margin-right: 5px;
    background-color: #EFEFEF;
    border: 1px solid #a1a1a152;
    border-radius: 8px;
}

.refresscaptchabtn{
    width: 25%;
    border: none;
    padding: 10px;
    margin-left: 5px;
    border: 1px solid #a1a1a152;
    border-radius: 8px;
    color: #787878;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #EFEFEF;
}