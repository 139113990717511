.ChangeBlock_Sequenceview_Container {
  width: 100% !important;
}

@media (min-width: 1440px) and (max-width: 1700px) {
  .ChangeBlock_Container {
    width: 58% !important;
  }
}

@media (min-width: 1260px) and (max-width: 1440px) {
  .ChangeBlock_Container {
    width: 52% !important;
  }
}

@media (max-width: 1260px) {
  .ChangeBlock_Container {
    width: 100% !important;
  }
}

.Changeblock_saveandpreview_buttonWrapper {
  min-width: 195px !important;
}
.Changeblock_saveandpreview_button {
  font-size: 14px !important;
}

.ChangeBlockView_Container {
  display: contents;
}

@media (max-width: 1260px) {
  .ChangeBlockView_Container {
    display: none !important;
  }
}

@media (max-width: 1700px) {
  .ChangeBlock_Sequenceview_Wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media (max-width: 1260px) {
  .Changeblock_saveandpreview_buttonWrapper {
    margin-top: 15px !important;
  }
}

/* @media(max-width: 1260px){
    .SaveandPreviewShowBottom{
        display: flex !important;
        flex-direction: column-reverse !important;
    }
} */

/* ///////////////////////////////////////add details   /////////////////// */

.DetailsSelectBanner_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
}

.bannerbox {
  height: auto;
  max-height: 170px;
}

@media (min-width: 1440px) and (max-width: 1700px) {
  .detailsdptext {
    font-size: 13px !important;
  }
}
@media (min-width: 991px) and (max-width: 1440px) {
  .detailsdptext {
    font-size: 12px !important;
  }
}

@media (min-width: 1440px) and (max-width: 1700px) {
  .AdddetailsdpContainer {
    height: 110px !important;
    width: 115px !important;
  }
}

@media (min-width: 1260px) and (max-width: 1440px) {
  .AddDetailsBanner_logoWrapper {
    display: flex !important;
    flex-direction: column-reverse !important;
    align-items: flex-start !important;
    text-align: start !important;
    justify-content: flex-start !important;
  }
}
@media (min-width: 350px) and (max-width: 991px) {
  .AddDetailsBanner_logoWrapper {
    display: flex !important;
    flex-direction: column-reverse !important;
    align-items: flex-start !important;
    text-align: start !important;
    justify-content: flex-start !important;
  }
}

@media (min-width: 1260px) and (max-width: 1440px) {
  .detailsdptextlogowrapper {
    display: flex !important;
    align-items: flex-start !important;
    text-align: start !important;
    justify-content: flex-start !important;
    width: 250px !important;
  }
}

@media (min-width: 991px) and (max-width: 1440px) {
  .AdddetailsdpContainer {
    height: 110px !important;
    width: 115px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .AdddetailsdpContainer {
    height: 130px !important;
    width: 135px !important;
  }
}
@media (min-width: 350px) and (max-width: 768px) {
  .AdddetailsdpContainer {
    height: 110px !important;
    width: 115px !important;
  }
}

.AddDetailsMarContainer {
  padding: 0 10px;
}

@media (min-width: 1260px) and (max-width: 1440px) {
  .AddDetailsMarContainer {
    padding: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .AddDetailsMarContainer {
    padding: 0px !important;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .AddDetailsMarContainer {
    padding: 0px !important;
  }
}

.AddDetailsNameDestiCom_Wrapper {
  display: flex;
  width: 100% !important;
}

@media (min-width: 1260px) and (max-width: 1440px) {
  .AddDetailswidContainer {
    width: 100%;
  }
}

@media (min-width: 1260px) and (max-width: 1440px) {
  .AddDetailsNameDestiCom_Wrapper {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .AddDetailsNameDestiCom_Wrapper {
    display: flex !important;
    flex-direction: column !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .AddDetailswidContainer {
    width: 100%;
  }
}

@media (min-width: 350px) and (max-width: 600px) {
  .AddDetailsNameDestiCom_Wrapper {
    display: flex !important;
    flex-direction: column !important;
  }
}
@media (min-width: 350px) and (max-width: 600px) {
  .AddDetailswidContainer {
    width: 100%;
  }
}

@media (min-width: 1440px) and (max-width: 1700px) {
  .AddDetailsContactInfoInnerForm {
    padding: 20px 0 !important;
  }
}
@media (min-width: 1260px) and (max-width: 1440px) {
  .AddDetailsContactInfoInnerForm {
    padding: 0 !important;
  }
}

@media (min-width: 350px) and (max-width: 600px) {
  .AddDetailsContactInfoInnerForm {
    padding: 20px 0 0 0 !important;
  }
}
/* 
.DetailsInfoPhoneInput{
    width: 70% !important;

} */

.react-tel-input .form-control {
  width: 100% !important;
  padding: 28px 0px 28px 60px !important;
  border: none !important;
  outline: none !important;
}

@media (max-width: 600px) {
  .ChangeblockContainerpd {
    padding: 15px 0 !important;
  }
}

/* ////////////////////////////////////////   services fomr changes */

.crossIconAdddetails {
  top: -6px !important;
  right: 2px !important;
}

@media (min-width: 350px) and (max-width: 1700px) {
  .MobileviewServices {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-bottom: 0px !important;
    /* min-height: 300px;
        max-height: 300px;
        overflow: hidden; */
  }
}

@media (min-width: 300px) and (max-width: 1800px) {
  .Textimonialminmaxheight {
    min-height: 100px;
    max-height: 100px;
    overflow-y: scroll !important;
  }
}

@media (min-width: 350px) and (max-width: 1700px) {
  .editButtonTextimonial {
    margin: 5px 0 !important;
  }
}

@media (min-width: 1260px) and (max-width: 1440px) {
  .editButtonTextimonial {
    /* margin: 5px 0 !important; */
    width: 100% !important;
  }
}

.AddDetailsTextimonialphnview {
  /* min-height: 150px !important;
    max-height: 150px !important;
    overflow: scroll !important;
    scrollbar-width: none;
    -ms-overflow-style: none; */
  width: 100% !important;
}

#ResumeProjectdetails{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  text-align: start;
}
@media (min-width: 1260px) and (max-width: 1440px) {
  .AddDetailsTextimonialphnview {
    width: 100% !important;
  }
}
.gallarysizepad {
  max-height: 140px !important;
  height: auto !important;
}

/* /////////////////////////////  business js css ///////////////// */

/* .CopyQrNextbtnWrapper{
        width: 25% !important;
    } */
@media (min-width: 991px) and (max-width: 2800px) {
  .CopyQrNextbtnWrapper {
    width: 25%;
  }
}

@media (min-width: 340px) and (max-width: 991px) {
  .CopyQrNextbtnWrapper {
    width: 100%;
  }
}

@media (min-width: 991px) and (max-width: 2800px) {
  .CopyQrNextbtnContainer {
    display: flex;
    width: 100%;
  }
}

@media (min-width: 991px) and (max-width: 2800px) {
  .BusinessCopyqrButton {
    width: 25%;
  }
}
@media (min-width: 991px) and (max-width: 2800px) {
  .BusinessCpyqrnextButton {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .StepsMobileviewstyling {
    display: none;
  }
}

/* ///////////////////////// /business /////////////// */

.MobileBusinessQrcode {
  width: 100% !important;
}

@media (max-width: 450px) {
  .MobileBusinessQrcode {
    width: 95% !important;
    padding: 0 15px 0 10px !important;
  }
}
@media (max-width: 385px) {
  .MobileBusinessQrcode {
    width: 95% !important;
    padding: 0 15px 0 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .MobileDetailsWrapper {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .MobilePhoneFieldWrapper {
    width: 100% !important;
  }
}

@media(min-width: 1260px) and (max-width: 1370px){
  .MobilePhoneFieldWrapper {
    width: 100% !important;
  }
}

@media (max-width: 550px) {
  .ContactFormPadIssue {
    /* padding-left: 12px !important; */
    padding-right: 12px !important;
  }
}

@media (min-width: 991px) {
  #AddNewStyleForm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding: 0 !important;
    justify-content: center;
  }
}
@media (min-width: 991px) {
  #AddNewStyleFormsaloon {
    display: grid;
    background-color: #ECEEF7;
    grid-template-columns: 1fr;
    /* grid-gap: 10px; */
    /* padding: 0 !important; */
    padding: 0 25px;
    justify-content: center;
  }
}

@media(min-width:1260px) and (max-width: 1460px){
  .ResumeAddNewStyleForm{
    display: grid !important;
    grid-template-columns: 1fr !important;
    grid-gap: 10px !important;
    padding: 0 !important;
    justify-content: center !important;
  }
}






@media (min-width: 768px) and (max-width: 991px) {
  #AddNewStyleForm {
    display: grid !important;
    grid-template-columns: 1fr !important;
    padding: 0 !important;
    grid-gap: 10px !important;
    justify-content: center;
  }
}


@media (min-width: 550px) and (max-width: 768px) {
  #AddNewStyleForm {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    padding: 0 !important;
    grid-gap: 10px !important;
    justify-content: center;
  }
}
@media (min-width: 550px) and (max-width: 768px) {
  #AddNewStyleFormsaloon {
    background-color: #ECEEF7;
    display: grid !important;
    grid-template-columns: 1fr;
    /* padding: 0 !important; */
    /* grid-gap: 10px !important; */
    justify-content: center;
    padding: 0 25px;
  }
}

@media(min-width:550px) and (max-width: 740px){
  .ResumeAddNewStyleForm{
    display: grid !important;
    grid-template-columns: 1fr !important;
    grid-gap: 10px !important;
    padding: 0 !important;
    justify-content: center !important;
  }
}

@media (min-width: 200px) and (max-width: 550px) {
  #AddNewStyleForm {
    display: grid !important;
    grid-template-columns: 1fr !important;
    padding: 0 !important;
    grid-gap: 10px !important;
    justify-content: center;
  }
}

#AddNewStyleFormfieldpad {
  padding: 10px 0 !important;
  width: 100%;
  overflow: hidden;
}

/* @media(min-width: 300px) and (max-width: 991px){
    #AddNewStyleFormfieldpad{
        padding: 0 !important;
    }
   } */

@media (max-width: 1260px) {
  #AddnewServicesformwrapper {
    padding-right: 0 !important;
    padding-top: 25px !important;
  }
}


@media (max-width: 1260px) {
  #AddnewGallaryformwrapper {
    padding-right: 0 !important;
    padding-top: 25px !important;
    padding-left: 0 !important;
  }
}

#SocialInnerFormPaddissu {
  padding: 7px !important;
}

.ResumeSocialInnerFormPaddissu{
  padding: 0 7px !important;
  margin-bottom: 0 !important;
}
@media (max-width: 1440px) {
  .ResumeSocialInnerFormPaddissu {
    padding: 15px 10px !important;
    margin-bottom: 0 !important;
  }
}
@media (max-width: 1440px) {
  #SocialInnerFormPaddissu {
    padding: 15px 10px !important;
  }
}

@media (max-width: 1440px) {
  #Socialformsidepad {
    padding: 0;
  }
}

.ChangeBlock_Mobilesavepre_buttonWrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.Adddetails_saveprev_ButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Changeblock_saveandpreview_buttonWrappertwo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

#changeblockprenxtbtn {
  margin: 0 5px !important;
  padding: 0px !important;
}
@media(max-width:600px){
  #changeblockprenxtbtn {
    margin: 5px 5px !important;
    padding: 0px !important;
    width: 100%;
  }
}

@media (max-width: 991px) {
  #CreateandNextButton {
    /* display: none; */
  }
}
@media (min-width: 991px) {
  #MobileActiveSteps {
    display: none;
  }
}

@media (max-width: 991px) {
  #MobileActiveSteps {
    display: visible !important;
    border-bottom: 3px solid #4d65dd;
    width: 200px;
    /* padding: 0 20px; */
    margin: -15px 0px 20px 12px;
  }
}

#ServicesEditformstyle {
  border: 0;
  border-radius: 10px;
  box-shadow: 0 0 29px 0 hsla(240, 5%, 41%, 0.12);
  display: block;
  font-size: 15px;
  margin-bottom: 10px;
  padding: 14px 15px;
  width: 100%;
  background-color: #e9ecef;
}

#SocialformsideMar {
  margin-bottom: 10px !important;
}

#Socialformpadd {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 0;
}
@media (min-width: 768px) and (max-width: 900px) {
  #Socialformpadd {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 0;
  }
}

@media (min-width: 300px) and (max-width: 550px) {
  #Socialformpadd {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 0;
  }
}

#Businesshoursformstyle {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start;
  border: 0;
  border-radius: 10px;
  box-shadow: 0 0 29px 0 hsla(240, 5%, 41%, 0.12);
  display: block;
  font-size: 15px;
  margin-bottom: 10px;
  padding: 14px 25px;
  width: 100%;
  background-color: #e9ecef;
}

@media (min-width: 891px) and (max-width: 2500px) {
  .Businessslotedit {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 10px;
    padding: 0;
  }
}

@media (min-width: 555px) and (max-width: 768px) {
  .Businessslotedit {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-gap: 10px;
    padding: 0;
  }
}

@media (max-width: 550px) {
  #DayTimSlot {
    display: none !important;
  }
}


@media(min-width: 1260px) and (max-width: 1660px){
  .BusinessDayTimingSlotWrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}


/* @media(min-width: 1260px) and (max-width: 1660px){
  .BusinessDayTimingSlot{
    display: flex;
    flex-direction: column;
    width: 50%;
  }
} */


@media(min-width: 1260px) and (max-width: 1660px){
  .BusinessDaySlotDuration{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.BusinessDaySlotDuration{
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
width: 25%;
}

  .WeekDayName{
    padding-bottom: 10px;
  }
  
  @media(min-width: 1260px) and (max-width: 1360px){
    #Businesshoursformstyle{
     padding: 14px 15px;
     font-size: 14px;
    }
   }


   
   @media (min-width:1260px) {
    .Sertbtn{
      display: none !important;
    }
   }


   #AddnewServicesformwrappersaloon{
    display: grid;
    grid-template-columns: 1fr;
   }

   .disabled-option {
    color: #888; 
    background-color: #f0f0f0; 
    font-style: italic;
  }

  .plsbutton {
    width: 30px !important;
    border-radius: 50%;
    height: 30px;
    position: absolute;
    right: 15px;
    top: 40px;
    padding: 0px !important;
    font-size: 20px;
  }

  .imgserve{
    font-size: 13px;
    font-weight: 500;
    color: #685858;
  }